import Store from '@/store/computed/storeHelpers'
import { getDrivers } from '../../requests/drivers'
export default {
  mounted() {
    if (this.pagination) this.initPagination()
    this.getDrivers()
  },
  data() {
    return {
      driverCount: 0,
      loadedDrivers: false
    }
  },
  watch: {
    pagination: {
      deep: true,
      handler() {
        this.getDrivers()
      }
    },
    sortDesc(val) {
      this.pagination.sortDesc = [val]
    },
    showInactive() {
      this.getDrivers()
    },
    showRequest() {
      this.getDrivers()
    }
  },
  computed: {
    sortDesc: Store.getSet({ store: 'masterfiles', prop: 'sortDesc' }),
    showInactive: Store.getSet({ store: 'masterfiles', prop: 'showInactive' }),
    showRequest: Store.getSet({ store: 'masterfiles', prop: 'showRequest' }),
    drivers: Store.getSet({ store: 'drivers', prop: 'drivers' }),
    loadingDrivers: Store.getSet({
      store: 'drivers',
      prop: 'loadingDrivers'
    }),
    parentId: Store.getSet({ store: 'drivers', prop: 'parentId' })
  },
  methods: {
    async getDrivers() {
      try {
        this.loadingDrivers = true
        const payload =
          this.masterFile == 'driver'
            ? {
                pagination: this.pagination,
                filterBy: this.filterBy,
                contractorId: this.parentId,
                showInactive: this.showInactive,
                showRequest: this.showRequest
              }
            : {}
        const result = await getDrivers(payload)
        if (result) {
          this.drivers = result.data.result
          this.driverCount = result.data.count
          this.loadedDrivers = true
        }
        this.loadingDrivers = false
      } catch (err) {
        console.log(err)
        this.loadingDrivers = false
      }
    }
  }
}
