import Mutations from '@/store/data/mutations'
export default {
  namespaced: true,
  state: {
    drivers: [],
    driversLite: [],
    contractorId: null,
    adding: false,
    loadingDrivers: false,
    loadingDriversLite: false,
    parentId: null
  },
  mutations: Mutations,
  actions: {},
  getters: {}
}
