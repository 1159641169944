export default {
  computed: {
    // return {
    allBillHeaders() {
      return [
        {
          text: 'ID',
          align: 'left',
          value: 'id',
          type: 'text',
          show: false
        },
        {
          text: 'Billing Period',
          align: 'left',
          value: 'billingPeriod',
          type: 'text',
          show: false
        },
        {
          text: 'Date',
          align: 'left',
          value: '_date',
          show: false
        },
        {
          text: 'Reference',
          align: 'left',
          value: 'externalRef',
          type: 'text',
          show: false
        },
        {
          text: this.$store.state.global.pages.project.titleSingular,
          align: 'left',
          value: 'project.name',
          type: 'text',
          show: false
        },
        {
          text: this.$store.state.global.pages.source.titleSingular,
          align: 'left',
          value: 'source.name',
          type: 'text',
          show: false
        },
        {
          text: this.$store.state.global.pages.destination.titleSingular,
          align: 'left',
          value: 'destination.name',
          type: 'text',
          show: false
        },
        {
          text: 'ABN',
          align: 'left',
          value: 'abn',
          type: 'text',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.bill.titleSingular} lines`,
          align: 'right',
          value: 'billLineCount',
          type: 'decimal',
          noOfDecimals: 0,
          show: false
        },
        {
          text: `${this.$store.state.global.pages.docket.titleSingular} lines`,
          align: 'right',
          value: 'docketLineCount',
          type: 'decimal',
          noOfDecimals: 0,
          show: false
        },
        {
          text: `Export lines`,
          align: 'right',
          value: 'docketExportLineCount',
          type: 'decimal',
          noOfDecimals: 0,
          show: false
        },
        // {
        //   text: 'Hours',
        //   align: 'right',
        //   value: '_hoursTotal',
        //   type: 'decimal',
        //   decimals: 1,
        //   show: false
        // },
        // {
        //   text: `${this.$store.state.global.pages.source.titleSingular} weight`,
        //   align: 'right',
        //   value: 'sourceWeightTotal',
        //   type: 'decimal',
        //   decimals: 0,
        //   show: false
        // },
        // {
        //   text: `${this.$store.state.global.pages.vehicle.titleSingular} weight`,
        //   align: 'right',
        //   value: 'vehicleWeightTotal',
        //   type: 'decimal',
        //   decimals: 0,
        //   show: false
        // },
        // {
        //   text: `${this.$store.state.global.pages.destination.titleSingular} weight`,
        //   align: 'right',
        //   value: 'destinationWeightTotal',
        //   type: 'decimal',
        //   decimals: 0,
        //   show: false
        // },
        {
          text: 'Total',
          align: 'right',
          value: '_sellTotal',
          type: 'curr',
          decimals: 2,
          show: false
        },
        {
          text: 'Internal notes',
          align: 'left',
          value: 'internalNotes',
          type: 'text',
          show: false
        },
        {
          text: 'Invoice notes',
          align: 'left',
          value: 'invoiceNotes',
          type: 'text',
          show: false
        },
        {
          text: 'Status',
          align: 'left',
          value: 'status',
          type: 'text',
          show: false
        },
        {
          text: 'Type',
          align: 'left',
          value: 'type',
          type: 'text',
          show: false
        },
        {
          text: `${this.$store.state.settings.settings.accountingSystem} Inv#`,
          align: 'left',
          value: 'transactionNumber',
          type: 'text',
          show: false
        },
        {
          text: `${this.$store.state.settings.settings.accountingSystem} ID`,
          align: 'left',
          value: 'transactionId',
          type: 'text',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.destination.titleSingular} ${this.$store.state.settings.settings.accountingSystem} ID`,
          align: 'left',
          value: 'destination.accountingContactId',
          type: 'text',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.source.titleSingular} ${this.$store.state.settings.settings.accountingSystem} ID`,
          align: 'left',
          value: 'source.accountingContactId',
          type: 'text',
          show: false
        },
        {
          text: `${this.$store.state.global.pages.project.titleSingular} ${this.$store.state.settings.settings.accountingSystem} ID`,
          align: 'left',
          value: 'project.accountingContactId',
          type: 'text',
          show: false
        },
        {
          text: `${this.$store.state.settings.settings.accountingSystem}`,
          align: 'center',
          value: 'hasXero',
          sortable: false,
          type: 'boolean',
          show: false
        },
        {
          text: 'Created By',
          align: 'left',
          value: 'createdBy.name',
          type: 'text',
          show: false
        },
        {
          text: 'Updated By',
          align: 'left',
          value: 'updatedBy.name',
          type: 'text',
          show: false
        },
        {
          text: 'Created At',
          align: 'left',
          value: '_createdAt',
          show: false
        },
        {
          text: 'Updated At',
          align: 'left',
          value: '_updatedAt',
          show: false
        }
      ]
    }
  }
}
