// import Store from '@/store/computed/storeHelpers'
export default {
  data() {
    return {
      allDocketHeaders: [
        {
          text: 'Billing Period',
          value: 'billingPeriod'
        },
        {
          text: 'ID',
          value: 'id'
        },
        { text: 'Date', value: '_date' },
        { text: 'Date received', value: '_dateReceived' },
        { text: 'External ref #', value: 'externalRef' },
        {
          text: `${this.$store.state.global.pages.allocation.titleSingular} ref #`,
          align: 'left',
          value: 'allocation.reference',
          type: 'text'
        },
        {
          text: this.$store.state.global.pages.project.titleSingular,
          value: 'source.project.name'
        },
        {
          text: this.$store.state.global.pages.source.titleSingular,
          value: 'source.name'
        },
        {
          text: this.$store.state.global.pages.vehicle.titleSingular,
          value: 'vehicle.name'
        },
        {
          text: this.$store.state.global.pages.vehicleType.titleSingular,
          value: 'vehicle.vehicleType.name'
        },
        {
          text: 'Max. tonnage',
          value: 'vehicle.vehicleType.tonnage',
          align: 'right'
        },
        {
          text: this.$store.state.global.pages.contractor.titleSingular,
          value: 'vehicle.contractor.name'
        },
        {
          text: this.$store.state.global.pages.driver.titleSingular,
          value: 'driver.name'
        },
        {
          text: 'Deliveries',
          align: 'right',
          value: 'allocation.lineCount',
          type: 'decimal',
          noOfDecimals: 0
        },
        {
          text: 'Lines',
          align: 'right',
          value: 'docketLineCount',
          type: 'decimal',
          noOfDecimals: 0
        },
        {
          text: 'Distance (km)',
          align: 'right',
          value: '_totalDistance',
          type: 'decimal',
          noOfDecimals: 2
        },
        { text: 'Start', value: '_start' },
        { text: 'End', value: '_end' },
        {
          text: 'Open for',
          align: 'right',
          value: 'hours',
          type: 'decimal',
          noOfDecimals: 2
        },
        {
          text: 'Hours',
          align: 'right',
          value: 'lineHours',
          type: 'decimal',
          noOfDecimals: 2
        },
        {
          text: 'Qty',
          align: 'right',
          value: '_qty',
          type: 'decimal',
          noOfDecimals: 2
        },
        {
          text: 'Cost Qty',
          align: 'right',
          value: '_costQty',
          type: 'decimal',
          noOfDecimals: 2
        },
        {
          text: 'Cost Total',
          align: 'right',
          value: '_costTotal',
          type: 'curr',
          noOfDecimals: 2
        },
        {
          text: 'Sell Total',
          align: 'right',
          value: '_sellTotal',
          type: 'curr',
          noOfDecimals: 2
        },
        {
          text: `${this.$store.state.global.pages.allocation.titleSingular} ID`,
          align: 'right',
          value: 'allocation.id',
          type: 'text'
        },
        {
          text: 'Notes',
          align: 'left',
          value: 'notes',
          type: 'text'
        },
        {
          text: 'Closed',
          align: 'center',
          value: 'closed',
          sortable: false,
          type: 'boolean'
        },
        {
          text: 'App created',
          align: 'right',
          value: 'isAppCreated',
          sortable: false,
          type: 'boolean'
        },
        {
          text: 'Approved',
          align: 'right',
          value: 'totalApproved',
          type: 'curr',
          noOfDecimals: 2
        },
        {
          text: 'Waived',
          align: 'right',
          value: 'totalWaived',
          type: 'curr',
          noOfDecimals: 2
        },
        {
          text: 'Outstanding',
          align: 'right',
          value: 'totalOutstanding',
          type: 'curr',
          noOfDecimals: 2
        },
        {
          text: 'Balanced',
          value: 'balanced',
          type: 'boolean'
        },
        {
          text: this.$store.state.global.pages.rcti.title,
          align: 'center',
          value: 'rctiLineCount',
          type: 'decimal',
          noOfDecimals: 0
        },
        {
          text: this.$store.state.global.pages.bill.title,
          align: 'right',
          value: 'billLineCount',
          type: 'decimal',
          noOfDecimals: 0
        },

        { text: 'Entered By', value: 'createdByName' },
        { text: 'Entered ID', value: 'createdById' },
        { text: 'Updated By', value: 'updatedBy.name' },
        { text: 'Created At', value: '_createdAt' },
        { text: 'Updated At', value: '_updatedAt' }
      ]
    }
  }
}
