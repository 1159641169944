<template>
  <v-container
    fluid
    v-if="$store.state.user.userAuthClass[`${$route.name}Read`]"
  >
    <v-row>
      <v-col cols="12" class="py-0">
        <MapControl
          :docket="docket"
          :openDockets="sortedDockets"
          :loadingOpenDockets="loadingOpenDockets"
          :docketIds="docketIds"
          :toggleAll="toggleAll"
          :toggleDocket="toggleDocket"
          :icon="icon"
          :contractors="contractorsLite"
          :sources="sourcesLite"
        />
      </v-col>
      <v-col cols="12">
        <MapView
          :docket="docket"
          :allocationLines="allocationLines"
          :currentRoute="currentRoute"
          :gpsLines="gpsLines"
          :selectedDockets="selectedDockets"
          :loadingGpsLines="loadingGpsLines"
          :loadingRouteLines="loadingRouteLines"
          :getRoute="getGpsLines"
          :showPods="showPods"
          :clearDriver="clearDriver"
          :defaultGPSCoordinates="defaultGPSCoordinates"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      @click:outside="() => (dialog = false)"
      @keydown.esc="() => (dialog = false)"
      :max-width="$vuetify.breakpoint.smAndUp ? '900px' : null"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <AllocationLineDetails
        v-if="currentLine && dialog"
        :item="currentLine"
        :dialog="dialog"
        :close="() => (dialog = false)"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import Store from '../../store/computed/storeHelpers'
import getOpenDockets from '../../services/mixins/getTransactionFiles/getOpenDockets'
import getContractorsLite from '../../services/mixins/getMasterFiles/lite/getContractorsLite'
import getSourcesLite from '../../services/mixins/getMasterFiles/lite/getSourcesLite'
import routeHelpers from '../../components/map/mixins/routeHelpers'
import locationHelpers from '../../components/map/mixins/locationHelpers'

import AllocationLineDetails from '@/components/allocations/lines/AllocationLineDetails'
import MapView from '@/components/map/MapView'
import MapControl from '@/components/map/MapControl'
export default {
  name: 'Map',
  mixins: [
    getOpenDockets,
    getContractorsLite,
    getSourcesLite,
    routeHelpers,
    locationHelpers
  ],
  components: {
    MapView,
    MapControl,
    AllocationLineDetails
  },
  props: {
    height: {
      type: Number,
      default: () => 500
    }
  },
  data() {
    return {
      // defaultGPSCoordinates: [151.425, -33.337],
      defaultGPSCoordinates: [133.7751, -25.2744],
      docketIds: [],
      docket: null,
      currentLine: null,
      dialog: false,
      allocationLines: null,
      currentRoute: [],
      currentGpsLines: []
    }
  },
  mounted() {
    this.setLocationsInterval(this.docket)
    this.setDocId()
  },
  watch: {
    docketIds(val) {
      this.getCurrentGpsLines({ ids: val })
    },
    docket(val) {
      this.setLocationsInterval(val)
    }
  },
  beforeDestroy() {
    if (this.locationsInterval) clearInterval(this.locationsInterval)
    if (this.routeInterval) clearInterval(this.routeInterval)
  },
  computed: {
    contractorId: Store.getSet({
      store: 'global',
      prop: 'selectedContractorId'
    }),
    sourceId: Store.getSet({ store: 'global', prop: 'selectedSourceId' }),
    selectedAllDockets() {
      return this.docketIds.length === this.filteredOpenDockets.length
    },
    selectedSomeDockets() {
      return this.docketIds.length > 0 && !this.selectedAllDockets
    },
    icon() {
      if (this.selectedAllDockets) return 'mdi-close-box'
      if (this.selectedSomeDockets) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    sortedDockets() {
      return [...this.filteredOpenDockets].sort((a, b) => {
        const textA = a.driver.name.toLowerCase()
        const textB = b.driver.name.toLowerCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0
      })
    },
    selectedDockets() {
      const array = this.sortedDockets.filter(docket => {
        return this.docketIds.includes(docket.id)
      })
      return array
    },
    filteredByContractor() {
      if (this.openDockets.length && this.contractorId) {
        return this.openDockets.filter(
          item => this.contractorId === item.vehicle.contractor.id
        )
      } else {
        return this.openDockets
      }
    },
    filteredOpenDockets() {
      if (this.filteredByContractor.length && this.sourceId) {
        return this.filteredByContractor.filter(
          item => this.sourceId === item.sourceId
        )
      } else {
        return this.filteredByContractor
      }
    }
  },
  methods: {
    toggleAll() {
      this.$nextTick(() => {
        if (this.selectedAllDockets) {
          this.docketIds = []
        } else {
          this.docketIds = []
          this.openDockets.forEach(({ id }) => this.docketIds.push(id))
        }
      })
    },
    toggleDocket(id) {
      const index = this.docketIds.indexOf(id)
      if (index > -1) {
        this.docketIds.splice(index, 1)
      } else {
        this.docketIds.push(id)
      }
    },
    showPods(val) {
      this.currentLine = val
      this.dialog = true
    },
    setDocId() {
      if (this.$route.query.id) {
        this.getGpsLines(this.$route.query.id)
      }
    },
    setColors() {
      for (const item of this.openDockets) {
        if (!item.color) item.color = item.driver.color.substr(0, 7)
        if (!item.color) item.color = 'blue'
      }
    }
  }
}
</script>
