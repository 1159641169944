<template>
  <Loading v-if="loadingMasterFiles" />
  <v-col cols="12" class="pa-0" v-else>
    <v-data-iterator
      :items="subjects"
      :items-per-page.sync="pagination.itemsPerPage"
      :page.sync="pagination.page"
      :sort-by="pagination.sortBy[0]"
      :sort-desc="pagination.sortDesc[0]"
      hide-default-footer
    >
      <template v-slot:no-data>
        <v-row class="mt-8 mx-4" align="center" justify="center">
          No matching records found
        </v-row>
      </template>
      <template v-slot:header>
        <v-row class="mt-8 mx-4" align="center" justify="center">
          <span class="grey--text mb-3">Items per page</span>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                text
                color="primary"
                class="ml-2 mb-3"
                v-bind="attrs"
                v-on="on"
              >
                {{ pagination.itemsPerPage }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(number, index) in itemsPerPageArray"
                :key="index"
                @click="() => (pagination.itemsPerPage = number)"
              >
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-spacer></v-spacer>

          <span class="mr-4 mb-3 grey--text">
            Page {{ pagination.page }} of {{ count }}
          </span>
          <v-btn
            outlined
            color="primary"
            small
            :disabled="pagination.page <= 1"
            icon
            class="mr-1 mb-3"
            @click="() => (pagination.page = pagination.page - 1)"
          >
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            outlined
            color="primary"
            small
            :disabled="pagination.page >= count"
            icon
            class="ml-1 mb-3"
            @click="() => (pagination.page = pagination.page + 1)"
          >
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
      <template v-slot:default="{ items }">
        <transition-group name="data-list">
          <v-col
            cols="12"
            sm="6"
            lg="4"
            xl="3"
            v-for="subject in items"
            :key="subject.id"
            class="pb-0 data-list-item"
          >
            <MasterFileCard
              :masterFile="masterFile"
              :fields="fields"
              :subject="subject"
              :text="'Test'"
              :label="'Test'"
              :icon="$store.state.global.pages[$route.name].icon"
              :loading="loading"
              :onEdit="onEdit"
              :onUpdatePassword="onUpdatePassword"
              :onValidateLogin="onValidateLogin"
              :onDelete="onDelete"
            />
          </v-col>
        </transition-group>
      </template>

      <template v-slot:footer>
        <v-row class="ma-4" align="center" justify="center">
          <span class="grey--text mb-3">Items per page</span>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                dark
                text
                color="primary"
                class="ml-2 mb-3"
                v-bind="attrs"
                v-on="on"
              >
                {{ pagination.itemsPerPage }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(number, index) in itemsPerPageArray"
                :key="index"
                @click="() => (pagination.itemsPerPage = number)"
              >
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-spacer></v-spacer>

          <span class="mr-4 mb-3 grey--text">
            Page {{ pagination.page }} of {{ count }}
          </span>
          <v-btn
            outlined
            color="primary"
            small
            :disabled="pagination.page <= 1"
            icon
            class="mr-1 mb-3"
            @click="() => (pagination.page = pagination.page - 1)"
          >
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            outlined
            color="primary"
            small
            :disabled="pagination.page >= count"
            icon
            class="ml-1 mb-3"
            @click="() => (pagination.page = pagination.page + 1)"
          >
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-iterator>
  </v-col>
</template>

<script>
import Store from '@/store/computed/storeHelpers'
import MasterFileCard from './MasterFileCard'
import Loading from '../misc/Loading'
import makeReportPdf from '../misc/shared/makeReportPdf'

export default {
  mixins: [makeReportPdf],
  components: { MasterFileCard, Loading },
  props: {
    masterFile: {
      type: String,
      required: true
    },
    subjects: {
      type: Array,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    loadingMasterFiles: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    onEdit: {
      type: Function,
      required: true
    },
    onUpdatePassword: {
      type: Function,
      required: false
    },
    onValidateLogin: {
      type: Function,
      required: false,
      default: () => false
    },
    onDelete: {
      type: Function,
      required: true
    },
    count: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      itemsPerPageArray: [10, 20, 30, 50, 100]
    }
  },
  computed: {
    pagination: Store.getSet({ store: 'masterfiles', prop: 'pagination' })
  },
  methods: {
    setPdfData({ header, data, keys }) {
      const columnStyles = {}
      for (const key of keys) {
        if (key.type == 'decimal' || key.type == 'curr')
          columnStyles[key.text] = { halign: 'right' }
        if (key.type == 'time') columnStyles[key.text] = { minCellWidth: 1.6 }
      }
      let total = 0
      let costTotal = 0
      data.forEach(item => {
        const sell = item['Total'] ? item['Total'] : item['Sell Total']
        const cost = item['Cost Total']
        if (sell) total += Number(sell)
        if (cost) costTotal += Number(cost)
      })
      return {
        title: header.pdfTitle ? header.pdfTitle : 'Report title',
        filename: `${header.name}.pdf`,
        type: header.pdfType ? header.pdfType : header.name,
        total: total ? this.addCommas(total.toFixed(2)) : null,
        costTotal: costTotal ? this.addCommas(costTotal.toFixed(2)) : null,
        orientation: header.orientation,
        pageSize: header.pageSize,
        footerText: 'Footer text here',
        lines: data,
        columnStyles
      }
    }
  }
}
</script>
