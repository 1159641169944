<template>
  <v-hover v-slot:default="{ hover }">
    <v-card
      class="justify-center card-outlined"
      :elevation="hover ? 1 : 0"
      :class="{ 'on-hover': hover }"
      :ripple="false"
      @click="() => handleClick(pod.id)"
      height="72px"
    >
      <v-container>
        <v-row>
          <v-col v-if="imageUrl" cols="3" class="pa-0">
            <v-img
              v-if="imageUrl"
              :src="imageUrl"
              min-height="70px"
              max-height="70px"
              @click="dialog = true"
              class="mx-auto my-auto"
            ></v-img>
          </v-col>
          <v-col :cols="imageUrl ? '9' : '12'" class="pa-0">
            <v-card-text class="pt-1 pb-2 ">
              <v-card-title
                class="pt-0 px-0 font-weight-regular text-truncate primary--text d-flex justify-space-between"
                style="font-size: 1.1rem;"
              >
                {{ pod.report ? pod.report.name : titles[pod.type] }}
                <span
                  v-if="pod.docketLine && pod.type === 'timesheet'"
                  class="subtitle-2 grey--text text--lighten-1"
                >
                  {{ getPodHours(pod) }}
                </span>
                <v-tooltip bottom v-if="isImage">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                      color="primary"
                      @click="downloadFile(pod)"
                    >
                      <v-icon>mdi-cloud-download</v-icon>
                    </v-btn>
                  </template>
                  <span>Download image</span>
                </v-tooltip>
              </v-card-title>
              <v-card-subtitle class="pa-0 text-truncate">
                {{ makeShortDateAndTime(pod.createdAt) }}
              </v-card-subtitle>
              <div v-if="pod.notes">
                {{ pod.notes }}
              </div>
            </v-card-text>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-hover>
</template>

<script>
import { downloadPod } from '../../services/requests/allocations'
import FormatDates from '../../services/mixins/formatDates'
import Auth from '../../services/authentication'

export default {
  mixins: [FormatDates],
  props: {
    pod: {
      type: Object,
      required: true
    },
    close: {
      type: Function,
      required: false
    },
    openEditDialog: {
      type: Function,
      required: false
    },
    handleClick: {
      type: Function,
      required: false
    }
  },
  data() {
    return {
      titles: {
        form: 'Site report',
        photo: 'Image captured',
        signature: 'Digital signature',
        timesheet: 'Timesheet'
      },
      dialog: false,
      imageUrl: null
    }
  },
  async mounted() {
    await this.getUrl()
  },
  computed: {
    isImage() {
      return this.pod.type === 'photo' || this.pod.type === 'signature'
    },
    fields() {
      if (this.pod.type !== 'form' && this.pod.fields) return
      const fields = JSON.parse(this.pod.fields)
      return fields
    }
  },
  methods: {
    async getUrl() {
      if (this.pod.url) this.imageUrl = this.pod.url
      if (this.pod.key) {
        try {
          const result = await Auth.getS3Url({
            key: this.pod.key,
            bucket: 'payloader-pods'
          })
          this.imageUrl = result.data.result
        } catch (err) {
          console.log(err)
        }
      }
    },
    async downloadFile(pod) {
      const result = await downloadPod(pod.key)
      if (!result) return
      window.open(result.data.result)
    },
    getPodHours(pod) {
      if (pod.docketLine.hours)
        return ` (${pod.docketLine.hours.toFixed(2)} hours)`
      if (pod.docketLine.start) {
        const start = new Date(pod.docketLine.start)
        const end = new Date()
        return ` (${(
          (end.getTime() - start.getTime()) /
          1000 /
          60 /
          60
        ).toFixed(2)} hours)`
      }
      return ''
    }
  }
}
</script>
