<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span>
          View
          {{ $store.state.global.pages.plan.titleSingular }}
          -
          <span class="pl-1 subtitle-1">
            ID: <span class="font-weight-bold">{{ item.id }}</span>
          </span>
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip
        bottom
        v-if="userAuthClass && !item.closed && $route.name == 'plan'"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="showEditDialog">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span
          >Edit this
          {{ $store.state.global.pages.plan.titleSingular.toLowerCase() }}</span
        >
      </v-tooltip>
    </v-toolbar>

    <v-container fluid>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-sheet outlined :color="item.isStaged ? 'amber' : ''" rounded>
            <v-card flat class="card-outlined">
              <v-card-text class="pb-0">
                <CardHeader
                  :title="item.source.project.name"
                  :icon="$store.state.global.pages.plan.icon"
                />
                <v-container fluid class="px-0">
                  <v-row>
                    <Detail
                      icon="mdi-calendar"
                      :text="dateString"
                      cols="6"
                      lg="4"
                    />
                    <Detail
                      :icon="$store.state.global.pages.source.icon"
                      :text="item.source.name"
                      cols="6"
                      lg="4"
                    />
                    <Detail
                      v-if="item.travelMode"
                      :icon="$store.state.global.pages.vehicle.icon"
                      :text="item.travelMode.toUpperCase()"
                      cols="6"
                      lg="4"
                    />
                    <Detail
                      icon="mdi-note"
                      :text="item.notes"
                      v-if="item.notes"
                      lg="4"
                    />
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      v-if="
                        vehicles.length && drivers.length && planVehicles.length
                      "
                    >
                      <h4>
                        Selected
                        {{ $store.state.global.pages.plan.titleSingular }}
                        {{ $store.state.global.pages.vehicle.title }}
                      </h4>
                      <span
                        v-for="plan in planVehicles"
                        :key="`${plan.id}-${plan.driver ? plan.driver.id : 0}`"
                      >
                        <DriverRoute
                          :item="{ ...plan, source: item.source }"
                          :useButton="false"
                          :lines="plan.linesAllocated"
                        />
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-col class="pb-0">
                <v-divider></v-divider>
              </v-col>
              <v-card-actions>
                <v-row v-if="$vuetify.breakpoint.smAndUp" class="my-1">
                  <v-col
                    cols="12"
                    class="py-0"
                    v-if="item.updatedAt !== item.createdAt"
                  >
                    <TimeAgo
                      class="grey--text"
                      :subject="item"
                      :time="item.updatedAt"
                      needsParse
                      updated
                    >
                      <v-icon small color="grey" slot="icon" class="mr-2">
                        mdi-clock-outline
                      </v-icon>
                    </TimeAgo>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <TimeAgo
                      class="grey--text"
                      :subject="item"
                      :time="item.createdAt"
                      needsParse
                      created
                    >
                      <v-icon small color="grey" slot="icon" class="mr-2">
                        mdi-clock-outline
                      </v-icon>
                    </TimeAgo>
                  </v-col>
                </v-row>
                <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
                <div v-if="allowOptimise">
                  <MapboxOptimise
                    :updateLinesWithWaypoints="updateLinesWithWaypoints"
                    :planLines="planLines"
                    :item="item"
                    :planVehicles="planVehicles"
                    v-if="useMapbox"
                  />
                  <GeoapifyOptimise
                    v-else
                    :updateLinesWithWaypoints="updateLinesWithWaypoints"
                    :planLines="planLines"
                    :item="item"
                    :planVehicles="planVehicles"
                  />
                </div>
                <span class="show-route" v-if="planLines && !this.item.closed">
                  <v-tooltip top :disabled="!unallocatedLines.length">
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <v-btn
                          :disabled="!!unallocatedLines.length"
                          color="primary"
                          text
                          @click="
                            () =>
                              (createAllocationsDialog = !createAllocationsDialog)
                          "
                        >
                          <v-icon left>
                            {{ $store.state.global.pages.allocation.icon }}
                          </v-icon>
                          <span
                            >Create
                            {{
                              $store.state.global.pages.allocation.title
                            }}</span
                          >
                        </v-btn>
                      </div>
                    </template>
                    <span>
                      Unallocated lines exist!
                    </span>
                  </v-tooltip>
                </span>
              </v-card-actions>
            </v-card>
          </v-sheet>
        </v-col>

        <v-col cols="12">
          <PlanLineTable
            :headers="lineHeaders"
            :items="planLines"
            :loading="loading"
            :loadingLines="loadingLines"
            :closeParent="close"
            :selectedId="selectedId"
          />
        </v-col>
        <v-dialog v-model="createAllocationsDialog" width="900px">
          <CreateAllocations
            :loading="loadingAllocations"
            :close="() => (createAllocationsDialog = false)"
            :totalAllocations="allocationsToCreate.length"
            :action="createAllocations"
          />
        </v-dialog>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import {
  getLinesByPlanId,
  updatePlanLinesWithWaypoint
} from '../../services/requests/plans'
import { addAllocations } from '../../services/requests/allocations'
import LineHeaders from './lines/mixins/lineHeaders'
import HeaderFilters from '../../services/mixins/headerFilters'

import PlanLineTable from './lines/PlanLineTable'
import FormatDates from '@/services/mixins/formatDates'
import Detail from '../misc/Detail'
import PlanLine from '../../services/models/planLine'
import TimeAgo from '../misc/TimeAgo'
import CardHeader from '../misc/shared/CardHeader'
import CreateAllocations from './CreateAllocations'
import Plan from '../../services/models/plan'
import DriverRoute from './DriverRoute'
import GeoapifyOptimise from './components/geoapify/GeoapifyOptimise'
import MapboxOptimise from './components/mapbox/MapboxOptimise'

export default {
  mixins: [FormatDates, LineHeaders, HeaderFilters],
  components: {
    PlanLineTable,
    Detail,
    TimeAgo,
    CardHeader,
    CreateAllocations,
    DriverRoute,
    GeoapifyOptimise,
    MapboxOptimise
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    close: {
      type: Function,
      required: true
    },
    openEditDialog: {
      type: Function,
      required: false
    },
    loading: {
      type: Boolean,
      required: true
    },
    open: {
      type: Function,
      required: false
    },
    selectedId: {
      type: Number,
      required: false
    },
    vehicles: {
      type: Array,
      required: true
    },
    drivers: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      planLines: [],
      loadingLines: false,
      loadingAllocations: false,
      allocatedVehicles: [],
      useMapbox: false,
      createAllocationsDialog: false,
      allowOptimise: process.env.VUE_APP_ALLOW_OPTIMISE === 'true'
    }
  },
  mounted() {
    this.getLines()
  },
  computed: {
    lineHeaders() {
      return Number(this.window?.width) >= 600
        ? this.allPossibleHeaders
        : this.mobileHeaders
    },
    dateString() {
      let str = this.makeShortDate(this.item.date)
      if (this.item.start) str += ` (Start time: ${this.item.start})`
      return str
    },
    planVehicles() {
      const vehicleIds = JSON.parse(this.item.planVehicles)
      const vehicles = []
      for (const planVehicle of vehicleIds) {
        const vehicle = this.vehicles?.find(
          ({ id }) => id === planVehicle.vehicleId
        )
        const driver = this.drivers?.find(
          ({ id }) => id === planVehicle.driverId
        )
        const allocatedVehicle = {
          ...vehicle,
          linesAllocated: this.planLines.filter(
            line => line.vehicleId === vehicle.id
          )
        }
        vehicles.push({ ...allocatedVehicle, driver })
      }
      return vehicles
    },
    unallocatedLines() {
      return this.planLines.filter(line => !line.vehicleId || !line.driverId)
    },
    allocationsToCreate() {
      const allocations = []
      const vehicles = []
      for (const line of this.planLines) {
        if (!vehicles.includes(line.vehicleId)) vehicles.push(line.vehicleId)
      }
      for (const vehicleId of vehicles) {
        let driverId = null
        const lines = this.planLines
          .filter(line => line.vehicleId === vehicleId)
          .map(line => {
            driverId = line.driverId
            return {
              type: 'DELIVERY',
              destinationId: line.destinationId,
              itemTypeId: line.itemTypeId,
              qty: line.qty,
              notes: line.notes,
              waypointIndex: line.waypointIndex,
              delTime: line.delTime,
              planLineId: line.id,
              addressStreet1: line.destination.addressStreet1,
              addressStreet2: line.destination.addressStreet2,
              addressCity: line.destination.addressCity,
              addressState: line.destination.addressState
            }
          })
        allocations.push({
          reference: this.item.reference,
          date: this.item.date,
          endDate: null,
          isDay: this.item.isDay,
          isStaged: false,
          hasDuplicate: false,
          sourceId: this.item.sourceId,
          vehicleId,
          driverId,
          notes: this.item.notes,
          sourceConfirmed: 'NOT SET',
          sourceNotes: '',
          destinationConfirmed: 'NOT SET',
          destinationNotes: '',
          amendmentsConfirmed: 'NOT SET',
          amendmentsNotes: '',
          planId: this.item.id,
          lines
        })
      }
      return allocations
    }
  },
  methods: {
    async getLines() {
      try {
        this.loadingLines = true
        const result = await getLinesByPlanId(this.item.id)
        const items = result.data.result
        this.planLines = items.map(item => new PlanLine(item))
        this.loadingLines = false
      } catch (err) {
        console.log(err)
      }
    },
    async updateLinesWithWaypoints(linesWithWayPointData) {
      this.loadingLines = true
      const lines = []
      for (const line of linesWithWayPointData) {
        lines.push({
          id: line.id,
          destinationId: line.destinationId,
          itemTypeId: line.itemTypeId,
          qty: line.qty,
          notes: line.notes,
          vehicleId: line.vehicleId,
          driverId: line.driverId,
          waypointIndex: line.waypointIndex,
          delTime: line.delTime,
          planId: line.planId
        })
      }
      try {
        const result = await updatePlanLinesWithWaypoint({ lines })
        if (result) {
          const newLines = []
          if (result.data.lines)
            for (const line of result.data.lines) {
              const newLine = new PlanLine(line)
              newLines.push(newLine)
              this.$store.commit(`plans/updateArrayStateById`, {
                prop: 'lines',
                editedValue: newLine
              })
            }
          this.planLines = newLines
          this.loadingLines = false
          this.snackUpdated()
        }
      } catch (err) {
        this.loadingLines = false
        console.log(err)
      }
    },
    async createAllocations() {
      this.loadingAllocations = true
      const payload = {
        allocations: this.allocationsToCreate,
        planId: this.item.id
      }
      try {
        const result = await addAllocations(payload)
        if (!result) {
          this.createAllocationsDialog = false
          this.loadingAllocations = false
          this.snack({
            text: `No ${this.$store.state.global.pages.allocation.titleSingular}(s) were created!`,
            color: 'red'
          })
          return
        }
        this.createAllocationsDialog = false
        this.loadingAllocations = false
        this.snack({
          text: `${result.data.result.allocationIds.length} ${this.$store.state.global.pages.allocation.titleSingular}(s) created!`,
          color: 'primary'
        })
        this.$store.commit('plans/updateArrayStateById', {
          prop: 'plans',
          editedValue: new Plan(result.data.result.plan)
        })
        this.close()
      } catch (err) {
        this.createAllocationsDialog = false
        this.loadingAllocations = false
        this.snack({
          text: 'An error occured!',
          color: 'red'
        })
        console.log(err)
      }
    },
    showEditDialog() {
      this.openEditDialog()
      this.close()
    }
  }
}
</script>

<style>
.show-route:hover {
  cursor: pointer;
}
</style>
