export default {
  data() {
    return {
      allRateHeaders: [
        {
          text: 'Active',
          value: 'active',
          type: 'boolean'
        },
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: this.$store.state.global.pages.project.titleSingular,
          value: 'project.name'
        },
        {
          text: this.$store.state.global.pages.source.titleSingular,
          value: 'source.name'
        },
        {
          text: 'Vehicles',
          value: 'truncatedVehicleTypes'
        },
        {
          text: this.$store.state.global.pages.itemType.titleSingular,
          value: 'truncatedItemTypes'
        },
        {
          text: this.$store.state.global.pages.destination.titleSingular,
          value: 'destination.name'
        },
        {
          text: 'Cost Price',
          value: '_costPrice',
          type: 'curr',
          align: 'right'
        },
        {
          text: 'Cost Unit',
          value: 'costUnit'
        },
        {
          text: 'Sell Price',
          value: '_sellPrice',
          type: 'curr',
          align: 'right'
        },
        {
          text: 'Sell Unit',
          value: 'sellUnit'
        },
        {
          text: 'Export Amount',
          value: '_exportAmount',
          type: 'curr',
          align: 'right'
        },
        {
          text: 'Details',
          align: 'left',
          value: 'notes',
          type: 'text'
        },
        {
          text: 'Admin fee',
          value: 'adminFeeRequired',
          type: 'boolean'
        },
        {
          text: 'Use tonnage',
          value: 'useTonnage',
          type: 'boolean'
        },
        { text: 'Created By', value: 'createdBy.name' },
        { text: 'Updated By', value: 'updatedBy.name' },

        { text: 'Created At', value: '_createdAt', type: 'date' },
        { text: 'Updated At', value: '_updatedAt', type: 'date' }
      ]
    }
  }
}
