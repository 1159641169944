import Store from '@/store/computed/storeHelpers'
import { getSources } from '../../requests/sources'
export default {
  mounted() {
    if (this.pagination) this.initPagination()
    this.getSources()
  },
  data() {
    return {
      loadedSources: false,
      sourceCount: 0
    }
  },
  watch: {
    pagination: {
      deep: true,
      handler() {
        this.getSources()
      }
    },
    sortDesc(val) {
      this.pagination.sortDesc = [val]
    },
    showInactive() {
      this.getSources()
    },
    showRequest() {
      this.getSources()
    }
  },
  computed: {
    sortDesc: Store.getSet({ store: 'masterfiles', prop: 'sortDesc' }),
    sources: Store.getSet({ store: 'sources', prop: 'sources' }),
    loadingSources: Store.getSet({
      store: 'sources',
      prop: 'loadingSources'
    }),
    parentId: Store.getSet({ store: 'sources', prop: 'parentId' }),
    showInactive: Store.getSet({ store: 'masterfiles', prop: 'showInactive' }),
    showRequest: Store.getSet({ store: 'masterfiles', prop: 'showRequest' })
  },
  methods: {
    async getSources() {
      try {
        this.loadingSources = true
        const payload =
          this.masterFile == 'source'
            ? {
                pagination: this.pagination,
                filterBy: this.filterBy,
                projectId: this.parentId,
                showInactive: this.showInactive,
                showRequest: this.showRequest
              }
            : {}
        const result = await getSources(payload)
        if (result) {
          this.sources = result.data.result
          this.sourceCount = result.data.count
        }
        this.loadedSources = true
        this.loadingSources = false
      } catch (err) {
        console.log(err)
        this.loadingSources = false
      }
    }
  }
}
