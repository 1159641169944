import Store from '@/store/computed/storeHelpers'
import BillAllHeaders from './billAllHeaders'
export default {
  mixins: [BillAllHeaders],
  computed: {
    defaultHeaders: Store.getSet({
      store: 'bills',
      prop: 'billDefaultHeaders'
    }),
    allPossibleHeaders() {
      return this.allBillHeaders
    }
  }
  // data() {
  //   return {

  //   }
  // }
}
