import RctiLine from '@/services/models/rctiLine'

export default {
  methods: {
    makePdf(data) {
      const results = []
      for (const line of data) {
        const from = [
          line.contractor.name,
          `ABN: ${line.contractor.abn || ''}`,
          line.contractor.addressStreet1 || '',
          line.contractor.addressStreet2
            ? line.contractor.addressStreet2
            : null,
          line.contractor.addressStreet3 ? line.contractor.address3 : null,
          line.contractor.addressCity
            ? `${line.contractor.addressCity} ${line.contractor.addressState ||
                ''} ${line.contractor.addressPostCode}`
            : null
        ].filter(item => item != null)
        const to = this.globalSettings.footerText.split('| ')

        const info = [
          { key: 'Invoice #', data: line.id.toString() },
          { key: 'Date', data: line._date },
          {
            key: this.$store.state.global.pages.project.titleSingular,
            data: line.project.name
          },
          { key: 'Status', data: line.status }
        ].filter(item => item.data != null)

        const extraInfo = [
          { key: 'Period', data: line.billingPeriod },
          { key: 'Ref #', data: line.externalRef },
          {
            key: `${this.$store.state.global.pages.docket.titleSingular} lines`,
            data: line.docketLineCount.toString()
          }
        ].filter(item => item.data != null)

        const lines = line.lines.map(item => {
          item = new RctiLine(item)
          return {
            Description: item.description,
            Qty: 1,
            Price: '$' + item._costTotal,
            Total: '$' + item._costTotal
          }
        })
        const tax = 0.1
        const total = line.total
          ? this.addCommas((line.total * (1 + tax)).toFixed(2))
          : '0.00'
        const taxTotal = tax
          ? this.addCommas((line.total * tax).toFixed(2))
          : '0.00'

        const invoiceNotes = line.invoiceNotes || ''
        const footerText = [
          '',
          'Please contact State Road Constructions within 7 days of receipt with any enquiries at info@stateroadconstructions.com.au',
          from.filter(item => item != null).join(' | ')
        ]
        const pdfName = `${this.$store.state.global.pages.rcti.titleSingular.toLowerCase()}_${
          line.id
        }.pdf`
        const columnStyles = {
          Qty: { halign: 'right' },
          Price: { halign: 'right' },
          Total: { halign: 'right' }
        }

        const columns = lines[0]
          ? Object.keys(lines[0]).map(str => {
              return { title: str, dataKey: str }
            })
          : []

        const makeFoot = () => {
          const totalsOffset = 2
          const footer = []
          const offset = columns.length
            ? new Array(columns.length - totalsOffset).fill('')
            : []
          if (tax) footer.push([...offset, 'Tax', '$' + taxTotal])
          if (total) footer.push([...offset, 'Total (inc)', '$' + total])
          return footer
        }
        const result = {
          pdfName,
          docHeading: this.$store.state.global.pages.rcti.titleSingular.toUpperCase(),
          docSubheading: 'Recipient Created Tax Invoice',
          columns,
          foot: makeFoot(),
          columnStyles,
          pdfData: {
            subjectName: line.contractor.name,
            id: line.id.toString(),
            type: 'rcti',
            from,
            to,
            info,
            extraInfo,
            lines,
            total,
            invoiceNotes,
            footerText
          }
        }

        results.push(result)
      }
      return results
    }
  }
}
