<template>
  <v-container
    fluid
    v-if="$store.state.user.userAuthClass[`${$route.name}Read`]"
  >
    <v-row>
      <v-col cols="12" class="py-0">
        <ControlCard
          :addDialog="addDialog"
          :toggleAdd="toggleAdd"
          :toggleDrawer="() => (drawer = !drawer)"
          filterLabel="Masterfile"
          :filterCols="models"
          :filterCol="filterCol"
          :filterBy="filterBy"
          :onFilterColChange="val => (filterCol = val)"
          :onFilterByChange="val => (filterBy = val)"
        >
          <template v-slot:header>
            <CardHeader
              :title="$store.state.global.pages[$route.name].title"
              :icon="$store.state.global.pages[$route.name].icon"
              :actions="controlActions"
            />
          </template>
        </ControlCard>
      </v-col>
      <v-col cols="12" v-if="loadedUserSettings">
        <ComplianceView />
      </v-col>
    </v-row>

    <FilterDrawer
      v-model="drawer"
      :contractors="contractorsLite"
      :vehicles="vehiclesLite"
      :drivers="driversLite"
    />

    <v-dialog v-model="addDialog" width="900px">
      <ComplianceAdd
        :toggleAdd="toggleAdd"
        :contractors="contractorsLite"
        :vehicles="vehiclesLite"
        :drivers="driversLite"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import Store from '../../store/computed/storeHelpers'
import getContractorsLite from '../../services/mixins/getMasterFiles/lite/getContractorsLite'
import getVehiclesLite from '../../services/mixins/getMasterFiles/lite/getVehiclesLite'
import getDriversLite from '../../services/mixins/getMasterFiles/lite/getDriversLite'
import models from '../../components/compliances/mixins/models'

import ControlCard from '../../components/misc/shared/ControlCard'
import CardHeader from '../../components/misc/shared/CardHeader'
import FilterDrawer from '../../components/misc/shared/FilterDrawer'
import ComplianceAdd from '@/components/compliances/ComplianceAdd'
import ComplianceView from '../../components/compliances/ComplianceView'
export default {
  mixins: [getContractorsLite, getVehiclesLite, getDriversLite, models],
  components: {
    ControlCard,
    CardHeader,
    FilterDrawer,
    ComplianceAdd,
    ComplianceView
  },
  data() {
    return {
      drawer: false,
      addDialog: false,
      filterCols: [
        {
          value: 'source.name',
          text: this.$store.state.global.pages.source.titleSingular
        },
        {
          value: 'driver.contractor.name',
          text: this.$store.state.global.pages.contractor.titleSingular
        },
        {
          value: 'driver.name',
          text: this.$store.state.global.pages.driver.titleSingular
        },
        {
          value: 'vehicle.name',
          text: this.$store.state.global.pages.vehicle.titleSingular
        },
        {
          value: 'vehicle.vehicleType.name',
          text: this.$store.state.global.pages.vehicleType.titleSingular
        },
        {
          value: 'allocation.id',
          text: 'ID'
        }
      ]
    }
  },
  computed: {
    loadedUserSettings: Store.getSet({
      store: 'global',
      prop: 'loadedUserSettings'
    }),
    filterCol: Store.getSet({ store: 'compliances', prop: 'filterCol' }),
    filterBy: Store.getSet({ store: 'compliances', prop: 'filterBy' }),
    controlActions() {
      return [
        {
          action: () => (this.drawer = !this.drawer),
          icon: 'mdi-filter-variant-plus',
          toolTipText: 'Filters',
          color: 'grey'
        }
      ]
    },
    selectedContractorId: Store.getSet({
      store: 'global',
      prop: 'selectedContractorId'
    }),
    selectedDriverId: Store.getSet({
      store: 'global',
      prop: 'selectedDriverId'
    }),
    selectedVehicleId: Store.getSet({
      store: 'global',
      prop: 'selectedVehicleId'
    })
  },
  methods: {
    toggleAdd() {
      this.addDialog = !this.addDialog
    },
    setModelId() {
      this.selectedDriverId = null
      this.selectedContractorId = null
      this.selectedVehicleId = null
      if (this.$route.query.model == 'driver')
        this.selectedDriverId = this.$route.query.id
      if (this.$route.query.model == 'contractor')
        this.selectedContractorId = this.$route.query.id
      if (this.$route.query.model == 'vehicle')
        this.selectedVehicleId = this.$route.query.id
    }
  },
  mounted() {
    if (this.$route.query.id && this.$route.query.model)
      setTimeout(this.setModelId, 500)
  }
}
</script>
