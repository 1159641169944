import Store from '@/store/computed/storeHelpers'
import { getDestinations } from '../../requests/destinations'
export default {
  mounted() {
    if (this.pagination) this.initPagination()
    this.getDestinations()
  },
  data() {
    return {
      destinationCount: 0,
      loadedDestinations: false
    }
  },
  watch: {
    pagination: {
      deep: true,
      handler() {
        this.getDestinations()
      }
    },
    sortDesc(val) {
      this.pagination.sortDesc = [val]
    },
    showRequest() {
      this.getDestinations()
    }
  },
  computed: {
    destinations: Store.getSet({ store: 'destinations', prop: 'destinations' }),
    sortDesc: Store.getSet({ store: 'masterfiles', prop: 'sortDesc' }),
    showRequest: Store.getSet({ store: 'masterfiles', prop: 'showRequest' }),
    loadingDestinations: Store.getSet({
      store: 'destinations',
      prop: 'loadingDestinations'
    })
  },
  methods: {
    async getDestinations() {
      try {
        this.loadingDestinations = true
        const payload =
          this.masterFile == 'destination'
            ? {
                pagination: this.pagination,
                filterBy: this.filterBy,
                showRequest: this.showRequest
              }
            : {}
        const result = await getDestinations(payload)
        if (result) {
          this.destinations = result.data.result
          this.destinationCount = result.data.count
        }
        this.loadedDestinations = true
        this.loadingDestinations = false
      } catch (err) {
        console.log(err)
        this.loadingDestinations = false
      }
    }
  }
}
