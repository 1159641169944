<template>
  <v-card color="grey lighten-4" min-width="350px" flat>
    <v-toolbar :color="event.color" dark>
      <v-toolbar-title v-html="event.name"></v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <div>
        {{ event.projectName }}<br />
        {{ event.sourceName }}
      </div>
      <div class="d-flex justify-space-between mt-3">
        <span>{{ event.vehicleName }} ({{ event.vehicleType }}) </span>
        <span
          >{{ event.lineCount }}
          {{ event.lineCount > 1 ? 'deliveries' : 'delivery' }}</span
        >
      </div>
      <div>
        {{ event.contractorName }}
      </div>
      <div class="d-flex justify-space-between font-weight-bold mt-2">
        <span>{{ $store.state.global.pages.destination.titleSingular }}</span>
        <span>{{ $store.state.global.pages.itemType.titleSingular }}</span>
      </div>
      <div v-if="loading">
        <v-progress-linear indeterminate></v-progress-linear>
      </div>
      <div v-else v-for="line of lines" :key="line.id">
        <div class="d-flex justify-space-between">
          <span>{{ line.destination.name }}</span>
          <span>{{ line.itemType.name }}</span>
        </div>
      </div>
      <div class="py-3">
        <v-divider></v-divider>
      </div>
      <v-row>
        <v-col
          cols="12"
          class="py-0"
          v-if="event.updatedAt !== event.createdAt"
        >
          <TimeAgo
            class="grey--text"
            :subject="event"
            :time="event.updatedAt"
            needsParse
            updated
          >
            <v-icon small color="grey" slot="icon" class="mr-2">
              mdi-clock-outline
            </v-icon>
          </TimeAgo>
        </v-col>
        <v-col cols="12" class="py-0">
          <TimeAgo
            class="grey--text"
            :subject="event"
            :time="event.createdAt"
            needsParse
            created
          >
            <v-icon small color="grey" slot="icon" class="mr-2">
              mdi-clock-outline
            </v-icon>
          </TimeAgo>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="d-flex justify-space-between">
      <v-btn text color="secondary" @click="detailsDialog = !detailsDialog">
        Show {{ $store.state.global.pages.allocation.titleSingular }}
      </v-btn>
      <v-btn text color="secondary" @click="close">
        Close
      </v-btn>
    </v-card-actions>
    <v-dialog
      v-model="detailsDialog"
      @click:outside="() => (detailsDialog = false)"
      @keydown.esc="() => (detailsDialog = false)"
      fullscreen
    >
      <AllocationDetails
        :item="event.allocation"
        :loading="loading"
        :close="() => (detailsDialog = false)"
        :open="() => (detailsDialog = true)"
        :openEditDialog="() => (editDialog = true)"
      />
    </v-dialog>
    <v-dialog
      v-model="editDialog"
      @click:outside="() => (editDialog = false)"
      @keydown.esc="() => (editDialog = false)"
      max-width
      fullscreen
    >
      <AllocationEdit
        :dialog="editDialog"
        :item="event.allocation"
        :loading="loading"
        :action="editAllocation"
        :close="() => (editDialog = false)"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import TimeAgo from '../../misc/TimeAgo'
import { getLinesByAllocationId } from '../../../services/requests/allocations'
import AllocationLine from '../../../services/models/allocationLine'
import AllocationDetails from '../AllocationDetails'
import AllocationEdit from '../AllocationEdit'
import Common from '../mixins/allocationCommon'

export default {
  mixins: [Common],
  components: {
    TimeAgo,
    AllocationDetails,
    AllocationEdit
  },
  props: {
    event: {
      type: Object,
      required: true
    },
    close: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      lines: [],
      detailsDialog: false,
      editDialog: false
    }
  },
  mounted() {
    this.getLines()
  },
  methods: {
    async getLines() {
      this.loading = true
      const result = await getLinesByAllocationId(this.event.id)
      const items = result.data.result
      this.lines = items.map(item => new AllocationLine(item))
      this.loading = false
    }
  }
}
</script>
