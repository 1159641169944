<template>
  <v-container
    fluid
    v-if="$store.state.user.userAuthClass[`${$route.name}Read`]"
  >
    <v-row>
      <v-col cols="12" class="py-0">
        <ControlCard
          :addDialog="addDialog"
          :toggleAdd="toggleAdd"
          :toggleDrawer="() => (drawer = !drawer)"
          :filterCols="filterCols"
          :filterCol="filterCol"
          :filterBy="filterBy"
          :onFilterColChange="val => (filterCol = val)"
          :onFilterByChange="val => (filterBy = val)"
        >
          <template v-slot:header>
            <CardHeader
              :title="$store.state.global.pages[$route.name].title"
              :icon="$store.state.global.pages[$route.name].icon"
              :actions="controlActions"
            />
          </template>
        </ControlCard>
      </v-col>
      <v-col cols="12" v-if="loadedUserSettings">
        <RateView />
      </v-col>
    </v-row>
    <FilterDrawer
      v-model="drawer"
      :users="usersLite"
      :projects="projectsLite"
      :sources="sourcesLite"
      :destinations="destinationsLite"
      :itemTypes="itemTypesLite"
      :vehicleTypes="vehicleTypes"
    />
    <v-dialog v-model="addDialog" width="900px">
      <RateAdd
        :toggleAdd="toggleAdd"
        :projects="projectsLite"
        :sources="sourcesLite"
        :itemTypes="itemTypesLite"
        :destinations="destinationsLite"
        :vehicleTypes="vehicleTypes"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import Store from '@/store/computed/storeHelpers'
import ControlCard from '@/components/misc/shared/ControlCard'
import CardHeader from '@/components/misc/shared/CardHeader'
import RateAdd from '@/components/rates/RateAdd'
import RateView from '@/components/rates/RateView'
import getUsersLite from '@/services/mixins/getMasterFiles/lite/getUsersLite'
import getProjectsLite from '@/services/mixins/getMasterFiles/lite/getProjectsLite'
import getSourcesLite from '@/services/mixins/getMasterFiles/lite/getSourcesLite'
import getVehicleTypes from '@/services/mixins/getMasterFiles/getVehicleTypes'
import getDestinationsLite from '@/services/mixins/getMasterFiles/lite/getDestinationsLite'
import getItemTypesLite from '@/services/mixins/getMasterFiles/lite/getItemTypesLite'
import FilterDrawer from '@/components/misc/shared/FilterDrawer'

export default {
  mixins: [
    getUsersLite,
    getSourcesLite,
    getProjectsLite,
    getDestinationsLite,
    getItemTypesLite,
    getVehicleTypes
  ],
  components: {
    ControlCard,
    CardHeader,
    RateAdd,
    RateView,
    FilterDrawer
  },
  data() {
    return {
      drawer: false,
      addDialog: false
    }
  },
  computed: {
    loadedUserSettings: Store.getSet({
      store: 'global',
      prop: 'loadedUserSettings'
    }),
    filterCol: Store.getSet({ store: 'rates', prop: 'filterCol' }),
    filterBy: Store.getSet({ store: 'rates', prop: 'filterBy' }),
    controlActions() {
      return [
        {
          action: () => (this.drawer = !this.drawer),
          icon: 'mdi-filter-variant-plus',
          toolTipText: 'Filters',
          color: 'grey'
        }
      ]
    },
    filterCols() {
      const cols = [
        {
          value: 'allocationLine.allocation.source.name',
          text: this.$store.state.global.pages.source.titleSingular
        },
        {
          value: 'allocationLine.allocation.vehicle.contractor.name',
          text: this.$store.state.global.pages.contractor.titleSingular
        },
        {
          value: 'allocationLine.allocation.driver.name',
          text: this.$store.state.global.pages.driver.titleSingular
        },
        {
          value: 'allocationLine.allocation.vehicle.name',
          text: this.$store.state.global.pages.vehicle.titleSingular
        },
        {
          value: 'allocationLine.allocation.vehicle.vehicleType.name',
          text: this.$store.state.global.pages.vehicleType.titleSingular
        },
        {
          value: 'pod.id',
          text: 'ID'
        }
      ]
      return cols
    }
  },
  methods: {
    toggleAdd() {
      this.addDialog = !this.addDialog
    }
  }
}
</script>
