<template>
  <MasterFilePage>
    <template v-slot:control>
      <MasterFileControl
        :masterFile="masterFile"
        :addDialog="addDialog"
        :toggleAdd="toggleAdd"
        :onSortChange="updateSortBy"
        :onFilterChange="updateFilterBy"
      />
    </template>
    <template v-slot:view>
      <MasterFileView
        :count="Math.ceil(contractorCount / pagination.itemsPerPage)"
        :masterFile="masterFile"
        :subjects="sortedContractors"
        :fields="fields"
        :loadingMasterFiles="loadingContractors || !loadedProjectsLite"
        :loading="loading"
        :onEdit="editContractor"
        :onDelete="deleteContractor"
      />
    </template>
    <template v-slot:form>
      <v-dialog
        v-model="addDialog"
        width="700"
        @click:outside="() => (addDialog = false)"
        @keydown.esc="() => (addDialog = false)"
        v-if="loadedProjectsLite"
      >
        <MasterFileForm
          :masterFile="masterFile"
          :fields="fields"
          :dialog="addDialog"
          :close="toggleAdd"
          :onSubmit="createContractor"
          :loading="loading"
        />
      </v-dialog>
    </template>
  </MasterFilePage>
</template>

<script>
import {
  addContractor,
  updateContractor,
  deleteContractor
} from '../../../services/requests/contractors'
import getContractors from '../../../services/mixins/getMasterFiles/getContractors'
import contractorFields from '@/services/mixins/fields/contractorFields'
import MasterFileCrudFunctions from '../../../services/mixins/masterFileCrudFunctions'
import Pagination from '../../../services/mixins/getMasterFiles/pagination'

import MasterFilePage from '../../../components/masterFiles/MasterFilePage'
import MasterFileControl from '../../../components/masterFiles/MasterFileControl'
import MasterFileView from '../../../components/masterFiles/MasterFileView'
import MasterFileForm from '../../../components/masterFiles/MasterFileForm'

import { debounce } from 'lodash'
export default {
  mixins: [
    getContractors,
    contractorFields,
    MasterFileCrudFunctions,
    Pagination
  ],
  components: {
    MasterFilePage,
    MasterFileControl,
    MasterFileView,
    MasterFileForm
  },
  data() {
    return {
      masterFile: 'contractor',
      prop: 'contractors',
      addDialog: false,
      sortBy: null,
      filterBy: '',
      loading: false
    }
  },
  watch: {
    filterBy: debounce(function() {
      this.getContractors()
      this.pagination.page = 1
    }, 300)
  },
  computed: {
    sortedContractors() {
      if (this.sortBy) {
        const path = this.sortBy
        return [...this.contractors].sort((a, b) => {
          if (this.getObjectPath(path, a) < this.getObjectPath(path, b)) {
            if (path === 'updatedAt') {
              return 1
            }
            return -1
          } else {
            if (path === 'updatedAt') {
              return -1
            }
            return 1
          }
        })
      } else {
        return this.contractors
      }
    }
  },
  methods: {
    async createContractor(payload) {
      await this.createMasterFile({ payload, action: addContractor })
    },
    async editContractor(contractor) {
      const { id, ...payload } = contractor
      await this.editMasterFile({ id, payload, action: updateContractor })
    },
    async deleteContractor(contractor) {
      await this.deleteMasterFile({
        payload: contractor,
        action: deleteContractor
      })
    },
    toggleAdd() {
      this.addDialog = !this.addDialog
    },
    updateSortBy(sortBy) {
      this.sortBy = sortBy
    },
    updateFilterBy(filterBy) {
      this.filterBy = filterBy
    }
  }
}
</script>
