import {
  getAllocations,
  getAllocationLines,
  getAllocationsByIds,
  getAllocationLinesByIds
} from '../../../services/requests/allocations'
import Allocation from '@/services/models/allocation'
import AllocationLine from '@/services/models/allocationLine'
// import Headers from './allocationLineHeaders'
import ExportKeys from '../../misc/shared/exportKeys'

export default {
  mixins: [ExportKeys],

  computed: {
    exportOptions() {
      const currentHeaders = [...this.headers]
      currentHeaders.pop()
      return [
        {
          name: this.$store.state.global.pages.allocation.title.toLowerCase(),
          fetchData: () => this.getHeaders(),
          keys: this.allocationHeaderKeys
        },
        {
          name: `${this.$store.state.global.pages.allocation.titleSingular} Lines`.toLowerCase(),
          fetchData: () => this.getLines(),
          keys: this.isStaged
            ? this.allocationStagingLineKeys
            : this.allocationLineKeys
        },
        {
          name: 'selected (allocationLine) columns',
          keys: currentHeaders,
          fetchData: () => this.getLines()
        }
      ]
    }
  },
  methods: {
    async getHeaders() {
      if (this.selectedAll) {
        const payload = {
          userId: this.$store.state.global.selectedUserId,
          showClosed: this.$store.state.global.showClosed,
          projectId: this.$store.state.global.selectedProjectId,
          sourceId: this.$store.state.global.selectedSourceId,
          contractorId: this.$store.state.global.selectedContractorId,
          vehicleId: this.$store.state.global.selectedVehicleId,
          driverId: this.$store.state.global.selectedDriverId,
          dateRangeStart: this.$store.state.global.dateRangeStart,
          dateRangeEnd: this.$store.state.global.dateRangeEnd,
          filterCol: this.$store.state.allocations.filterCol,
          filterBy: this.$store.state.allocations.filterBy
        }
        const result = await getAllocations(payload)
        const items = result.data.result.map(item => new Allocation(item))
        return items
        // return result.data.result
      } else {
        const ids = this.selected.map(({ allocationId }) => allocationId)
        const uniq = [...new Set(ids)]
        const result = await getAllocationsByIds({ ids: uniq })
        const items = result.data.result.map(item => new Allocation(item))
        return items
        // return result.data.result
      }
    },
    async getLines() {
      if (this.selectedAll) {
        const payload = this.buildQuery()
        const result = await getAllocationLines(payload)
        const items = result.data.result.map(item => new AllocationLine(item))
        return items
        // return result.data.result
      } else {
        const ids = this.selected.map(({ id }) => id)
        const uniq = [...new Set(ids)]
        const result = await getAllocationLinesByIds({ ids: uniq })
        const items = result.data.result.map(item => new AllocationLine(item))
        return items
        // return result.data.result
      }
    }
  }
}
