import Store from '@/store/computed/storeHelpers'
import { getBillingPeriods } from '@/services/requests/billingPeriods'

export default {
  mounted() {
    // if (this.pagination) this.initPagination()
    this.getBillingPeriods()
  },
  computed: {
    billingPeriods: Store.getSet({
      store: 'billingPeriods',
      prop: 'billingPeriods'
    }),
    allBillingPeriods: Store.getSet({
      store: 'billingPeriods',
      prop: 'allBillingPeriods'
    }),
    openBillingPeriods: Store.getSet({
      store: 'billingPeriods',
      prop: 'openBillingPeriods'
    }),
    loadingBillingPeriods: Store.getSet({
      store: 'billingPeriods',
      prop: 'loadingBillingPeriods'
    })
  },
  methods: {
    async getBillingPeriods() {
      this.loadingBillingPeriods = true
      try {
        const result = await getBillingPeriods()
        this.billingPeriods = result.data.result.rows
        this.allBillingPeriods = this.billingPeriods.map(({ name }) => name)
        this.openBillingPeriods = this.billingPeriods
          .filter(({ isOpen }) => isOpen)
          .map(({ name }) => name)
        this.loadingBillingPeriods = false
      } catch (err) {
        this.loadingBillingPeriods = false
        console.log(err)
        this.snack({ text: err.response.data.message, color: 'red' })
      }
    }
  }
}
