import DocketLine from '@/services/models/docketLine'

export default {
  methods: {
    makePdf(data) {
      const results = []
      for (const line of data) {
        const from = this.globalSettings.footerText.split('| ')
        const to = [
          line.source.name,
          `ABN: ${line.source.project.abn || ''}`,
          line.source.addressStreet1 || '',
          line.source.addressStreet2
            ? line.source.addressStreet2
            : line.source.address3 || '',
          line.source.addressStreet2 ? line.source.address3 || '' : null
        ].filter(item => item != null)

        const info = [
          {
            key: `${this.$store.state.global.pages.docket.titleSingular} #`,
            data: line.id.toString()
          },
          { key: 'Date', data: line._date },
          {
            key: this.$store.state.global.pages.project.titleSingular,
            data: line.source.project.name
          },
          {
            key: this.$store.state.global.pages.source.titleSingular,
            data: line.source.name
          },
          { key: 'Run', data: line.source.runCode }
        ].filter(item => item.data != null)

        const extraInfo = [
          {
            key: this.$store.state.global.pages.contractor.titleSingular,
            data: line.vehicle.contractor.name
          },
          {
            key: this.$store.state.global.pages.driver.titleSingular,
            data: line.driver.name
          },
          {
            key: this.$store.state.global.pages.vehicle.titleSingular,
            data: line.vehicle.name
          },
          {
            key: this.$store.state.global.pages.vehicleType.titleSingular,
            data: line.vehicle.vehicleType.name
          },
          { key: 'Ref #', data: line.externalRef }
        ].filter(item => item.data != null)

        const lines = line.lines.length
          ? line.lines.map(item => {
              item = new DocketLine(item)
              return {
                Description: `${item.destination.name} (${item.itemType.name})`,
                'W/B': item.wbDocketNumber,
                Qty: item._qty,
                Unit: item.sellUnit,
                Price: '$' + item._sellPrice,
                Total: '$' + item._sellTotal
              }
            })
          : []
        const costTotal = line._costTotal
          ? this.addCommas(line._costTotal)
          : '0.00'
        const total = line._sellTotal ? this.addCommas(line._sellTotal) : '0.00'
        const invoiceNotes = line.notes || ''
        const footerText = ['', '', this.globalSettings.footerText]

        const pdfName = `${this.$store.state.global.pages.docket.titleSingular.toLowerCase()}_${
          line.id
        }.pdf`
        const columnStyles = {
          Qty: { halign: 'right' },
          Price: { halign: 'right' },
          Total: { halign: 'right' }
        }

        const columns = lines[0]
          ? Object.keys(lines[0]).map(str => {
              return { title: str, dataKey: str }
            })
          : []

        const makeFoot = () => {
          const totalsOffset = 2
          const footer = []
          const offset = columns.length
            ? new Array(columns.length - totalsOffset).fill('')
            : []
          if (costTotal) footer.push([...offset, 'Cost', '$' + costTotal])
          if (total) footer.push([...offset, 'Total', '$' + total])
          return footer
        }

        const result = {
          pdfName,
          docHeading: this.$store.state.global.pages.docket.titleSingular.toUpperCase(),
          docSubheading: '',
          columns,
          foot: makeFoot(),
          columnStyles,
          pdfData: {
            type: 'docket',
            from,
            to,
            info,
            extraInfo,
            lines,
            total,
            invoiceNotes,
            footerText
          }
        }
        results.push(result)
      }
      return results
    }
  }
}
