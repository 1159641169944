<template>
  <MasterFilePage>
    <template v-slot:control>
      <MasterFileControl
        :masterFile="masterFile"
        :addDialog="addDialog"
        :toggleAdd="toggleAdd"
        :loadingMasterFiles="!loadedDestinationsLite"
        :onSortChange="updateSortBy"
        :onFilterChange="updateFilterBy"
      />
    </template>
    <template v-slot:view>
      <MasterFileView
        :count="Math.ceil(projectCount / pagination.itemsPerPage)"
        :masterFile="masterFile"
        :subjects="sortedProjects"
        :fields="fields"
        :loadingMasterFiles="
          loadingProjects ||
            !loadedDestinationsLite ||
            !loadedContractorsLite ||
            !loadedDriversLite ||
            !loadedVehiclesLite
        "
        :loading="loading"
        :onEdit="editProject"
        :onDelete="deleteProject"
      />
    </template>
    <template v-slot:form>
      <v-dialog
        v-model="addDialog"
        width="700"
        @click:outside="() => (addDialog = false)"
        @keydown.esc="() => (addDialog = false)"
        v-if="
          loadedProjects &&
            loadedDestinationsLite &&
            loadedContractorsLite &&
            loadedDriversLite &&
            loadedVehiclesLite
        "
      >
        <MasterFileForm
          :masterFile="masterFile"
          :fields="fields"
          :dialog="addDialog"
          :close="toggleAdd"
          :onSubmit="createProject"
          :loading="loading"
        />
      </v-dialog>
    </template>
  </MasterFilePage>
</template>

<script>
import {
  addProject,
  updateProject,
  deleteProject
} from '../../../services/requests/projects'
import getProjects from '../../../services/mixins/getMasterFiles/getProjects'
import projectFields from '@/services/mixins/fields/projectFields'
import MasterFileCrudFunctions from '../../../services/mixins/masterFileCrudFunctions'
import Pagination from '../../../services/mixins/getMasterFiles/pagination'

import MasterFilePage from '../../../components/masterFiles/MasterFilePage'
import MasterFileControl from '../../../components/masterFiles/MasterFileControl'
import MasterFileView from '../../../components/masterFiles/MasterFileView'
import MasterFileForm from '../../../components/masterFiles/MasterFileForm'

import { debounce } from 'lodash'
export default {
  mixins: [getProjects, projectFields, MasterFileCrudFunctions, Pagination],
  components: {
    MasterFilePage,
    MasterFileControl,
    MasterFileView,
    MasterFileForm
  },
  data() {
    return {
      masterFile: 'project',
      prop: 'projects',
      addDialog: false,
      sortBy: null,
      filterBy: '',
      loading: false
    }
  },
  watch: {
    filterBy: debounce(function() {
      this.getProjects()
      this.pagination.page = 1
    }, 300)
  },
  computed: {
    sortedProjects() {
      if (this.sortBy) {
        const path = this.sortBy
        return [...this.projects].sort((a, b) => {
          if (this.getObjectPath(path, a) < this.getObjectPath(path, b)) {
            if (path === 'updatedAt') {
              return 1
            }
            return -1
          } else {
            if (path === 'updatedAt') {
              return -1
            }
            return 1
          }
        })
      } else {
        return this.projects
      }
    }
  },
  methods: {
    async createProject(payload) {
      await this.createMasterFile({ payload, action: addProject })
    },
    async editProject(project) {
      const { id, ...payload } = project
      await this.editMasterFile({ id, payload, action: updateProject })
    },
    async deleteProject(project) {
      await this.deleteMasterFile({
        payload: project,
        action: deleteProject
      })
    },
    toggleAdd() {
      this.addDialog = !this.addDialog
    },
    updateSortBy(sortBy) {
      this.sortBy = sortBy
    },
    updateFilterBy(filterBy) {
      this.filterBy = filterBy
    }
  }
}
</script>
