<template>
  <div>
    <v-card flat class="card-outlined">
      <v-card-text>
        <CardHeader
          :title="`${$store.state.global.pages.plan.titleSingular} Line Entry`"
          :icon="$store.state.global.pages.plan.icon"
        />
        <v-form ref="addForm" @submit.prevent="add()">
          <v-row>
            <v-col cols="12" sm="3" v-if="false">
              <v-autocomplete
                label="* Type"
                v-model="type"
                :items="types"
                item-value="id"
                item-text="name"
                :rules="typeRules"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-autocomplete
                :label="`${$store.state.global.pages.driver.titleSingular}`"
                :prepend-icon="$store.state.global.pages.driver.icon"
                v-model="driverId"
                :items="filteredDrivers"
                item-value="id"
                item-text="driverName_contractorName"
                @click:clear="
                  () =>
                    $nextTick(
                      () => (driverId = null),
                      (driverCompliances = null)
                    )
                "
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-autocomplete
                :label="`${$store.state.global.pages.vehicle.titleSingular}`"
                :prepend-icon="$store.state.global.pages.vehicle.icon"
                v-model="vehicleId"
                :items="filteredVehicles"
                item-value="id"
                item-text="vehicleName_contractorName_vehicleType"
                @click:clear="() => $nextTick(clearVehicle)"
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-autocomplete
                :label="
                  `* ${$store.state.global.pages.destination.titleSingular}`
                "
                :prepend-icon="$store.state.global.pages.destination.icon"
                v-model="destinationId"
                :items="filteredDestinations"
                item-value="id"
                item-text="destinationName_number"
                clearable
                :rules="destinationRules"
              ></v-autocomplete>
              <EditDeliveryAddress
                :destinationId="destinationId"
                :destinations="destinations"
                :editAddress="editAddress"
                @toggleAddress="() => (editAddress = !editAddress)"
                @newAddress="newAddress"
              ></EditDeliveryAddress>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-autocomplete
                :label="`* ${$store.state.global.pages.itemType.titleSingular}`"
                :prepend-icon="$store.state.global.pages.itemType.icon"
                v-model="itemTypeId"
                :items="filteredItemTypes"
                item-value="id"
                item-text="name"
                :rules="itemTypeRules"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                label="Qty"
                type="number"
                prepend-icon="mdi-note"
                v-model.number="qty"
                :rules="qtyRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                label="Notes"
                prepend-icon="mdi-note"
                v-model="notes"
                :rules="notesRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-expand-transition>
                <v-row v-show="destinationId && itemTypeId">
                  <v-col class="py-0 d-flex justify-end">
                    <v-btn
                      text
                      color="primary"
                      type="submit"
                      @keydown.enter="add()"
                      class="mb-5"
                    >
                      Add
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expand-transition>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-container fluid class="px-0">
      <v-row>
        <v-expand-transition>
          <v-col cols="12">
            <PlanLineTable
              :headers="lineHeaders"
              :items="lines"
              :onEdit="onEdit"
              :onDelete="onDelete"
              :loading="loading"
              :loadingLines="loadingLines"
              showEdit
              hideDetails
            />
          </v-col>
        </v-expand-transition>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PlanValidation from '@/services/validation/plan'
import EditDeliveryAddress from '../../misc/editDeliveryAddress'
import FilterByContractor from '../../../services/mixins/filterByContractor'
import FilterItemDestination from '../../../services/mixins/filterItemDestination'

import CardHeader from '../../misc/shared/CardHeader'
import PlanLineTable from '@/components/plans/lines/PlanLineTable'
export default {
  mixins: [PlanValidation, FilterItemDestination, FilterByContractor],
  components: {
    CardHeader,
    PlanLineTable,
    EditDeliveryAddress
  },
  props: {
    lines: {
      type: Array,
      required: true
    },
    onAdd: {
      type: Function,
      required: true
    },
    onEdit: {
      type: Function,
      required: true
    },
    onDelete: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    loadingLines: {
      type: Boolean,
      required: false
    },
    isEditPlan: {
      type: Boolean,
      required: false,
      default: false
    },
    lineHeaders: {
      type: Array,
      required: true
    },
    sourceId: {
      type: Number,
      required: false
    },
    sources: {
      type: Array,
      required: true
    },
    vehicles: {
      type: Array,
      required: true
    },
    drivers: {
      type: Array,
      required: true
    },
    destinations: {
      type: Array,
      required: true
    },
    itemTypes: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      type: 'DELIVERY',
      destinationId: null,
      itemTypeId: null,
      vehicleId: null,
      driverId: null,
      notes: null,
      qty: 1,
      types: ['DELIVERY'],
      editAddress: false,
      currentDeliveryAddress: null
    }
  },
  computed: {
    currentDestination() {
      return this.destinations.filter(item => item.id == this.destinationId)[0]
    }
  },
  methods: {
    add() {
      if (!this.$refs.addForm.validate()) {
        this.snackFormError()
        return
      }
      const line = {
        id: this.lines.length + 1,
        type: this.type,
        destinationId: this.destinationId,
        itemTypeId: this.itemTypeId,
        vehicleId: this.vehicleId,
        driverId: this.driverId,
        qty: this.qty,
        notes: this.notes,
        addressStreet1: this.currentDeliveryAddress?.addressStreet1,
        addressStreet2: this.currentDeliveryAddress?.addressStreet2,
        addressCity: this.currentDeliveryAddress?.addressCity,
        addressState: this.currentDeliveryAddress?.addressState
      }
      this.onAdd(line)
      this.clear()
    },
    clear() {
      this.type = 'DELIVERY'
      this.notes = null
      if (this.isEditPlan) {
        this.destinationId = null
        this.itemTypeId = null
      }
    },
    newAddress(address) {
      this.currentDeliveryAddress = address
      this.editAddress = false
    }
  },
  watch: {
    destinationId() {
      if (this.currentDestination) {
        this.currentDeliveryAddress = {
          addressStreet1: this.currentDestination.addressStreet1,
          addressStreet2: this.currentDestination.addressStreet2,
          addressCity: this.currentDestination.addressCity,
          addressState: this.currentDestination.addressState
        }
      }
    }
  }
}
</script>
