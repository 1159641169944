<template>
  <v-card flat class="card-outlined">
    <v-card-text class="pb-0 d-flex justify-space-between align-center">
      <v-row>
        <v-col cols="12" sm="10" class="py-0">
          <MultiActions
            page="rates"
            isLines
            :selectedCount="selectedCount"
            :fieldsToChange="actionFields"
            :exportOptions="exportOptions"
          />
        </v-col>
        <v-col cols="12" sm="2" class="py-0 d-flex justify-end align-center">
          <ColumnSelector
            :defaultHeaders="defaultHeaders"
            :possibleHeaders="possibleHeaders"
            toolTipText="Column selector"
            :onChanged="val => (defaultHeaders = val)"
          />
        </v-col>
        <SelectAlert
          :selected="selected"
          :selectedAll="selectedAll"
          :totalRecordCount="count"
          :selectAll="() => (selectedAll = true)"
          :unselectAll="() => ((selectedAll = false), (selected = []))"
        />
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="rates"
      :loading="loadingRates"
      mobile-breakpoint="0"
      :footer-props="{
        'items-per-page-options': [10, 20, 40, 50],
        showFirstLastPage: true
      }"
      v-model="selected"
      show-select
    >
      <template v-slot:no-data>
        No matching records found
      </template>
      <template v-slot:loading>
        Loading records...
      </template>
      <template v-slot:item="{ item, isSelected }">
        <Row
          :headers="headers"
          :item="item"
          :key="item.id"
          :value="isSelected"
          @input="toggleSelected"
          select
          defaultDialog="detailsDialog"
          showEdit
        >
          <template v-slot:details="{ dialog, close, openEditDialog }">
            <RateDetails
              :dialog="dialog"
              :item="item"
              :close="close"
              :loading="loading"
              :openEditDialog="openEditDialog"
              :rateId="item.id"
            />
          </template>
          <template v-slot:edit="{ dialog, close }">
            <RateEdit
              :dialog="dialog"
              :item="item"
              :loading="loading"
              :action="editRate"
              :close="close"
            />
          </template>
          <template v-slot:delete="{ dialog, close }">
            <DeleteDialog
              :dialog="dialog"
              :page="titleSingular"
              :subject="item"
              :action="deleteRate"
              :close="close"
            />
          </template>
        </Row>
      </template>
      <template
        v-slot:footer[`page-text`]="{ pageStart, pageStop, itemsLength }"
      >
        <div class="text-center mt-1">
          <span v-if="itemsLength">
            {{ `${pageStart}-${pageStop} of ${itemsLength}` }}
          </span>
          <span v-else> - </span>
          <v-progress-linear
            v-if="loadingRates && itemsLength"
            size="15"
            indeterminate
            color="primary"
          ></v-progress-linear>
          <div v-else style="height:5px"></div>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { updateRate, deleteRate } from '../../services/requests/rates'
import Store from '@/store/computed/storeHelpers'
import getRates from '../../services/mixins/getMasterFiles/getRates'
import Headers from './mixins/rateHeaders'
import HeaderFilters from '../../services/mixins/headerFilters'
import MultipleRecords from '../../services/mixins/multipleRecords'
import Rate from '../../services/models/rate'
import RateExport from './mixins/rateExport'

import Row from '../misc/row/Row'
import RateDetails from '@/components/rates/RateDetails'
import RateEdit from '../rates/RateEdit'
import DeleteDialog from '../misc/DeleteDialog'

import ColumnSelector from '../misc/shared/ColumnSelector'
import MultiActions from '../misc/shared/MultiActions'
import SelectAlert from '../misc/shared/SelectAlert'

import { debounce } from 'lodash'
export default {
  mixins: [getRates, Headers, HeaderFilters, MultipleRecords, RateExport],
  components: {
    Row,
    RateEdit,
    DeleteDialog,
    ColumnSelector,
    MultiActions,
    SelectAlert,
    RateDetails
  },
  data() {
    return {
      loading: false,
      actionFields: [
        {
          text: 'Export selected',
          icon: 'mdi-file-export',
          color: 'grey',
          value: { value: null, text: 'Export Selected', label: 'export' }
        },
        {
          text: 'Print selected',
          icon: 'mdi-file-pdf-box',
          color: 'grey',
          value: { value: null, text: 'Print selected', label: 'print' }
        },
        {
          text: 'Delete selected',
          icon: 'mdi-delete',
          color: 'red',
          value: { value: null, text: 'Delete Selected', label: 'delete' },
          action: async () => {
            await this.deleteMultipleRecords({
              ids: this.selected.map(({ id }) => id),
              table: 'rates',
              isLine: true,
              query: this.selectedAll ? this.buildQuery() : null
            })
          }
        }
      ]
    }
  },
  computed: {
    showClosed: Store.getSet({ store: 'global', prop: 'showClosed' }),
    selectedUserId: Store.getSet({ store: 'global', prop: 'selectedUserId' }),
    selectedProjectId: Store.getSet({
      store: 'global',
      prop: 'selectedProjectId'
    }),
    selectedSourceId: Store.getSet({
      store: 'global',
      prop: 'selectedSourceId'
    }),
    selectedDestinationId: Store.getSet({
      store: 'global',
      prop: 'selectedDestinationId'
    }),
    // selectedDeliveryStatus: Store.getSet({
    //   store: 'global',
    //   prop: 'selectedDeliveryStatus'
    // }),
    // selectedContractorId: Store.getSet({
    //   store: 'global',
    //   prop: 'selectedContractorId'
    // }),
    // selectedDriverId: Store.getSet({
    //   store: 'global',
    //   prop: 'selectedDriverId'
    // }),
    // selectedVehicleId: Store.getSet({
    //   store: 'global',
    //   prop: 'selectedVehicleId'
    // }),
    selectedVehicleTypeId: Store.getSet({
      store: 'global',
      prop: 'selectedVehicleTypeId'
    }),
    selectedItemTypeId: Store.getSet({
      store: 'global',
      prop: 'selectedItemTypeId'
    }),
    dateRangeStart: Store.getSet({ store: 'global', prop: 'dateRangeStart' }),
    dateRangeEnd: Store.getSet({ store: 'global', prop: 'dateRangeEnd' }),
    filterCol: Store.getSet({ store: 'rates', prop: 'filterCol' }),
    filterBy: Store.getSet({ store: 'rates', prop: 'filterBy' }),
    selected: Store.getSet({ store: 'rates', prop: 'selectedLines' }),
    selectedAll: Store.getSet({
      store: 'rates',
      prop: 'selectedAllLines'
    }),
    selectedCount() {
      return this.selected.length
    }
  },
  watch: {
    rates() {
      this.selected = []
      this.selectedAll = false
    },
    showClosed() {
      this.getRates()
    },
    selectedUserId() {
      this.getRates()
    },
    selectedProjectId() {
      this.getRates()
    },
    selectedSourceId() {
      this.getRates()
    },
    selectedDestinationId() {
      this.getRates()
    },
    selectedVehicleTypeId() {
      this.getRates()
    },
    selectedItemTypeId() {
      this.getRates()
    },
    // selectedContractorId() {
    //   this.getRates()
    // },
    // selectedDriverId() {
    //   this.getRates()
    // },
    // selectedVehicleId() {
    //   this.getRates()
    // },
    // dateRangeStart() {
    //   this.getRates()
    // },
    // dateRangeEnd() {
    //   this.getRates()
    // },
    filterCol() {
      this.getRates()
    },
    filterBy: debounce(function() {
      this.getRates()
    }, 300)
  },
  methods: {
    toggleSelected(val) {
      if (val.value) {
        this.selected.push(val.object)
      } else {
        var index = this.selected.indexOf(val.object)
        this.selected.splice(index, 1)
      }
    },
    async editRate(
      rate,
      vehicleTypes,
      selectedVehicleTypeIds,
      itemTypes,
      selectedItemTypeIds
    ) {
      try {
        this.loading = true
        const payload = {
          active: rate.active,
          adminFeeRequired: rate.adminFeeRequired,
          altRateTrips: rate.altRateTrips,
          costPrice: rate.costPrice,
          costPriceAlt: rate.costPriceAlt,
          costPriceAltSunday: rate.costPriceAltSunday,
          costPriceNight: rate.costPriceNight,
          costPriceSunday: rate.costPriceSunday,
          costUnit: rate.costUnit,
          costUnitAlt: rate.costUnitAlt,
          costUnitNight: rate.costUnitNight,
          costUnitSunday: rate.costUnitSunday,
          sellPrice: rate.sellPrice,
          exportAmount: rate.exportAmount,
          sellPriceAlt: rate.sellPriceAlt,
          sellPriceAltSunday: rate.sellPriceAltSunday,
          sellPriceNight: rate.sellPriceNight,
          sellPriceSunday: rate.sellPriceSunday,
          sellUnit: rate.sellUnit,
          sellUnitAlt: rate.sellUnitAlt,
          sellUnitNight: rate.sellUnitNight,
          sellUnitSunday: rate.sellUnitSunday,
          destinationId: rate.destinationId,
          itemTypeId: rate.itemTypeId,
          projectId: rate.projectId,
          sourceId: rate.sourceId,
          useTonnage: rate.useTonnage,
          notes: rate.notes,
          vehicleTypes: vehicleTypes.length
            ? JSON.stringify(vehicleTypes)
            : null,
          selectedVehicleTypeIds,
          itemTypes: itemTypes.length ? JSON.stringify(itemTypes) : null,
          selectedItemTypeIds
        }
        const result = await updateRate(rate.id, payload)
        if (result) {
          this.$store.commit('rates/updateArrayStateById', {
            prop: 'rates',
            editedValue: new Rate(result.data.result)
          })
          this.snackUpdated()
        }
        this.loading = false
        return result
      } catch (err) {
        this.loading = false
        console.log(err)
      }

      // return updateRate(rate.id, payload)
      //   .then(result => {
      //     this.$store.commit('rates/updateArrayStateById', {
      //       prop: 'rates',
      //       editedValue: new Rate(result.data.result)
      //     })
      //     this.loading = false
      //     this.snackUpdated()
      //   })
      //   .catch(err => {
      //     this.loading = false
      //     console.log(err)
      //   })
    },
    async deleteRate(rate) {
      try {
        this.loading = true
        const result = await deleteRate(rate.id)
        if (result) {
          this.$store.commit('rates/deleteFromArrayStateByValue', {
            prop: 'rates',
            value: rate
          })
          this.snackDeleted()
        }
        this.loading = false
        return result
      } catch (err) {
        this.loading = false
        console.log(err)
      }

      // return deleteRate(rate.id)
      //   .then(() => {
      //     this.$store.commit('rates/deleteFromArrayStateByValue', {
      //       prop: 'rates',
      //       value: rate
      //     })
      //     this.loading = false
      //     this.snackDeleted()
      //   })
      //   .catch(err => {
      //     this.loading = false
      //     console.log(err)
      //   })
    }
  }
}
</script>
