<template>
  <v-card flat class="card-outlined">
    <v-card-text class="pb-0 d-flex justify-space-between align-center">
      <v-row>
        <v-col cols="12" sm="10" class="py-0">
          <MultiActions
            page="allocations"
            :selectedCount="selectedCount"
            :fieldsToChange="actionFields"
            :exportOptions="exportOptions"
          />
        </v-col>
        <v-col cols="12" sm="2" class="py-0 d-flex justify-end align-center">
          <ColumnSelector
            :defaultHeaders="defaultHeaders"
            :possibleHeaders="possibleHeaders"
            toolTipText="Column selector"
            :onChanged="val => (defaultHeaders = val)"
          />
        </v-col>
        <SelectAlert
          :selected="selected"
          :selectedAll="selectedAll"
          :totalRecordCount="count"
          :selectAll="() => (selectedAll = true)"
          :unselectAll="() => ((selectedAll = false), (selected = []))"
        />
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="allocations"
      :loading="loadingAllocations"
      :options.sync="pagination"
      :server-items-length="count"
      mobile-breakpoint="0"
      :footer-props="{
        'items-per-page-options': [10, 20, 40, 50],
        showFirstLastPage: true
      }"
      v-model="selected"
      show-select
    >
      <template v-slot:no-data>
        No matching records found
      </template>
      <template v-slot:loading>
        Loading records...
      </template>
      <template v-slot:item="{ item, isSelected }">
        <Row
          :headers="headers"
          :item="item"
          :key="item.id"
          showEdit
          :value="isSelected"
          @input="toggleSelected"
          select
          defaultDialog="detailsDialog"
          editDialogFullscreen
          detailsDialogFullscreen
          :createDocketFromAllocation="createDocketFromAllocation"
        >
          <template v-slot:details="{ dialog, close, open, openEditDialog }">
            <AllocationDetails
              v-if="dialog"
              :dialog="dialog"
              :item="item"
              :loading="loading"
              :close="close"
              :open="open"
              :openEditDialog="openEditDialog"
            />
          </template>
          <template v-slot:edit="{ dialog, close }">
            <AllocationEdit
              v-if="dialog"
              :dialog="dialog"
              :item="item"
              :loading="loading"
              :action="editAllocation"
              :close="close"
            />
          </template>
          <template v-slot:delete="{ dialog, close }">
            <DeleteDialog
              :dialog="dialog"
              :page="titleSingular"
              :subject="item"
              :action="deleteAllocation"
              :close="close"
            />
          </template>
          <template v-slot:void="{ dialog, close }">
            <VoidDialog
              :dialog="dialog"
              :page="titleSingular"
              :subject="item"
              :loading="loading"
              :action="makeAllocationVoid"
              :close="close"
            />
          </template>
        </Row>
      </template>
      <template
        v-slot:footer[`page-text`]="{ pageStart, pageStop, itemsLength }"
      >
        <div class="text-center mt-1">
          <span v-if="itemsLength">
            {{ `${pageStart}-${pageStop} of ${itemsLength}` }}
          </span>
          <span v-else> - </span>
          <v-progress-linear
            v-if="loadingAllocations && itemsLength"
            size="15"
            indeterminate
            color="primary"
          ></v-progress-linear>
          <div v-else style="height:5px"></div>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { matchDockets } from '../../services/requests/allocations'
import Store from '@/store/computed/storeHelpers'
import getAllocationsMixin from '../../services/mixins/getTransactionFiles/getAllocations'
import Headers from './mixins/allocationHeaders'
import HeaderFilters from '../../services/mixins/headerFilters'
import MultipleRecords from '../../services/mixins/multipleRecords'
import Common from './mixins/allocationCommon'
import FormatDates from '@/services/mixins/formatDates'
import AllocationExport from './mixins/allocationExport'

import Row from '../misc/row/Row'
import AllocationDetails from './AllocationDetails'
import AllocationEdit from './AllocationEdit'
import DeleteDialog from '../misc/DeleteDialog'
import VoidDialog from './VoidDialog'
import ColumnSelector from '../misc/shared/ColumnSelector'
import MultiActions from '../misc/shared/MultiActions'
import SelectAlert from '../misc/shared/SelectAlert'

import { debounce } from 'lodash'
export default {
  mixins: [
    getAllocationsMixin,
    Headers,
    HeaderFilters,
    MultipleRecords,
    Common,
    FormatDates,
    AllocationExport
  ],
  components: {
    Row,
    AllocationDetails,
    AllocationEdit,
    DeleteDialog,
    VoidDialog,
    ColumnSelector,
    MultiActions,
    SelectAlert
  },
  props: {
    createDocketFromAllocation: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    actionFields() {
      const authLimitedActions = [
        {
          text: `Match / Close ${this.$store.state.global.pages.allocation.title}`,
          icon: 'mdi-file-export',
          color: 'primary',
          value: {
            value: null,
            text: `Match / Close ${this.$store.state.global.pages.allocation.title}`,
            label: 'match'
          },
          action: async () => this.matchDockets(),
          selectedNotRequired: true
        },
        {
          text: 'Delete Selected',
          icon: 'mdi-delete',
          color: 'red',
          value: { value: null, text: 'Delete selected', label: 'delete' },
          action: async () => {
            await this.deleteMultipleRecords({
              ids: this.selected.map(({ id }) => id),
              table: 'allocations',
              query: this.selectedAll ? this.buildQuery() : null
            })
          }
        }
      ]
      let actions = [
        {
          text: 'Export selected',
          icon: 'mdi-file-export',
          color: 'grey',
          value: { value: null, text: 'Export selected', label: 'export' }
        },
        {
          text: 'Print selected',
          icon: 'mdi-file-pdf-box',
          color: 'grey',
          value: { value: null, text: 'Print selected', label: 'print' }
        }
      ]
      if (this.userAuthClass) actions = [...actions, ...authLimitedActions]
      return actions
    },
    showClosed: Store.getSet({ store: 'global', prop: 'showClosed' }),
    showVoid: Store.getSet({ store: 'global', prop: 'showVoid' }),
    selectedUserId: Store.getSet({ store: 'global', prop: 'selectedUserId' }),
    selectedProjectId: Store.getSet({
      store: 'global',
      prop: 'selectedProjectId'
    }),
    selectedSourceId: Store.getSet({
      store: 'global',
      prop: 'selectedSourceId'
    }),
    selectedContractorId: Store.getSet({
      store: 'global',
      prop: 'selectedContractorId'
    }),
    selectedDriverId: Store.getSet({
      store: 'global',
      prop: 'selectedDriverId'
    }),
    selectedVehicleId: Store.getSet({
      store: 'global',
      prop: 'selectedVehicleId'
    }),
    dateRangeStart: Store.getSet({ store: 'global', prop: 'dateRangeStart' }),
    dateRangeEnd: Store.getSet({ store: 'global', prop: 'dateRangeEnd' }),
    filterCol: Store.getSet({ store: 'allocations', prop: 'filterCol' }),
    filterBy: Store.getSet({ store: 'allocations', prop: 'filterBy' }),
    selected: Store.getSet({ store: 'allocations', prop: 'selected' }),
    selectedAll: Store.getSet({ store: 'allocations', prop: 'selectedAll' }),
    selectedCount() {
      return this.selectedAll ? this.count : this.selected.length
    }
  },
  watch: {
    allocations() {
      this.selected = []
      this.selectedAll = false
    },
    showClosed() {
      this.getAllocationsFromPage1()
    },
    showVoid() {
      this.getAllocationsFromPage1()
    },
    selectedUserId() {
      this.getAllocationsFromPage1()
    },
    selectedProjectId() {
      this.getAllocationsFromPage1()
    },
    selectedSourceId() {
      this.getAllocationsFromPage1()
    },
    selectedContractorId() {
      this.getAllocationsFromPage1()
    },
    selectedDriverId() {
      this.getAllocationsFromPage1()
    },
    selectedVehicleId() {
      this.getAllocationsFromPage1()
    },
    dateRangeStart() {
      this.getAllocationsFromPage1()
    },
    dateRangeEnd() {
      this.getAllocationsFromPage1()
    },
    filterCol() {
      this.getAllocationsFromPage1()
    },
    filterBy: debounce(function() {
      this.getAllocationsFromPage1()
    }, 300)
  },
  methods: {
    async getAllocationsFromPage1() {
      this.pagination.page = 1
      await this.getAllocations()
    },
    async matchDockets() {
      try {
        this.loading = true
        const result = await matchDockets()
        this.loading = false
        this.snack({ text: result.data.message, color: 'green' })
      } catch (err) {
        this.loading = false
        console.log(err)
      }
    }
  }
}
</script>
