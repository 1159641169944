var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panels',{attrs:{"focusable":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[(_vm.filteredSettings.otherSettings && _vm.globalSettings.footerText)?[(
        _vm.$store.state.user.userAuthClass[((_vm.$route.name) + "Read")] &&
          _vm.isAdministrator
      )?_c('PanelWrapper',{attrs:{"title":"Global Settings","icon":"mdi-earth","active":_vm.panel == 0}},[_c('GlobalPanel',{attrs:{"footerText":_vm.globalSettings.footerText,"otherSettings":_vm.filteredSettings.otherSettings}})],1):_vm._e(),_c('PanelWrapper',{attrs:{"title":"User Settings","icon":"mdi-account","active":_vm.panel == 1}},[_c('UserPanel')],1),(
        _vm.$store.state.user.userAuthClass[((_vm.$route.name) + "Read")] &&
          _vm.isAdministrator
      )?_c('PanelWrapper',{attrs:{"title":_vm.$store.state.global.pages.billingPeriod.title,"icon":_vm.$store.state.global.pages.billingPeriod.icon,"active":_vm.panel == 2}},[_c('BillingPeriodPanel')],1):_vm._e(),(_vm.$store.state.user.userAuthClass.policyRead)?_c('PanelWrapper',{attrs:{"title":_vm.$store.state.global.pages.policy.title,"icon":_vm.$store.state.global.pages.policy.icon,"active":_vm.panel == 3}},[_c('PolicyPanel')],1):_vm._e(),(
        _vm.$store.state.user.userAuthClass[((_vm.$route.name) + "Read")] &&
          _vm.isAdministrator
      )?_c('PanelWrapper',{attrs:{"title":"Accounting Settings","icon":_vm.$store.state.global.pages.policy.icon,"active":_vm.panel == 4}},[_c('AccountingPanel',{attrs:{"accountingSettings":_vm.filteredSettings.accountingSettings}})],1):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }