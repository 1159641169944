import Store from '@/store/computed/storeHelpers'
import { getVehiclesLite } from '../../../requests/vehicles'
export default {
  mounted() {
    this.getVehiclesLite()
  },
  data() {
    return {
      loadedVehiclesLite: false
    }
  },
  computed: {
    vehiclesLite: Store.getSet({ store: 'vehicles', prop: 'vehiclesLite' }),
    loadingVehiclesLite: Store.getSet({
      store: 'vehicles',
      prop: 'loadingVehiclesLite'
    })
  },
  methods: {
    async getVehiclesLite() {
      try {
        this.loadingVehiclesLite = true
        const result = await getVehiclesLite()
        if (result) {
          this.vehiclesLite = result.data.result
        }
        this.loadedVehiclesLite = true
        this.loadingVehiclesLite = false
      } catch (err) {
        console.log(err)
        this.loadingVehiclesLite = false
      }
    }
  }
}
