<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span>
          View {{ $store.state.global.pages.docket.titleSingular }} -
          <span class="pl-1 subtitle-1">
            ID:
            <span class="font-weight-bold">{{
              docket ? docket.id : null
            }}</span>
          </span>
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip
        bottom
        v-if="
          userAuthClass &&
            docket &&
            !docket.closed &&
            !docket.isWithDriver &&
            $route.name == 'docket'
        "
      >
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="showEditDialog">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span
          >Edit this
          {{
            $store.state.global.pages.docket.titleSingular.toLowerCase()
          }}</span
        >
      </v-tooltip>
    </v-toolbar>

    <v-container fluid>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-card flat class="card-outlined" v-if="!docket || !docketLines">
            <v-sheet class="pa-3">
              <v-skeleton-loader
                class="mx-auto"
                type="avatar, list-item-three-line, divider, actions"
              ></v-skeleton-loader>
            </v-sheet>
          </v-card>
          <v-sheet
            outlined
            :color="docket.type == 'CREDIT' ? 'red' : ''"
            rounded
            v-else
          >
            <v-card flat class="card-outlined">
              <v-card-text class="pb-0">
                <CardHeader
                  :title="docket.source.project.name"
                  :subtitle="docket.type == 'CREDIT' ? docket.type : ''"
                  :icon="$store.state.global.pages.docket.icon"
                />
                <v-container fluid class="px-0">
                  <v-row>
                    <Detail
                      icon="mdi-calendar"
                      :text="docket.billingPeriod"
                      cols="6"
                      lg="4"
                    />
                    <Detail
                      icon="mdi-calendar"
                      :text="docket._date"
                      cols="6"
                      lg="4"
                    />
                    <Detail
                      :icon="$store.state.global.pages.source.icon"
                      :text="docket.source.name"
                      cols="6"
                      lg="4"
                    />
                    <Detail
                      :icon="$store.state.global.pages.contractor.icon"
                      :text="docket.vehicle.contractor.name"
                      cols="6"
                      lg="4"
                    />
                    <Detail
                      :icon="$store.state.global.pages.vehicle.icon"
                      :text="docket.vehicle.name"
                      cols="6"
                      lg="4"
                    />
                    <Detail
                      :icon="$store.state.global.pages.vehicle.icon"
                      :text="
                        `${docket.vehicle.vehicleType.name} (${docket.vehicle.vehicleType.tonnage}t)`
                      "
                      :tooltip="docket.vehicle.vehicleType.name"
                      cols="6"
                      lg="4"
                    />
                    <Detail
                      :icon="$store.state.global.pages.driver.icon"
                      :text="
                        `${docket.driver.name} ${
                          docket.isWithDriver ? '(open)' : ''
                        }`
                      "
                      cols="6"
                      lg="4"
                    />
                    <Detail
                      v-if="docket.totalDistance"
                      icon="mdi-map-marker-distance"
                      :text="`${docket._totalDistance} km traveled`"
                      cols="6"
                      lg="4"
                    />
                    <Detail
                      v-if="docket.externalRef"
                      icon="mdi-note"
                      :text="docket.externalRef"
                      cols="6"
                      lg="4"
                    />
                    <Detail
                      :icon="
                        docket.closed ? 'mdi-door-closed' : 'mdi-door-open'
                      "
                      :text="docket.closed ? 'Closed' : 'Open'"
                      cols="6"
                      lg="4"
                    />
                    <Detail
                      icon="mdi-clock"
                      :text="'Active for ' + docket._hours + ' hours'"
                      v-if="docket.hours"
                      lg="4"
                    />
                  </v-row>
                </v-container>
              </v-card-text>
              <v-col class="pb-0">
                <v-divider></v-divider>
              </v-col>
              <v-card-actions v-if="docket">
                <v-row class="py-4" v-if="$vuetify.breakpoint.smAndUp">
                  <v-col
                    cols="12"
                    class="py-0"
                    v-if="docket.updatedAt !== docket.createdAt"
                  >
                    <TimeAgo
                      class="grey--text"
                      :subject="docket"
                      :time="docket.updatedAt"
                      needsParse
                      updated
                    >
                      <v-icon small color="grey" slot="icon" class="mr-2">
                        mdi-clock-outline
                      </v-icon>
                    </TimeAgo>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <TimeAgo
                      class="grey--text"
                      :subject="docket"
                      :time="docket.createdAt"
                      needsParse
                      created
                    >
                      <v-icon small color="grey" slot="icon" class="mr-2">
                        mdi-clock-outline
                      </v-icon>
                    </TimeAgo>
                  </v-col>
                </v-row>
                <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
                <span
                  class="show-route"
                  v-if="docket.allocation && $route.name != 'allocation'"
                >
                  <v-btn
                    color="primary"
                    text
                    @click="allocationDetailsDialog = true"
                  >
                    <v-icon left>
                      {{ $store.state.global.pages.allocation.icon }}
                    </v-icon>
                    <span>
                      {{
                        $store.state.global.pages.allocation.titleSingular
                      }}</span
                    >
                  </v-btn>
                </span>
                <MakeInvoicePdf v-if="pdfData" :pdfData="pdfData" />
                <ShowAudits :auditData="auditData" />
                <v-menu top left v-if="docket.type == 'DOCKET'">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon text color="grey">
                      <v-icon>mdi-plus-minus-variant</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <!-- <v-list-item @click="() => {}">
                      <v-list-item-icon>
                        <v-icon color="primary">mdi-plus-circle</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{
                          this.$store.state.global.pages.docket.titleSingular
                        }}
                        Addition
                      </v-list-item-title>
                    </v-list-item> -->
                    <v-list-item @click="() => openCredit(docket)">
                      <v-list-item-icon>
                        <v-icon color="primary">mdi-minus-circle</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        {{
                          this.$store.state.global.pages.docket.titleSingular
                        }}
                        Credit
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn color="primary" icon text @click="getLines()"
                  ><v-icon>mdi-refresh</v-icon></v-btn
                >
              </v-card-actions>
            </v-card>
          </v-sheet>
        </v-col>

        <v-col cols="12">
          <DocketLineTable
            :headers="lineHeaders"
            :items="docketLines || []"
            :loading="loading"
            :loadingLines="loadingLines"
            :selectedId="selectedId"
            :showBilling="showBilling"
          />
          <div
            v-if="sellTotalForLines && showSell"
            class=" mx-3 mt-3 text-right title"
          >
            <span v-if="pendingTotalForLines" class="grey--text">
              Ended: ${{
                addCommas((sellTotalForLines - pendingTotalForLines).toFixed(2))
              }}
              <br />
              Pending: ${{ addCommas(pendingTotalForLines.toFixed(2)) }}
              <br />
            </span>
            Total: ${{ addCommas(sellTotalForLines.toFixed(2)) }}
            <br />
            <span
              class="grey--text"
              v-if="!docket.billLineCount && showBilling"
            >
              Outstanding: ${{ addCommas(Number(outstandingTotal).toFixed(2)) }}
            </span>
          </div>
        </v-col>
        <v-col>
          <v-dialog
            v-model="allocationDetailsDialog"
            @click:outside="
              () => {
                allocationDetailsDialog = false
              }
            "
            @keydown.esc="
              () => {
                allocationDetailsDialog = false
              }
            "
            fullscreen
          >
            <AllocationDetails
              v-if="docket && allocation && $route.name != 'allocation'"
              :dialog="allocationDetailsDialog"
              :item="allocation"
              :loading="loading"
              :close="
                () => {
                  allocationDetailsDialog = false
                }
              "
              :openEditDialog="openEditDialog"
            />
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import getWindowSize from '../../services/mixins/getWindowSize'
import { getLinesByDocketId } from '../../services/requests/dockets'
import FormatDates from '@/services/mixins/formatDates'
import LineHeaders from './lines/mixins/lineHeaders'
import Docket from '@/services/models/docket'
import DocketLine from '@/services/models/docketLine'
import Allocation from '@/services/models/allocation'

import DocketLineTable from './lines/DocketLineTable'
import Detail from '../misc/Detail'
import TimeAgo from '../misc/TimeAgo'
import CardHeader from '../misc/shared/CardHeader'
import { sumProp } from '../../utils/Utils'
import MakeInvoicePdf from '../misc/shared/MakeInvoicePdf'
import ShowAudits from '../misc/shared/ShowAudits'
import makePdf from './mixins/makeDocketPdf'

export default {
  mixins: [FormatDates, LineHeaders, getWindowSize, makePdf],
  components: {
    DocketLineTable,
    Detail,
    AllocationDetails: () => import('../allocations/AllocationDetails'),
    TimeAgo,
    CardHeader,
    MakeInvoicePdf,
    ShowAudits
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    close: {
      type: Function,
      required: true
    },
    openEditDialog: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    selectedId: {
      type: Number,
      required: false
    },
    openCredit: {
      type: Function,
      required: false
    }
  },
  data() {
    return {
      docketLines: null,
      allocation: null,
      docket: null,
      allocationDetailsDialog: false,
      loadingLines: false
    }
  },
  mounted() {
    this.getLines()
  },
  computed: {
    showBilling() {
      return this.docket?.source.project.billType === 'APPROVED'
    },
    showSell() {
      return this.$store.state.user.userAuthClass.showSell
    },
    lineHeaders() {
      return Number(this.window?.width) >= 600
        ? this.headers
        : this.mobileHeaders
    },
    sellTotalForLines() {
      return this.docketLines?.length
        ? sumProp(this.docketLines, 'sellTotal')
        : 0
    },
    outstandingTotal() {
      return this.docketLines?.length
        ? sumProp(this.docketLines, 'outstandingBillingAmount')
        : 0
    },
    pendingTotalForLines() {
      const pendingLines = this.docketLines?.filter(
        ({ start, end }) => !!start && !end
      )
      return pendingLines?.length ? sumProp(pendingLines, 'sellTotal') : 0
    },
    auditData() {
      return { id: this.docket.id, model: 'docket' }
    },
    pdfData() {
      if (!this.docket || !this.docketLines?.length) return null
      const data = this.docket
      data.lines = this.docketLines
      return this.makePdf([data])[0]
    }
  },
  methods: {
    async getLines() {
      try {
        this.loadingLines = true
        const result = await getLinesByDocketId(this.item.id)
        const items = result.data.result.lines
        const docket = result.data.result.docket
        this.docket = new Docket(docket)
        this.allocation = this.docket.allocation
          ? new Allocation(this.docket.allocation)
          : null

        this.docketLines = items.map(
          item => new DocketLine({ ...item, docket })
        )
        this.loadingLines = false
      } catch (err) {
        this.loadingLines = false
        console.log(err)
      }
    },
    showEditDialog() {
      this.openEditDialog()
      this.close()
    }
  }
}
</script>
