<template>
  <v-expansion-panels v-model="field.panel" flat class="card-outlined">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div class="d-flex align-center">
          <v-icon color="primary">{{ field.icon }}</v-icon>
          <span class="ml-2">
            {{ field.label }}
          </span>
        </div>
        <template v-slot:actions>
          <v-chip color="primary" class="mr-2" label
            >{{ values.length }} / {{ field.items.length }}</v-chip
          >
          <v-icon>
            mdi-chevron-down
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-text-field v-model="search" placeholder="Search"></v-text-field>

        <v-list dense>
          <v-list-item>
            <v-row class="mb-1">
              <v-col class="subtitle-2 mx-3 px-5">
                {{ expansionTitle }}
              </v-col>
              <v-col class="subtitle-2 text-center">
                Details
              </v-col>
            </v-row>
          </v-list-item>
          <v-lazy v-for="(item, i) in items" :key="i">
            <v-list-item :value="item.id" v-show="!item.disabled">
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox
                    v-model="values"
                    class="mt-0"
                    hide-details
                    :value="item.id"
                    :input-value="active"
                    color="primary"
                  ></v-checkbox>
                </v-list-item-action>
                <v-list-item-content class="py-0">
                  <v-list-item-title>
                    <v-row>
                      <v-col class="py-0 d-flex align-center">
                        {{ getName(item) }}
                      </v-col>
                      <v-col class="py-0 d-flex align-center">
                        <v-text-field
                          v-if="refs"
                          v-model="refs[item.id]"
                        ></v-text-field>
                        <v-text-field v-else disabled></v-text-field>
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-lazy>
          <v-divider></v-divider>

          <v-list-item
            v-if="activeItems.length != items.length"
            @click="() => (selectDisplayedValues = !selectDisplayedValues)"
          >
            <v-list-item-action>
              <v-checkbox
                :value="selectDisplayedValues"
                color="primary"
              ></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{
                selectDisplayedValues ? 'Unselect' : 'Select'
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="activeItems.length == items.length"
            @click="() => (selectAllValues = !selectAllValues)"
          >
            <v-list-item-action>
              <v-checkbox :value="selectAllValues" color="primary"></v-checkbox>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>Select All</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  mounted() {
    this.init()
  },
  props: {
    field: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      items: [...this.field.items],
      values: [],
      refs: null,
      activeItems: [...this.field.items].filter(({ disabled }) => !disabled),
      selectAllValues: false,
      selectDisplayedValues: false,
      search: null
    }
  },
  watch: {
    selectAllValues(val) {
      if (val) {
        this.values = this.items.map(({ id }) => id)
      } else {
        this.values = []
      }
    },
    selectDisplayedValues(val) {
      if (val) {
        for (const item of this.items) {
          if (!item.disabled) this.values.push(item.id)
        }
        this.values = [...new Set(this.values)]
        this.selectDisplayedValues = null
      }
    },
    search(val) {
      for (const item of this.items) {
        if (
          this.getName(item)
            .toLowerCase()
            .search(val.toLowerCase()) > -1
        )
          item.disabled = false
        else item.disabled = true
      }
      this.activeItems = this.items.filter(({ disabled }) => !disabled)
    },
    values(val) {
      this.field.newValue = val
      this.field.refs = this.refs
    },
    field() {
      this.init()
    }
  },
  computed: {
    useNewInduction() {
      return (
        typeof this.field?.value[0] == 'object' ||
        this.$route.name == 'driver' ||
        (this.$route.name == 'project' && this.propName == 'driverId')
      )
    },
    propName() {
      return (
        this.field.prop.slice(7, this.field.prop.length - 1).toLowerCase() +
        'Id'
      )
    },
    expansionTitle() {
      const page = this.propName.slice(0, this.propName.length - 2)
      return this.$store.state.global.pages[page]
        ? this.$store.state.global.pages[page].titleSingular
        : this.$store.state.global.pages.itemType.titleSingular
    }
  },
  methods: {
    getName(item) {
      return this.field.nameValue ? item[this.field.nameValue] : item.name
    },
    init() {
      if (this.useNewInduction) {
        this.refs = {}
        this.values = [...this.field.value]
          .filter(val =>
            this.field.items.map(item => item.id).includes(val[this.propName])
          )
          .map(fieldValue => fieldValue[this.propName])
        for (const item of this.field.items) {
          const obj = this.field.value.find(
            val => val[this.propName] == item.id
          )
          this.refs[item.id] = obj?.reference
        }
      } else {
        this.values = this.field.value.filter(val =>
          this.field.items.map(item => item.id).includes(val)
        )
      }
    }
  }
}
</script>
