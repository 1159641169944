<template>
  <MasterFilePage>
    <template v-slot:control>
      <MasterFileControl
        :masterFile="masterFile"
        :addDialog="addDialog"
        :toggleAdd="toggleAdd"
        :parents="contractorsLite"
        :parent="parent"
        :parentId="parentId"
        :onSortChange="updateSortBy"
        :onFilterChange="updateFilterBy"
        :onParentChange="updateParent"
      />
    </template>
    <template v-slot:details>
      <MasterFileDetails
        :item="parent"
        v-if="parent"
        @close="parentId = null"
      />
    </template>
    <template v-slot:view>
      <MasterFileView
        :count="Math.ceil(driverCount / pagination.itemsPerPage)"
        :masterFile="masterFile"
        :subjects="sortedDrivers"
        :fields="fields"
        :loadingMasterFiles="
          loadingDrivers ||
            !loadedContractorsLite ||
            !loadedSourcesLite ||
            !loadedVehiclesLite ||
            !loadedUsers ||
            !loadedProjectsLite
        "
        :loading="loading"
        :onEdit="editDriver"
        :onUpdatePassword="updatePassword"
        :onValidateLogin="validateLogin"
        :onDelete="deleteDriver"
      />
    </template>
    <template v-slot:form>
      <v-dialog
        v-model="addDialog"
        width="700"
        @click:outside="() => (addDialog = false)"
        @keydown.esc="() => (addDialog = false)"
        v-if="
          loadedContractorsLite &&
            loadedSourcesLite &&
            loadedVehiclesLite &&
            loadedUsers &&
            loadedProjectsLite
        "
      >
        <MasterFileForm
          :masterFile="masterFile"
          :fields="fields"
          :dialog="addDialog"
          :close="toggleAdd"
          :onSubmit="createDriver"
          :onValidateLogin="validateLogin"
          :loading="loading"
        />
      </v-dialog>
    </template>
  </MasterFilePage>
</template>

<script>
import {
  addDriver,
  updateDriver,
  updateDriverPassword,
  checkLoginExists,
  deleteDriver
} from '../../../services/requests/drivers'
import getDrivers from '../../../services/mixins/getMasterFiles/getDrivers'
import getContractors from '../../../services/mixins/getMasterFiles/lite/getContractorsLite'
import driverFields from '@/services/mixins/fields/driverFields'
import MasterFileCrudFunctions from '../../../services/mixins/masterFileCrudFunctions'
import Pagination from '../../../services/mixins/getMasterFiles/pagination'

import MasterFilePage from '../../../components/masterFiles/MasterFilePage'
import MasterFileControl from '../../../components/masterFiles/MasterFileControl'
import MasterFileDetails from '../../../components/masterFiles/MasterFileDetails'
import MasterFileView from '../../../components/masterFiles/MasterFileView'
import MasterFileForm from '../../../components/masterFiles/MasterFileForm'

import { debounce } from 'lodash'
export default {
  mixins: [
    getDrivers,
    getContractors,
    driverFields,
    MasterFileCrudFunctions,
    Pagination
  ],
  components: {
    MasterFilePage,
    MasterFileControl,
    MasterFileDetails,
    MasterFileView,
    MasterFileForm
  },
  data() {
    return {
      masterFile: 'driver',
      prop: 'drivers',
      addDialog: false,
      sortBy: null,
      filterBy: '',
      loading: false
    }
  },
  mounted() {
    this.setFid()
  },
  watch: {
    $route() {
      this.setFid()
    },
    filterBy: debounce(function() {
      this.getDrivers()
      this.pagination.page = 1
    }, 300),
    parentId: debounce(function() {
      this.getDrivers()
      this.pagination.page = 1
    }, 300)
  },
  computed: {
    parentFilteredDrivers() {
      if (this.parentId) {
        return this.drivers.filter(driver => {
          return driver.contractorId == this.parentId
        })
      } else {
        return this.drivers
      }
    },
    sortedDrivers() {
      if (this.sortBy) {
        const path = this.sortBy
        return [...this.parentFilteredDrivers].sort((a, b) => {
          if (this.getObjectPath(path, a) < this.getObjectPath(path, b)) {
            if (path === 'updatedAt') {
              return 1
            }
            return -1
          } else {
            if (path === 'updatedAt') {
              return -1
            }
            return 1
          }
        })
      } else {
        return this.parentFilteredDrivers
      }
    },
    parent() {
      return this.contractorsLite.find(({ id }) => this.parentId === id)
    }
  },
  methods: {
    async createDriver(payload) {
      const { confirmedPassword, ...data } = payload
      console.log(confirmedPassword.charAt(0))
      await this.createMasterFile({ payload: data, action: addDriver })
    },
    async editDriver(driver) {
      const { id, ...payload } = driver
      await this.editMasterFile({ id, payload, action: updateDriver })
    },
    async updatePassword(id, payload) {
      await this.updateMasterFilePassword({
        id: id,
        payload: payload,
        action: updateDriverPassword
      })
    },
    async deleteDriver(driver) {
      await this.deleteMasterFile({
        payload: driver,
        action: deleteDriver
      })
    },
    toggleAdd() {
      this.addDialog = !this.addDialog
    },
    updateSortBy(sortBy) {
      this.sortBy = sortBy
    },
    updateFilterBy(filterBy) {
      this.filterBy = filterBy
    },
    updateParent(parentId) {
      this.parentId = parentId
    },
    validateLogin(login) {
      return checkLoginExists(login)
    },
    setFid() {
      const fid = Number(this.$route.query.fid)
      fid && (this.parentId = fid)
    }
  }
}
</script>
