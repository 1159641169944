<template>
  <v-card flat class="card-outlined">
    <v-card-text class="pb-0 d-flex justify-space-between align-center">
      <v-row>
        <v-col cols="12" sm="10" class="py-0">
          <MultiActions
            page="plans"
            :selectedCount="selectedCount"
            :fieldsToChange="actionFields"
          />
        </v-col>
        <v-col cols="12" sm="2" class="py-0 d-flex justify-end align-center">
          <ColumnSelector
            :defaultHeaders="defaultHeaders"
            :possibleHeaders="possibleHeaders"
            toolTipText="Column selector"
            :onChanged="val => (defaultHeaders = val)"
          />
        </v-col>
        <SelectAlert
          :selected="selected"
          :selectedAll="selectedAll"
          :totalRecordCount="count"
          :selectAll="() => (selectedAll = true)"
          :unselectAll="() => ((selectedAll = false), (selected = []))"
        />
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="plans"
      :loading="loadingPlans"
      :options.sync="pagination"
      :server-items-length="count"
      mobile-breakpoint="0"
      :footer-props="{
        'items-per-page-options': [10, 20, 40, 50],
        showFirstLastPage: true
      }"
      v-model="selected"
      show-select
    >
      <template v-slot:no-data>
        No matching records found
      </template>
      <template v-slot:loading>
        Loading records...
      </template>
      <template v-slot:item="{ item, isSelected }">
        <Row
          :headers="headers"
          :item="item"
          :key="item.id"
          showEdit
          :value="isSelected"
          @input="toggleSelected"
          select
          defaultDialog="detailsDialog"
          editDialogFullscreen
          detailsDialogFullscreen
        >
          <template v-slot:details="{ dialog, close, open, openEditDialog }">
            <PlanDetails
              v-if="dialog"
              :dialog="dialog"
              :item="item"
              :loading="loading"
              :close="close"
              :open="open"
              :vehicles="vehicles"
              :drivers="drivers"
              :openEditDialog="openEditDialog"
            />
          </template>
          <template v-slot:edit="{ dialog, close }">
            <PlanEdit
              v-if="dialog"
              :dialog="dialog"
              :item="item"
              :loading="loading"
              :action="editPlan"
              :close="close"
            />
          </template>
          <template v-slot:delete="{ dialog, close }">
            <DeleteDialog
              :dialog="dialog"
              :page="titleSingular"
              :subject="item"
              :action="deletePlan"
              :close="close"
            />
          </template>
        </Row>
      </template>
      <template
        v-slot:footer[`page-text`]="{ pageStart, pageStop, itemsLength }"
      >
        <div class="text-center mt-1">
          <span v-if="itemsLength">
            {{ `${pageStart}-${pageStop} of ${itemsLength}` }}
          </span>
          <span v-else> - </span>
          <v-progress-linear
            v-if="loadingPlans && itemsLength"
            size="15"
            indeterminate
            color="primary"
          ></v-progress-linear>
          <div v-else style="height:5px"></div>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import Store from '@/store/computed/storeHelpers'
import getPlansMixin from '../../services/mixins/getTransactionFiles/getPlans'
import Headers from './mixins/planHeaders'
import HeaderFilters from '../../services/mixins/headerFilters'
import MultipleRecords from '../../services/mixins/multipleRecords'
import Common from './mixins/planCommon'
import FormatDates from '@/services/mixins/formatDates'

import Row from '../misc/row/Row'
import PlanDetails from './PlanDetails'
import PlanEdit from './PlanEdit'
import DeleteDialog from '../misc/DeleteDialog'
// import VoidDialog from './VoidDialog'
import ColumnSelector from '../misc/shared/ColumnSelector'
import MultiActions from '../misc/shared/MultiActions'
import SelectAlert from '../misc/shared/SelectAlert'

import { debounce } from 'lodash'
export default {
  mixins: [
    getPlansMixin,
    Headers,
    HeaderFilters,
    MultipleRecords,
    Common,
    FormatDates
  ],
  components: {
    Row,
    PlanDetails,
    PlanEdit,
    DeleteDialog,
    ColumnSelector,
    MultiActions,
    SelectAlert
  },
  props: {
    vehicles: {
      type: Array,
      required: true
    },
    drivers: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    actionFields() {
      const authLimitedActions = [
        {
          text: 'Delete Selected',
          icon: 'mdi-delete',
          color: 'red',
          value: { value: null, text: 'Delete selected', label: 'delete' },
          action: async () => {
            await this.deleteMultipleRecords({
              ids: this.selected.map(({ id }) => id),
              table: 'plans',
              query: this.selectedAll ? this.buildQuery() : null
            })
          }
        }
      ]
      let actions = [
        // {
        //   text: 'Export selected',
        //   icon: 'mdi-file-export',
        //   color: 'grey',
        //   value: { value: null, text: 'Export selected', label: 'export' }
        // },
        // {
        //   text: 'Print selected',
        //   icon: 'mdi-file-pdf-box',
        //   color: 'grey',
        //   value: { value: null, text: 'Print selected', label: 'print' }
        // }
      ]
      if (this.userAuthClass) actions = [...actions, ...authLimitedActions]
      return actions
    },
    showClosed: Store.getSet({ store: 'global', prop: 'showClosed' }),
    showVoid: Store.getSet({ store: 'global', prop: 'showVoid' }),
    selectedUserId: Store.getSet({ store: 'global', prop: 'selectedUserId' }),
    selectedProjectId: Store.getSet({
      store: 'global',
      prop: 'selectedProjectId'
    }),
    selectedSourceId: Store.getSet({
      store: 'global',
      prop: 'selectedSourceId'
    }),
    selectedContractorId: Store.getSet({
      store: 'global',
      prop: 'selectedContractorId'
    }),
    selectedDriverId: Store.getSet({
      store: 'global',
      prop: 'selectedDriverId'
    }),
    selectedVehicleId: Store.getSet({
      store: 'global',
      prop: 'selectedVehicleId'
    }),
    dateRangeStart: Store.getSet({ store: 'global', prop: 'dateRangeStart' }),
    dateRangeEnd: Store.getSet({ store: 'global', prop: 'dateRangeEnd' }),
    filterCol: Store.getSet({ store: 'plans', prop: 'filterCol' }),
    filterBy: Store.getSet({ store: 'plans', prop: 'filterBy' }),
    selected: Store.getSet({ store: 'plans', prop: 'selected' }),
    selectedAll: Store.getSet({ store: 'plans', prop: 'selectedAll' }),
    selectedCount() {
      return this.selectedAll ? this.count : this.selected.length
    }
  },
  watch: {
    plans() {
      this.selected = []
      this.selectedAll = false
    },
    showClosed() {
      this.getPlansFromPage1()
    },
    showVoid() {
      this.getPlansFromPage1()
    },
    selectedUserId() {
      this.getPlansFromPage1()
    },
    selectedProjectId() {
      this.getPlansFromPage1()
    },
    selectedSourceId() {
      this.getPlansFromPage1()
    },
    selectedContractorId() {
      this.getPlansFromPage1()
    },
    selectedDriverId() {
      this.getPlansFromPage1()
    },
    selectedVehicleId() {
      this.getPlansFromPage1()
    },
    dateRangeStart() {
      this.getPlansFromPage1()
    },
    dateRangeEnd() {
      this.getPlansFromPage1()
    },
    filterCol() {
      this.getPlansFromPage1()
    },
    filterBy: debounce(function() {
      this.getPlansFromPage1()
    }, 300)
  },
  methods: {
    async getPlansFromPage1() {
      this.pagination.page = 1
      await this.getPlans()
    }
  }
}
</script>
