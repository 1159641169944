export default {
  // data() {
  //   return {
  //     created: false,
  //     updated: false
  //   }
  // },
  computed: {
    updated() {
      const time = new Date(Date.parse(this.item.updatedAt))
      const diffInSeconds = this.getTimeDiff(time)
      return diffInSeconds <= 180 && diffInSeconds >= 0
    },
    created() {
      const time = new Date(Date.parse(this.item.createdAt))
      const diffInSeconds = this.getTimeDiff(time)
      return diffInSeconds <= 180 && diffInSeconds >= 0
    }
  },
  methods: {
    getTimeDiff(time) {
      var now = new Date()
      var diffInSeconds = (now.getTime() - time.getTime()) / 1000
      return diffInSeconds
    }
  }
  // mounted() {
  //   var diffCreated = this.getTimeDiff('createdAt')
  //   if (diffCreated <= 180 && diffCreated >= 0) this.created = true
  //   var diffUpdated = this.getTimeDiff('updatedAt')
  //   if (diffUpdated <= 180 && diffUpdated >= 0) this.updated = true
  //   // if (this.$route.name == 'docket') {
  //   //   var diffDocketUpdated = this.getTimeDiff('docket.updatedAt')
  //   //   if (diffDocketUpdated <= 180 && diffDocketUpdated >= 0)
  //   //     this.updated = true
  //   // } else {
  //   //   var diffUpdated = this.getTimeDiff('updatedAt')
  //   //   if (diffUpdated <= 180 && diffUpdated >= 0) this.updated = true
  //   // }

  //   this.$watch(`${this.prefix}.updated`, function(val) {
  //     if (val) {
  //       this.updated = true
  //       setTimeout(() => {
  //         this.updated = false
  //       }, 20000)
  //     }
  //   })
  // }
}
