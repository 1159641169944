export default {
  data() {
    return {
      allPodHeaders: [
        {
          text: 'Type',
          value: 'type',
          type: 'podType',
          sortable: false
        },
        { text: 'Name', value: 'report.name' },
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: `${this.$store.state.global.pages.allocation.title.slice(
            0,
            -1
          )} ID`,
          value: 'allocationLine.allocation.id'
        },
        {
          text: 'Date',
          value: 'allocationLine.allocation.date',
          type: 'date'
        },
        { text: 'Delivered At', value: 'deliveredAt', sortable: false },
        { text: 'Line ID', value: 'allocationLineId' },
        {
          text: this.$store.state.global.pages.source.titleSingular,
          value: 'allocationLine.allocation.source.name'
        },
        {
          text: this.$store.state.global.pages.destination.titleSingular,
          value: 'allocationLine.destination.name'
        },
        {
          text: `${this.$store.state.global.pages.destination.titleSingular} phone`,
          value: 'allocationLine.destination.contactPhone'
        },
        {
          text: this.$store.state.global.pages.vehicle.titleSingular,
          value: 'allocationLine.allocation.vehicle.name'
        },
        {
          text: this.$store.state.global.pages.vehicleType.titleSingular,
          value: 'allocationLine.allocation.vehicle.vehicleType.name'
        },
        {
          text: this.$store.state.global.pages.contractor.titleSingular,
          value: 'allocationLine.allocation.vehicle.contractor.name'
        },
        {
          text: this.$store.state.global.pages.driver.titleSingular,
          value: 'allocationLine.allocation.driver.name'
        },
        {
          text: `${this.$store.state.global.pages.driver.titleSingular} phone`,
          value: 'allocationLine.allocation.driver.contactPhone'
        },

        {
          text: this.$store.state.global.pages.itemType.titleSingular,
          value: 'allocationLine.itemType.name'
        },
        { text: 'Boxes', value: 'allocationLine.qty' },
        {
          text: 'Details',
          align: 'left',
          value: 'notes',
          type: 'text'
        },
        {
          text: `${this.$store.state.global.pages.allocation.titleSingular} Notes`,
          align: 'left',
          value: 'allocationLine.allocation.notes',
          type: 'text'
        },
        {
          text: 'Run #',
          align: 'right',
          value: 'allocationLine.destination.runCode',
          sortable: true
        },
        {
          text: `${this.$store.state.global.pages.docket.titleSingular} ID`,
          align: 'left',
          value: 'allocationLine.allocation.docketId',
          type: 'text'
        },
        // {
        //   text: 'Delivery Status',
        //   align: 'center',
        //   value: 'allocationLine.deliveryStatus',
        //   type: 'deliveryStatus',
        //   sortable: false
        // },
        { text: 'Created At', value: 'createdAt', type: 'date' },
        { text: 'Updated At', value: 'updatedAt', type: 'date' }
      ]
    }
  }
}
