<template>
  <v-card height="100%">
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-1">
        <span class="text-truncate">
          Edit {{ titleSingular.toLowerCase() }} line -
          <span class="pl-1 subtitle-1">
            ID: <span class="font-weight-bold">{{ item.id }}</span>
          </span>
        </span>
      </v-toolbar-title>
    </v-toolbar>
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-form ref="editForm" @submit.prevent="edit()">
            <v-card flat class="card-outlined mb-3">
              <v-card-text class="pb-0">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="* Description"
                      prepend-icon="mdi-note"
                      v-model="editedItem.description"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Account code"
                      prepend-icon="mdi-note"
                      v-model="editedItem.accountCode"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Tax Type"
                      prepend-icon="mdi-note"
                      v-model="editedItem.taxType"
                      :rules="notesRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Notes"
                      prepend-icon="mdi-note"
                      v-model="editedItem.notes"
                      :rules="notesRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Total"
                      prepend-icon="mdi-currency-usd"
                      v-model="editedItem.costTotal"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="grey darken-1" @click="close">Cancel</v-btn>
                <v-btn text color="primary" :loading="loading" type="submit">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import Store from '../../../store/computed/storeHelpers'
import AllocationValidation from '@/services/validation/allocation'
export default {
  mixins: [AllocationValidation],
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    action: {
      type: Function,
      required: true
    },
    close: {
      type: Function,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      editedItem: Object.assign({}, this.item),
      editAddress: false,
      currentDeliveryAddress: null
    }
  },
  computed: {
    destinations: Store.getSet({
      store: 'destinations',
      prop: 'destinationsLite'
    }),
    itemTypes: Store.getSet({ store: 'itemTypes', prop: 'itemTypesLite' })
  },
  methods: {
    edit() {
      if (!this.$refs.editForm.validate()) {
        this.snackFormError()
        return
      }
      this.action({ original: this.item, item: this.editedItem })
      this.close()
    },
    newAddress(address) {
      this.currentDeliveryAddress = address
      this.editedItem.addressStreet1 = address.addressStreet1
      this.editedItem.addressStreet2 = address.addressStreet2
      this.editedItem.addressCity = address.addressCity
      this.editedItem.addessState = address.addressState
      this.editAddress = false
    }
  }
}
</script>
