import AllocationLine from '@/services/models/allocationLine'
export default {
  methods: {
    makePdf(data) {
      const results = []
      for (const line of data) {
        const from = this.globalSettings.footerText.split('| ')
        const to = [
          line.source.project.name + ' / ' + line.source.name,
          `ABN: ${line.source.project.abn || ''}`,
          line.source.addressStreet1 || null,
          line.source.addressStreet2 || null,
          `${line.source.addressCity} ${line.source.addressState}`
        ].filter(item => item != null)

        const info = [
          {
            key: `${this.$store.state.global.pages.allocation.titleSingular} #`,
            data: line.id.toString()
          },
          { key: 'Date', data: line._date },
          {
            key: this.$store.state.global.pages.project.titleSingular,
            data: line.source.project.name
          },
          {
            key: this.$store.state.global.pages.source.titleSingular,
            data: line.source.name
          },
          { key: 'Run', data: line.source.runCode }
        ].filter(item => item.data != null)

        const extraInfo = [
          {
            key: this.$store.state.global.pages.driver.titleSingular,
            data: line.driver.name
          },
          {
            key: this.$store.state.global.pages.vehicle.titleSingular,
            data: line.vehicle.name
          },
          {
            key: this.$store.state.global.pages.vehicleType.titleSingular,
            data: line.vehicle.vehicleType.name
          },
          { key: 'Order #', data: line.externalRef },
          {
            key: this.$store.state.global.pages.allocation.titleSingular,
            data: line.docketId ? line.docketId.toString() : null
          }
        ].filter(item => item.data != null)
        const lines = line.lines.map(item => {
          item = new AllocationLine(item)
          const delWindow = item.destination.deliveryWindow
            ? ` (${item.destination.deliveryWindow})`
            : ''
          const delTime = item.delTime ? `Est. ${item.delTime}` : ''
          const notes = `${item.notes || ''} ${delTime} ${delWindow}`.trim()
          return {
            [this.$store.state.global.pages.destination.titleSingular]:
              item.destination.name,
            [this.$store.state.global.pages.itemType.titleSingular]:
              item.itemType.name,
            Qty: item.qty,
            Notes: notes
            // Delivery: item.deliveryStatus
          }
        })

        const total = 0
        const invoiceNotes = line.notes || ''
        const footerText = ['', '', this.globalSettings.footerText]

        const pdfName = `${this.$store.state.global.pages.allocation.titleSingular.toLowerCase()}_${
          line.id
        }.pdf`
        const columnStyles = {
          Qty: { halign: 'right' },
          Price: { halign: 'right' },
          Total: { halign: 'right' }
        }

        const columns = lines[0]
          ? Object.keys(lines[0]).map(str => {
              return { title: str, dataKey: str }
            })
          : []

        const makeFoot = () => {
          const totalsOffset = 2
          const foot = columns.length
            ? new Array(columns.length - totalsOffset).fill('')
            : []
          if (total) foot.push('Total', '$' + total)
          return [foot]
        }

        const result = {
          pdfName,
          docHeading: this.$store.state.global.pages.allocation.titleSingular.toUpperCase(),
          docSubheading: '',
          columns,
          foot: makeFoot(),
          columnStyles,
          pdfData: {
            type: 'allocation',
            from,
            toLabel: `${this.$store.state.global.pages.source.titleSingular}:`,
            to,
            info,
            extraInfo,
            lines,
            total,
            invoiceNotes,
            footerText
          }
        }
        results.push(result)
      }
      return results
    }
  }
}
