<template>
  <MasterFilePage>
    <template v-slot:control>
      <MasterFileControl
        :masterFile="masterFile"
        :addDialog="addDialog"
        :toggleAdd="toggleAdd"
        :loadingMasterFiles="!loadedItemTypesLite"
        :onFilterChange="updateFilterBy"
        :sortByItems="sortByItems"
      />
    </template>
    <template v-slot:view>
      <MasterFileView
        :count="Math.ceil(destinationCount / pagination.itemsPerPage)"
        :masterFile="masterFile"
        :subjects="destinations"
        :fields="fields"
        :loadingMasterFiles="
          loadingDestinations || !loadedItemTypesLite || !loadedProjectsLite
        "
        :loading="loading"
        :onEdit="editDestination"
        :onDelete="deleteDestination"
      />
    </template>
    <template v-slot:form>
      <v-dialog
        v-model="addDialog"
        width="700"
        @click:outside="() => (addDialog = false)"
        @keydown.esc="() => (addDialog = false)"
        v-if="loadedDestinations && loadedItemTypesLite && loadedProjectsLite"
      >
        <MasterFileForm
          :masterFile="masterFile"
          :fields="fields"
          :dialog="addDialog"
          :close="toggleAdd"
          :onSubmit="createDestination"
          :loading="loading"
        />
      </v-dialog>
    </template>
  </MasterFilePage>
</template>

<script>
import {
  addDestination,
  updateDestination,
  deleteDestination
} from '../../../services/requests/destinations'
import getDestinations from '../../../services/mixins/getMasterFiles/getDestinations'
import destinationFields from '@/services/mixins/fields/destinationFields'
import MasterFileCrudFunctions from '../../../services/mixins/masterFileCrudFunctions'
import Pagination from '../../../services/mixins/getMasterFiles/pagination'

import MasterFilePage from '../../../components/masterFiles/MasterFilePage'
import MasterFileControl from '../../../components/masterFiles/MasterFileControl'
import MasterFileView from '../../../components/masterFiles/MasterFileView'
import MasterFileForm from '../../../components/masterFiles/MasterFileForm'

import { debounce } from 'lodash'
export default {
  mixins: [
    getDestinations,
    destinationFields,
    MasterFileCrudFunctions,
    Pagination
  ],
  components: {
    MasterFilePage,
    MasterFileControl,
    MasterFileView,
    MasterFileForm
  },
  data() {
    return {
      masterFile: 'destination',
      prop: 'destinations',
      addDialog: false,
      filterBy: '',
      loading: false,
      sortByItems: [
        { id: 1, text: 'ID', value: 'id' },
        { id: 2, text: 'Name', value: 'name' },
        { id: 3, text: 'Run ID', value: 'runCode' },
        { id: 4, text: 'Updated At', value: 'updatedAt' }
      ]
    }
  },
  watch: {
    filterBy: debounce(function() {
      this.getDestinations()
      this.pagination.page = 1
    }, 300)
  },
  methods: {
    async createDestination(payload) {
      payload.geofenceRadius = Number(payload.geofenceRadius)
      await this.createMasterFile({ payload, action: addDestination })
    },
    async editDestination(destination) {
      destination.geofenceRadius = Number(destination.geofenceRadius)
      const { id, ...payload } = destination
      await this.editMasterFile({ id, payload, action: updateDestination })
    },
    async deleteDestination(destination) {
      await this.deleteMasterFile({
        payload: destination,
        action: deleteDestination
      })
    },
    toggleAdd() {
      this.addDialog = !this.addDialog
    },
    updateFilterBy(filterBy) {
      this.filterBy = filterBy
    }
  }
}
</script>
