import Store from '@/store/computed/storeHelpers'
import { getProjectsLite } from '../../../requests/projects'
export default {
  mounted() {
    this.getProjectsLite()
  },
  data() {
    return {
      loadedProjectsLite: false
    }
  },
  computed: {
    projectsLite: Store.getSet({ store: 'projects', prop: 'projectsLite' }),
    loadingProjectsLite: Store.getSet({
      store: 'projects',
      prop: 'loadingProjectsLite'
    })
  },
  methods: {
    async getProjectsLite() {
      try {
        this.loadingProjectsLite = true
        const result = await getProjectsLite()
        if (result) {
          this.projectsLite = result.data.result
        }
        this.loadedProjectsLite = true
        this.loadingProjectsLite = false
      } catch (err) {
        console.log(err)
        this.loadingProjectsLite = false
      }
    }
  }
}
