<template>
  <v-card flat class="card-outlined mx-3">
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="() => toggleAdd(false)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        Add
        {{ isStaged ? `(staged) ${titleSingular}` : titleSingular }}
      </v-toolbar-title>
    </v-toolbar>
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-sheet outlined :color="isStaged ? 'amber' : ''" rounded>
            <v-card flat class="card-outlined">
              <v-card-text class="pb-0">
                <CardHeader
                  :title="
                    `${
                      isStaged ? `${titleSingular} (staged)` : titleSingular
                    } Entry`
                  "
                  :icon="$store.state.global.pages.allocation.icon"
                  :actions="controlActions"
                />
                <v-form ref="addForm" @submit.prevent="add()">
                  <v-row class="mb-1">
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        label="Reference"
                        prepend-icon="mdi-group"
                        v-model="reference"
                        :rules="referenceRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <DatePickerWrapper
                        label="* Date"
                        v-model="date"
                        :rules="dateRules"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-autocomplete
                        :label="
                          `* ${$store.state.global.pages.source.titleSingular}`
                        "
                        :prepend-icon="$store.state.global.pages.source.icon"
                        v-model="sourceId"
                        :items="sources"
                        item-value="id"
                        item-text="sourceName_projectName"
                        :rules="sourceRules"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-autocomplete
                        :label="
                          `* ${$store.state.global.pages.driver.titleSingular}`
                        "
                        :prepend-icon="$store.state.global.pages.driver.icon"
                        v-model="driverId"
                        :items="filteredDrivers"
                        item-value="id"
                        item-text="driverName_contractorName"
                        :rules="driverRules"
                        @click:clear="
                          () =>
                            $nextTick(
                              () => (driverId = null),
                              (driverCompliances = null)
                            )
                        "
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-autocomplete
                        :label="
                          `* ${$store.state.global.pages.vehicle.titleSingular}`
                        "
                        :prepend-icon="$store.state.global.pages.vehicle.icon"
                        v-model="vehicleId"
                        :items="filteredVehicles"
                        item-value="id"
                        item-text="vehicleName_contractorName_vehicleType"
                        :rules="vehicleRules"
                        @click:clear="() => $nextTick(clearVehicle)"
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        label="Notes"
                        prepend-icon="mdi-note"
                        v-model="notes"
                        :rules="notesRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <DatePickerWrapper
                        label="End Date"
                        v-model="endDate"
                        :rules="[]"
                      />
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-switch
                        :label="isDay ? 'Day' : 'Night'"
                        color="primary"
                        v-model="isDay"
                        inset
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-switch
                        label="Staged"
                        color="primary"
                        v-model="isStaged"
                        inset
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" v-if="isStaged">
                      <v-expansion-panels flat class="card-outlined">
                        <v-expansion-panel active-class="mt-0">
                          <v-expansion-panel-header>
                            <h4 class="grey--text">
                              Staging Confirmations
                            </h4>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-row
                              v-for="item of stagingConfirmations"
                              :key="item.name"
                            >
                              <v-col cols="12" sm="3" class="py-0">
                                <v-select
                                  :label="`${item.name} Status`"
                                  :prepend-icon="item.icon"
                                  v-model="item.status"
                                  :items="['CONFIRMED', 'REVIEW', 'NOT SET']"
                                  :rules="stagingStatusRules"
                                ></v-select>
                              </v-col>
                              <v-col cols="12" sm="9" class="py-0">
                                <v-text-field
                                  :label="`${item.name} Notes`"
                                  prepend-icon="mdi-note"
                                  v-model="item.notes"
                                  :rules="stagingNotesRules"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-col>
                    <v-col
                      cols="12"
                      class="pt-0 d-flex justify-end"
                      v-if="lines.length"
                    >
                      <v-tooltip top :disabled="!hasExpiredCompliances">
                        <template v-slot:activator="{ on }">
                          <div v-on="on">
                            <v-btn
                              :disabled="
                                hasExpiredCompliances &&
                                  globalSettings.stopOnComplianceError
                              "
                              color="primary"
                              type="submit"
                              :loading="loading"
                              outlined
                              class="mb-3"
                            >
                              Save
                            </v-btn>
                          </div>
                        </template>
                        <span>
                          Expired compliance records exist!
                        </span>
                      </v-tooltip>
                    </v-col>
                    <StagedWarning
                      v-if="
                        isStaged &&
                          (duplicateVehicleLines.length ||
                            duplicateDriverLines.length)
                      "
                      :duplicateVehicleLines="duplicateVehicleLines"
                      :duplicateDriverLines="duplicateDriverLines"
                    />
                    <ComplianceWarning
                      v-if="driverCompliances.length"
                      :compliances="driverCompliances"
                      :count="driverComplianceCount"
                      :name="driverCompliances[0].driver.name"
                    />
                    <ComplianceWarning
                      v-if="vehicleCompliances.length"
                      :compliances="vehicleCompliances"
                      :count="vehicleComplianceCount"
                      :name="vehicleCompliances[0].vehicle.name"
                    />
                    <ComplianceWarning
                      v-if="contractorCompliances.length"
                      :compliances="contractorCompliances"
                      :count="contractorComplianceCount"
                      :name="contractorCompliances[0].contractor.name"
                    />
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-sheet>
        </v-col>

        <v-col cols="12">
          <AllocationLineAdd
            :lines="lines"
            :onAdd="addLine"
            :onEdit="editLine"
            :onDelete="deleteLine"
            :loading="loading"
            :lineHeaders="lineHeaders"
            :source="source"
          />
        </v-col>
      </v-row>
    </v-container>

    <!-- ADD RUN DIALOG -->
    <v-dialog
      v-model="addRunDialog"
      @click:outside="close"
      @keydown.esc="close"
      max-width="1100px"
    >
      <AddByRun
        :lines="lines"
        :destinationCount="destinationCount"
        :populateByRun="populateByRun"
        :getDestinationsByRun="getDestinationsByRun"
        :itemTypes="itemTypes"
        :close="close"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import Vue from 'vue'
import Store from '../../store/computed/storeHelpers'
import {
  addAllocation,
  getAllocationLines
} from '../../services/requests/allocations'
import { getCompliancesBySubjectId } from '../../services/requests/compliances'
import { getVehicle } from '../../services/requests/vehicles'
import { getDestinationsByRun } from '../../services/requests/destinations'
import AllocationValidation from '../../services/validation/allocation'
import FilterByContractor from '../../services/mixins/filterByContractor'
import FormatDates from '../../services/mixins/formatDates'
import Allocation from '../../services/models/allocation'
import AllocationLine from '../../services/models/allocationLine'
import Compliance from '../../services/models/compliance'
import LineHeaders from './lines/mixins/lineHeaders'

import CardHeader from '../misc/shared/CardHeader'
import DatePickerWrapper from '../misc/fields/DatePickerWrapper'
import AllocationLineAdd from '../allocations/lines/AllocationLineAdd'
import AddByRun from '../allocations/lines/AddByRun'
import ComplianceWarning from '../misc/ComplianceWarning'
import StagedWarning from '../misc/StagedWarning'
export default {
  mixins: [AllocationValidation, FilterByContractor, FormatDates, LineHeaders],
  components: {
    CardHeader,
    DatePickerWrapper,
    AllocationLineAdd,
    AddByRun,
    ComplianceWarning,
    StagedWarning
  },
  props: {
    toggleAdd: {
      type: Function,
      required: true
    },
    sources: {
      type: Array,
      required: true
    },
    vehicles: {
      type: Array,
      required: true
    },
    drivers: {
      type: Array,
      required: true
    },
    destinations: {
      type: Array,
      required: true
    },
    itemTypes: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      reference: null,
      date: null,
      sourceId: null,
      vehicleId: null,
      driverId: null,
      notes: null,
      endDate: null,
      isDay: true,
      // isStaged: false,
      stagingConfirmations: [
        {
          name: this.$store.state.global.pages.source.titleSingular,
          icon: this.$store.state.global.pages.source.icon,
          status: 'NOT SET',
          notes: null
        },
        {
          name: this.$store.state.global.pages.destination.titleSingular,
          icon: this.$store.state.global.pages.destination.icon,
          status: 'NOT SET',
          notes: null
        },
        {
          name: 'Amendments',
          icon: 'mdi-pen-plus',
          status: 'NOT SET',
          notes: null
        }
      ],
      stagedLines: [],
      lines: [],
      loading: false,
      addRunDialog: false,
      destinationCount: null,
      runDestinations: null,
      driverCompliances: [],
      driverComplianceCount: null,
      vehicleCompliances: [],
      vehicleComplianceCount: null,
      contractorCompliances: [],
      contractorComplianceCount: null
    }
  },
  mounted() {
    if (this.$store.state.global.defaultSourceId) {
      this.sourceId = this.$store.state.global.defaultSourceId
    }
  },
  watch: {
    calDate(val) {
      if (val) this.date = this.calDate
    },
    async driverId(val) {
      if (val) {
        try {
          if (this.selectedDriver.defaultVehicleId && !this.vehicleId) {
            this.vehicleId = this.selectedDriver.defaultVehicleId
          }
          const result = await getCompliancesBySubjectId(val, 'driver')
          if (result.data.result) {
            this.driverCompliances = result.data.result.rows.map(
              item => new Compliance(item)
            )
            this.driverComplianceCount = result.data.result.count
          }
          if (this.isStaged) this.getStagedLines()
        } catch (err) {
          console.log(err)
        }
      } else {
        this.driverCompliances = []
        this.driverComplianceCount = []
      }
    },
    async vehicleId(val) {
      if (val) {
        try {
          await this.getContractorCompliances(val)
          const result = await getCompliancesBySubjectId(val, 'vehicle')
          if (result.data.result) {
            this.vehicleCompliances = result.data.result.rows.map(
              item => new Compliance(item)
            )
            this.vehicleComplianceCount = result.data.result.count
          }
          if (this.isStaged) this.getStagedLines()
        } catch (err) {
          console.log(err)
        }
      } else {
        this.vehicleCompliances = []
        this.vehicleComplianceCount = []
      }
    },
    async date() {
      if (this.isStaged) this.getStagedLines()
    },
    async isStaged() {
      if (this.isStaged) this.getStagedLines()
    }
  },
  computed: {
    lineHeaders() {
      return this.allPossibleHeaders.filter(
        item => item.text != 'POD' && item.text != 'Delivery Status'
      )
    },
    calDate: Store.getSet({ store: 'allocations', prop: 'calDate' }),
    calNewAdded: Store.getSet({ store: 'allocations', prop: 'calNewAdded' }),
    hasExpiredCompliances() {
      const expiredVehicles = this.vehicleCompliances.find(
        ({ daysTillExpired }) => daysTillExpired < 1
      )
      const expiredDrivers = this.driverCompliances.find(
        ({ daysTillExpired }) => daysTillExpired < 1
      )
      const expiredContractors = this.contractorCompliances.find(
        ({ daysTillExpired }) => daysTillExpired < 1
      )
      return !!expiredVehicles || !!expiredDrivers || !!expiredContractors
    },
    duplicateDriverLines() {
      return this.stagedLines.filter(
        item => item.allocation?.driverId == this.driverId
      )
    },
    duplicateVehicleLines() {
      const vehicle = this.vehicles.find(({ id }) => id == this.vehicleId)
      return this.stagedLines.filter(
        item => item.allocation?.vehicle?.name == vehicle?.name
      )
    },
    hasDuplicate() {
      return (
        !!this.duplicateDriverLines.length ||
        !!this.duplicateVehicleLines.length
      )
    },
    selectedDriver() {
      if (!this.drivers || !this.driverId) return null
      return this.drivers.filter(({ id }) => this.driverId == id)[0]
    },
    controlActions() {
      return [
        {
          action: () => (this.addRunDialog = !this.addRunDialog),
          text: 'Run',
          icon: 'mdi-run-fast',
          toolTipText: 'Populate by run'
        }
      ]
    },
    isStaged: Store.getSet({ store: 'allocations', prop: 'isStaged' })
  },
  methods: {
    async getStagedLines() {
      try {
        if (this.isStaged && this.date) {
          const payload = {
            dateRangeStart: this.date,
            dateRangeEnd: this.date,
            isStaged: true,
            showVoid: false,
            filterCol: null,
            filterBy: null
          }
          const result = await getAllocationLines(payload)
          if (result.data.result) {
            this.stagedLines = result.data.result.map(item => {
              return new AllocationLine(item)
            })
          }
        }
      } catch (err) {
        console.log(err)
      }
    },
    async populateByRun(runCodeStart, runCodeEnd, itemType) {
      if (!runCodeStart || !runCodeEnd) {
        this.snack({
          text: `${this.titleSingular} line updated`,
          color: 'green'
        })
      }
      this.runDestinations.forEach(item => {
        this.lines.push({
          id: this.lines.length + 1,
          destination: item,
          itemType: {
            name: itemType.name
          },
          destinationId: item.id,
          itemTypeId: itemType.id,
          qty: 1,
          notes: '',
          addressStreet1: item.addressStreet1,
          addressStreet2: item.addressStreet2,
          addressCity: item.addressCity,
          addressState: item.addressState
        })
      })
      this.destinationCount = null
      this.runDestinations = null
    },
    async getDestinationsByRun(runCodeStart, runCodeEnd) {
      if (runCodeStart && runCodeEnd) {
        const payload = {
          runCodeStart: runCodeStart.runCode,
          runCodeEnd: runCodeEnd.runCode
        }
        const runDestinations = await getDestinationsByRun(payload)
        this.runDestinations = runDestinations.data.result
        this.destinationCount = runDestinations.data.count
      }
    },
    async getContractorCompliances(vehicleId) {
      try {
        const result = await getVehicle(vehicleId)
        const vehicle = result.data.result
        const res = await getCompliancesBySubjectId(
          vehicle.contractorId,
          'contractor'
        )
        if (res.data.result) {
          this.contractorCompliances = res.data.result.rows.map(
            item => new Compliance(item)
          )
          this.contractorComplianceCount = res.data.result.count
        }
      } catch (err) {
        console.log(err)
      }
    },
    async add() {
      if (!this.$refs.addForm.validate()) {
        this.snackFormError()
        return
      }
      if (this.endDate < this.date) {
        this.snack({
          text: 'End Date must be greater or equal to Date!',
          color: 'red'
        })
        return
      }

      try {
        this.loading = true
        const lines = []
        for (const line of this.lines) {
          lines.push({
            type: line.type,
            destinationId: line.destinationId,
            itemTypeId: line.itemTypeId,
            qty: line.qty,
            notes: line.notes,
            addressStreet1: line.addressStreet1,
            addressStreet2: line.addressStreet2,
            addressCity: line.addressCity,
            addressState: line.addressState
          })
        }
        const payload = {
          reference: this.reference,
          date: this.date,
          isDay: this.isDay,
          isStaged: this.isStaged,
          hasDuplicate: this.hasDuplicate,
          sourceId: this.sourceId,
          vehicleId: this.vehicleId,
          driverId: this.driverId,
          notes: this.notes,
          endDate: this.endDate,
          sourceConfirmed: this.stagingConfirmations[0].status,
          sourceNotes: this.stagingConfirmations[0].notes,
          destinationConfirmed: this.stagingConfirmations[1].status,
          destinationNotes: this.stagingConfirmations[1].notes,
          amendmentsConfirmed: this.stagingConfirmations[2].status,
          amendmentsNotes: this.stagingConfirmations[2].notes,
          lines
        }
        const result = await addAllocation(payload)
        if (result) {
          this.$store.commit('allocations/addToArrayState', {
            prop: 'allocations',
            value: new Allocation(result.data.result)
          })
          if (result.data.lines)
            for (const line of result.data.lines) {
              this.$store.commit('allocations/addToArrayState', {
                prop: 'lines',
                value: new AllocationLine(line)
              })
            }
          this.calNewAdded = true
          this.cancel()
          this.snackCreated()
        }
        this.loading = false
      } catch (err) {
        this.loading = false
        console.log(err)
      }
    },
    addLine(line) {
      const destination = this.destinations.find(
        ({ id }) => id === line.destinationId
      )
      const itemType = this.itemTypes.find(({ id }) => id === line.itemTypeId)
      this.lines.push({
        ...line,
        destination,
        itemType
      })
    },
    editLine({ original, item }) {
      item.destination = this.destinations.find(
        ({ id }) => id === item.destinationId
      )
      item.itemType = this.itemTypes.find(({ id }) => id === item.itemTypeId)
      const i = this.lines.indexOf(original)
      if (~i) Vue.set(this.lines, i, item)
    },
    deleteLine(line) {
      const i = this.lines.indexOf(line)
      if (~i) this.lines.splice(i, 1)
    },
    cancel() {
      this.toggleAdd(true)
      this.clear()
    },
    close() {
      this.addRunDialog = false
    },
    clear() {
      this.vehicleId = null
      this.driverId = null
      this.notes = null
      this.endDate = null
      this.calDate = null
      this.lines = []
    },
    clearVehicle() {
      this.vehicleId = null
      this.vehicleCompliances = []
      this.vehicleComplianceCount = null
      this.contractorCompliances = []
      this.contractorComplianceCount = null
    }
  }
}
</script>
