<template>
  <v-app>
    <v-main class="background">
      <transition name="fadeToggle" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>
    <Snackbar />
  </v-app>
</template>

<script>
import Snackbar from './components/misc/Snackbar'
export default {
  name: 'App',
  created() {
    document.title = 'Payloader Console'
  },
  components: { Snackbar }
}
</script>

<style>
.fadeToggle-enter-active,
.fadeToggle-leave-active {
  transition: opacity 0.2s;
}
.fadeToggle-enter,
.fadeToggle-leave-to {
  opacity: 0;
}
.card-outlined {
  border: 1px solid #bdbdbd !important;
  transition: all 0.3s ease;
}

/* LIST ANIMATIONS GENERIC */
.data-list-item {
  /* margin-right: 10px; */
  transition: all 0.4s;
  display: inline-block;
  vertical-align: top;
  width: 100%;
}

.field-list-item {
  transition: all 0.4s;
  display: inline-block;
  width: 100%;
  max-height: 100px;
}

.data-list-leave-active {
  position: absolute;
}

.field-list-leave-active {
  position: absolute;
  max-height: 0px;
}

/* LIST ANIMATIONS SPECIFIC MOVEMENT*/
.data-list-enter,
.data-list-leave-to {
  opacity: 0;
  transform: translateX(-200px);
}
</style>
