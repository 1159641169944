<template>
  <v-card flat class="card-outlined">
    <v-card-text>
      <v-card-title
        class="px-0 pt-0 pb-3 font-weight-regular d-flex align-center justify-space-between"
      >
        <div class="d-flex align-center">
          <v-btn v-if="backAction" class="mr-2" icon @click="backAction">
            <v-icon>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-icon class="pr-2" color="primary">{{
            $store.state.global.pages[masterFile].icon
          }}</v-icon>
          {{ $store.state.global.pages[masterFile].title }}
        </div>

        <v-btn
          v-if="action"
          color="primary"
          text
          :icon="!$vuetify.breakpoint.smAndUp"
          @click="action"
        >
          <v-icon v-if="actionIcon" :left="$vuetify.breakpoint.smAndUp">
            {{ actionIcon }}
          </v-icon>
          <span v-if="actionText">
            {{ $vuetify.breakpoint.smAndUp ? actionText : '' }}
          </span>
        </v-btn>
      </v-card-title>
      <v-row v-if="onFilterChange">
        <v-col v-if="onParentChange" cols="12" sm="4" lg="3" class="py-0">
          <v-autocomplete
            :prepend-icon="$store.state.global.pages.contractor.icon"
            :label="filterLabel"
            v-model="currentParentId"
            :items="parents"
            item-text="name"
            item-value="id"
            clearable
            class="py-3"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="4" lg="3" class="py-0">
          <div class="d-flex justify-space-between">
            <v-select
              prepend-icon="mdi-sort-variant"
              label="Sort by..."
              v-model="$store.state.masterfiles.pagination.sortBy[0]"
              :items="sortByItems"
              item-text="text"
              item-value="value"
              class="py-3"
            ></v-select>
            <v-btn
              v-if="$store.state.masterfiles.pagination.sortBy[0]"
              color="primary"
              icon
              @click="
                $store.state.masterfiles.sortDesc = !$store.state.masterfiles
                  .sortDesc
              "
              class="mt-3"
            >
              <v-icon>
                {{
                  !$store.state.masterfiles.sortDesc
                    ? 'mdi-arrow-up'
                    : 'mdi-arrow-down'
                }}
              </v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12" sm="4" lg="3" class="py-0">
          <v-text-field
            prepend-icon="mdi-filter-variant"
            label="Filter by..."
            v-model="filterBy"
            class="py-3"
          ></v-text-field>
        </v-col>
        <v-col
          v-if="useInactiveSwitch || useRequestSwitch"
          cols="12"
          sm="4"
          lg="3"
          class="d-flex justify-space-between align-center mb-4"
        >
          <v-switch
            v-if="useInactiveSwitch"
            inset
            label="Show inactive"
            v-model="showInactive"
            hide-details
            class="mt-0"
          ></v-switch>
          <v-switch
            v-if="useRequestSwitch"
            inset
            label="Show request"
            v-model="showRequest"
            hide-details
            class="mt-0"
          ></v-switch>
        </v-col>
      </v-row>
    </v-card-text>
    <OverlapFab
      v-if="userAuthClass"
      :condition="addDialog"
      :action="toggleAdd"
    />
  </v-card>
</template>

<script>
import OverlapFab from '../misc/OverlapFab'
import Store from '@/store/computed/storeHelpers'
export default {
  components: {
    OverlapFab
  },
  props: {
    masterFile: {
      type: String,
      required: true
    },
    addDialog: {
      type: Boolean,
      required: true
    },
    toggleAdd: {
      type: Function,
      required: true
    },
    onFilterChange: {
      type: Function,
      required: false
    },
    onParentChange: {
      type: Function,
      required: false
    },
    parents: {
      type: Array,
      required: false
    },
    parentId: {
      type: Number,
      required: false
    },
    action: {
      type: Function,
      required: false
    },
    actionIcon: {
      type: String,
      required: false
    },
    sortByItems: {
      type: Array,
      required: false,
      default: () => [
        { id: 1, text: 'ID', value: 'id' },
        { id: 2, text: 'Name', value: 'name' },
        { id: 3, text: 'Updated At', value: 'updatedAt' }
      ]
    },
    actionText: {
      type: String,
      required: false
    },
    backAction: {
      type: Function,
      required: false
    }
  },
  data() {
    return {
      filterBy: null,
      currentParentId: null
    }
  },
  computed: {
    useInactiveSwitch() {
      return (
        this.$route.name == 'driver' ||
        this.$route.name == 'vehicle' ||
        this.$route.name == 'contractor' ||
        this.$route.name == 'project' ||
        this.$route.name == 'source'
      )
    },
    useRequestSwitch() {
      return (
        this.$route.name == 'driver' ||
        this.$route.name == 'vehicle' ||
        this.$route.name == 'contractor' ||
        this.$route.name == 'itemType' ||
        this.$route.name == 'source' ||
        this.$route.name == 'destination'
      )
    },
    filterLabel() {
      return this.masterFile == 'source'
        ? `Select ${this.$store.state.global.pages.project.titleSingular.toLowerCase()}`
        : `Select ${this.$store.state.global.pages.contractor.titleSingular.toLowerCase()}`
    },
    showInactive: Store.getSet({ store: 'masterfiles', prop: 'showInactive' }),
    showRequest: Store.getSet({ store: 'masterfiles', prop: 'showRequest' })
  },
  watch: {
    filterBy(val) {
      if (this.onFilterChange) this.onFilterChange(val)
    },
    currentParentId(val) {
      if (this.onParentChange) this.onParentChange(val)
    },
    parentId(val) {
      if (val) this.currentParentId = val
    }
  }
}
</script>
