import DriverValidation from '../../validation/driver'
import getContractorsLite from '../getMasterFiles/lite/getContractorsLite'
import getSourcesLite from '../getMasterFiles/lite/getSourcesLite'
import getVehiclesLite from '../getMasterFiles/lite/getVehiclesLite'
import getItemTypesLite from '../getMasterFiles/lite/getItemTypesLite'
import getUsers from '../getMasterFiles/getUsers'
import getProjectsLite from '../getMasterFiles/lite/getProjectsLite'

export default {
  mixins: [
    DriverValidation,
    getContractorsLite,
    getSourcesLite,
    getVehiclesLite,
    getItemTypesLite,
    getUsers,
    getProjectsLite
  ],
  data() {
    return {
      states: ['NSW', 'QLD', 'SA', 'TAS', 'VIC', 'WA', 'NT', 'ACT']
    }
  },
  computed: {
    fields() {
      return [
        {
          label: '* Name',
          prop: 'name',
          value: null,
          sm: '6',
          rules: this.nameRules
        },
        {
          label: '* Username',
          prop: 'username',
          value: '',
          type: 'login',
          sm: '6',
          rules: this.usernameRules
        },
        {
          label: 'Contact Email',
          prop: 'email',
          value: null,
          sm: '6',
          rules: this.contactEmailRules
        },
        {
          label: 'Contact Phone',
          prop: 'contactPhone',
          value: null,
          sm: '6',
          rules: this.contactPhoneRules
        },
        {
          label: '* Password',
          prop: 'password',
          value: '',
          type: 'password',
          sm: '6',
          rules: this.passwordRules
        },
        {
          label: '* Confirm Password',
          prop: 'confirmedPassword',
          value: '',
          type: 'password',
          sm: '6',
          rules: this.passwordRules
        },
        {
          label: `* ${this.$store.state.global.pages.contractor.titleSingular}`,
          prop: 'contractorId',
          value: null,
          sm: '6',
          rules: this.contractorRules,
          items: this.contractorsLite
        },
        {
          label: 'Colour',
          prop: 'color',
          value: '#00a999',
          sm: '6',
          type: 'color'
        },
        {
          label: `Default ${this.$store.state.global.pages.source.titleSingular}`,
          prop: 'defaultSourceId',
          value: null,
          nameValue: 'sourceName_projectName',
          clearable: true,
          sm: '6',
          items: this.sourcesLite
        },
        {
          label: `Default ${this.$store.state.global.pages.vehicle.titleSingular}`,
          prop: 'defaultVehicleId',
          clearable: true,
          value: null,
          nameValue: 'vehicleName_contractorName_vehicleType',
          sm: '6',
          items: this.vehiclesLite
        },
        {
          label: `Default ${this.$store.state.global.pages.itemType.titleSingular}`,
          prop: 'defaultItemTypeId',
          clearable: true,
          value: null,
          sm: '6',
          items: this.itemTypesLite
        },
        {
          label: `Run(s)`,
          prop: 'defaultRun',
          clearable: true,
          value: null,
          sm: '6'
        },
        {
          label: 'Availability',
          prop: 'availability',
          value: null,
          sm: '6',
          rules: this.detailsRules,
          hint: 'eg 06:00-11:30, 12:15-14:00 - 24 hour format'
        },
        {
          label: 'Street Address 1',
          prop: 'addressStreet1',
          value: null,
          sm: '6',
          rules: this.addressRules
        },
        {
          label: 'Street Address 2',
          prop: 'addressStreet2',
          value: null,
          sm: '6',
          rules: this.addressRules
        },
        {
          label: 'City',
          prop: 'addressCity',
          value: null,
          sm: '4',
          rules: this.addressCityRules
        },
        {
          label: 'State',
          prop: 'addressState',
          value: null,
          sm: '4',
          items: this.states
        },
        {
          label: 'Post Code',
          prop: 'addressPostCode',
          value: null,
          sm: '4',
          rules: this.addressPostCodeRules
        },
        {
          label: 'Latitude',
          prop: 'lat',
          value: null,
          sm: '6',
          rules: null,
          type: 'hidden'
        },
        {
          label: 'Longitude',
          prop: 'long',
          value: null,
          sm: '6',
          rules: null,
          type: 'hidden'
        },

        // {
        //   label: '* User',
        //   prop: 'userId',
        //   value: null,
        //   sm: '6',
        //   rules: this.userRules,
        //   items: this.users
        // },
        {
          label: 'Image',
          prop: 'imageKey',
          value: null,
          sm: '6',
          type: 'image'
        },
        {
          label: 'Active',
          prop: 'active',
          value: true,
          sm: '6',
          type: 'boolean'
        },
        {
          label: 'Request',
          prop: 'request',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: 'GPS Enabled',
          prop: 'gpsEnabled',
          value: true,
          sm: '6',
          type: 'boolean'
        },
        {
          label: 'Licence expiry',
          prop: 'licenceExpiry',
          value: null,
          sm: '6',
          disabled: true
        },
        {
          label: `${this.$store.state.global.pages.project.titleSingular} inductions`,
          prop: 'allowedProjects',
          items: this.projectsLite,
          value: [],
          type: 'expansionCheckbox',
          panel: -1,
          icon: this.$store.state.global.pages.project.icon
        }
      ]
    }
  }
}
