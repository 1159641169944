<template>
  <v-img
    class="bg-image"
    src="https://images.pexels.com/photos/358622/pexels-photo-358622.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
    cover
    max-height="100vh"
    gradient="to top right, rgba(49, 51, 63, 0.64), rgba(25,32,72,.9)"
  >
    <v-container>
      <v-row justify="center">
        <v-col md="4">
          <v-card class="mt-16" max-width="800px">
            <v-card-title>
              <v-col class="pa-0 ma-0">
                <div>
                  XYZ Transport
                </div>
                <div class="subtitle-1">Track my delivery</div>
              </v-col>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="trackingForm"
                @submit.prevent="submit()"
                class="pb-2"
              >
                <v-text-field
                  label="Tracking ID"
                  prepend-icon="mdi-map-marker"
                  v-model="id"
                ></v-text-field>
                <div class="text-right">
                  <v-btn type="submit" text color="primary">Submit</v-btn>
                </div>
              </v-form>
              <!-- <v-divider></v-divider> -->
              <v-card>
                <v-card-title class="px-0 pt-0">
                  <MglMap
                    :accessToken="accessToken"
                    :mapStyle.sync="mapStyle"
                    :center="coodinates"
                    :zoom="12"
                    :style="`height: ${20}vh; width: 100vw`"
                  >
                    <div>
                      <MglMarker :coordinates="coodinates" color="green">
                      </MglMarker>
                    </div>
                  </MglMap>
                </v-card-title>
                <v-card-text>
                  <div v-if="trackingLine" class="mt-0">
                    <span class="d-flex justify-space-between">
                      <span class="title">
                        {{ deliveryStatus.title }}
                      </span>
                      <v-icon
                        size="42"
                        class="mb-1 justify-end"
                        :color="deliveryStatus.color"
                        >{{ deliveryStatus.icon }}</v-icon
                      >
                    </span>
                    <div>
                      {{ deliveryStatus.text }}
                    </div>
                    <div class="font-weight-bold mt-1">
                      {{ makePhoneStyleDate(trackingLine.allocation.date) }}
                    </div>
                    <span
                      >{{ trackingLine.addressCity }},
                      {{ trackingLine.addressState }}</span
                    >
                    <div class="mt-2">
                      Your driver is
                      {{ trackingLine.allocation.driver.name }}
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-img>
</template>
<script>
import getTracking from '../../services/mixins/getTracking'
import FormatDates from '@/services/mixins/formatDates'
import Mapbox from 'mapbox-gl'
import { MglMap, MglMarker } from 'vue-mapbox'

export default {
  name: 'Tracking',
  components: {
    MglMap,
    MglMarker
  },
  created() {
    this.mapbox = Mapbox
  },
  mounted() {
    this.setDocId()
  },
  data() {
    return {
      id: null,
      accessToken:
        'pk.eyJ1IjoiZHlsMzI0IiwiYSI6ImNrZXdkb3hjeTA5ZGUzM21mYXZteDduc2oifQ.BVYYH13XaGFT0zoZXEl-lg',
      mapStyle: 'mapbox://styles/mapbox/streets-v11' // your map style
    }
  },
  mixins: [getTracking, FormatDates],
  computed: {
    coodinates() {
      return this.trackingLine
        ? [this.trackingLine.long, this.trackingLine.lat]
        : [0, 0]
    },
    deliveryStatus() {
      if (!this.trackingLine)
        return {
          title: 'Not found',
          text: 'Not found',
          icon: 'mdi-truck',
          color: 'grey'
        }
      if (!this.trackingLine.allocation.docketId)
        return {
          title: 'Scheduled for delivery',
          text: 'Your order has been scheduled for delivery on: ',
          icon: 'mdi-truck',
          color: 'amber'
        }
      if (this.trackingLine.lineCount > 0)
        return {
          title: 'Delivery complete!',
          text: 'Your order was deilvered on:',
          icon: 'mdi-check-circle-outline',
          color: 'green'
        }
      return {
        title: 'On board with driver!',
        text: 'Your order is on board with the driver, for delivery on:',
        icon: 'mdi-truck-fast',
        color: 'amber'
      }
    }
  },
  methods: {
    async submit() {
      if (!this.$refs.trackingForm.validate()) {
        this.snackFormError()
        return
      }
      this.loading = true
      await this.getAllocationLineById(this.id)

      // this.line = new AllocationLine(this.line)
      if (!this.trackingLine)
        this.snack({
          text: 'No data found',
          color: 'error'
        })
      this.loading = false
    },
    async setDocId() {
      if (this.$route.query.id) {
        this.id = this.$route.query.id
        this.submit()
      }
    }
  }
}
</script>
