import Api from '@/services/api.js'

export const addPlan = payload => {
  return Api.post('plans/', payload)
}
export const getPlans = payload => {
  return Api.post('plans/get', payload)
}
export const updatePlan = (id, payload) => {
  return Api.put(`plans/${id}`, payload)
}
export const deletePlan = id => {
  return Api.delete(`plans/${id}`)
}
export const addPlanLine = payload => {
  return Api.post('plans/lines', payload)
}
export const getPlanLines = payload => {
  return Api.post('plans/lines/get', payload)
}
export const getLinesByPlanId = id => {
  return Api.get(`plans/lines/${id}`)
}
export const updatePlanLinesWithWaypoint = payload => {
  return Api.put('plans/lines/update/waypoint', payload)
}
export const updatePlanLine = (id, payload) => {
  return Api.put(`plans/lines/${id}`, payload)
}
export const deletePlanLine = id => {
  return Api.delete(`plans/lines/${id}`)
}
// export const getPlansByIds = payload => {
//   return Api.post('plans/byIds', payload)
// }
// export const getPlansByIdsWithLines = payload => {
//   return Api.post('plans/byIdsWithLines', payload)
// }
// export const getPlansWithLines = payload => {
//   return Api.post('plans/getWithLines', payload)
// }
// export const getLinesByPlanIds = payload => {
//   return Api.post('plans/lines/byPlanIds', payload)
// }
// export const getPlanLinesByIds = payload => {
//   return Api.post('plans/lines/byIds', payload)
// }
