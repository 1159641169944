var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MasterFilePage',{scopedSlots:_vm._u([{key:"control",fn:function(){return [_c('MasterFileControl',{attrs:{"masterFile":_vm.masterFile,"addDialog":_vm.addDialog,"toggleAdd":_vm.toggleAdd,"loadingMasterFiles":!_vm.loadedDestinationsLite,"onSortChange":_vm.updateSortBy,"onFilterChange":_vm.updateFilterBy}})]},proxy:true},{key:"view",fn:function(){return [_c('MasterFileView',{attrs:{"count":Math.ceil(_vm.projectCount / _vm.pagination.itemsPerPage),"masterFile":_vm.masterFile,"subjects":_vm.sortedProjects,"fields":_vm.fields,"loadingMasterFiles":_vm.loadingProjects ||
          !_vm.loadedDestinationsLite ||
          !_vm.loadedContractorsLite ||
          !_vm.loadedDriversLite ||
          !_vm.loadedVehiclesLite,"loading":_vm.loading,"onEdit":_vm.editProject,"onDelete":_vm.deleteProject}})]},proxy:true},{key:"form",fn:function(){return [(
        _vm.loadedProjects &&
          _vm.loadedDestinationsLite &&
          _vm.loadedContractorsLite &&
          _vm.loadedDriversLite &&
          _vm.loadedVehiclesLite
      )?_c('v-dialog',{attrs:{"width":"700"},on:{"click:outside":function () { return (_vm.addDialog = false); },"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return (function () { return (_vm.addDialog = false); })($event)}},model:{value:(_vm.addDialog),callback:function ($$v) {_vm.addDialog=$$v},expression:"addDialog"}},[_c('MasterFileForm',{attrs:{"masterFile":_vm.masterFile,"fields":_vm.fields,"dialog":_vm.addDialog,"close":_vm.toggleAdd,"onSubmit":_vm.createProject,"loading":_vm.loading}})],1):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }