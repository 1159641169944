<template>
  <span>
    <span
      class="show-route"
      v-if="planLines && !item.closed && planVehicles.length"
    >
      <v-btn color="primary" text @click="() => (confirmOptimiseDialog = true)">
        <v-icon left>
          {{ $store.state.global.pages.destination.icon }}
        </v-icon>
        <span>Optimise Route - MB (beta)</span>
      </v-btn>
    </span>
    <v-dialog v-model="confirmOptimiseDialog" width="900px">
      <ConfirmOptimise
        :loading="loadingRouteData"
        :close="() => (confirmOptimiseDialog = false)"
        :action="optimiseRouteMapbox"
      />
    </v-dialog>
  </span>
</template>

<script>
import ConfirmOptimise from './ConfirmOptimise'
import FormatDates from '@/services/mixins/formatDates'

export default {
  mixins: [FormatDates],
  components: {
    ConfirmOptimise
  },
  props: {
    updateLinesWithWaypoints: {
      type: Function,
      required: true
    },
    planLines: {
      type: Array,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    planVehicles: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      confirmOptimiseDialog: false,
      loadingRouteData: false,
      linesWithWayPointData: [],
      mapboxAccessToken: process.env.VUE_APP_MGL_KEY,
      defaultHours: 8,
      defaultItemM3: 0.001
    }
  },
  methods: {
    async optimiseRouteMapbox(traffic, type) {
      this.loadingRouteData = true
      const jobDate = new Date(this.item.date)
      const totalVolume = this.planLines.reduce(
        (acc, line) =>
          acc + line.qty * (line.itemType.volume || this.defaultItemM3),
        0
      )
      const defaultCapacity = Math.ceil(totalVolume / this.planVehicles.length)
      const locations = this.createLocations()
      const agents = this.createAgents(
        defaultCapacity,
        jobDate,
        locations,
        traffic
      )
      const shipments = this.createShipments(jobDate, locations)
      const requestOptions = this.createRequestOptions(
        agents,
        shipments,
        locations,
        type
      )
      console.log('request: ', requestOptions)
      try {
        const query = await fetch(
          `https://api.mapbox.com/optimized-trips/v2?access_token=${this.mapboxAccessToken}`,
          requestOptions
        )
        const response = await query.json()
        let result = null
        if (response.status == 'ok')
          result = await this.getResult(response.id, 1)
        else throw new Error('Error optimising route')
        if (result) await this.handleResult(result)
        else throw new Error('Error getting result')
        // await this.handleResult(null)
      } catch (err) {
        this.loadingRouteData = false
        this.confirmOptimiseDialog = false
        console.log(err)
      }
    },
    async handleResult(result) {
      if (result.routes) {
        this.linesWithWayPointData = []
        for (const feature of result.routes) {
          const featureLines = this.processFeature(feature)
          for (const line of featureLines) {
            this.linesWithWayPointData.push(line)
          }
        }
        const lineIdsUpdated = this.linesWithWayPointData.map(line => line.id)
        const linesNotUpdated = this.planLines
          .filter(line => !lineIdsUpdated.includes(line.id))
          .map(line => {
            return {
              ...line,
              waypointIndex: null,
              delTime: null,
              vehicleId: null,
              driverId: null
            }
          })
        this.linesWithWayPointData = [
          ...this.linesWithWayPointData,
          ...linesNotUpdated
        ]
        this.updateLinesWithWaypoints(this.linesWithWayPointData)
        this.loadingRouteData = false
        this.confirmOptimiseDialog = false
      }
    },
    async getResult(id, count) {
      const delay = ms => new Promise(res => setTimeout(res, ms))
      console.log('id: ', id)
      console.log('count: ', count)
      try {
        await delay(7000)
        const query = await fetch(
          `https://api.mapbox.com/optimized-trips/v2/${id}?access_token=${this.mapboxAccessToken}`,
          { method: 'GET', headers: { 'Content-Type': 'application/json' } }
        )
        const response = await query.json()
        console.log(response)
        if (response.status === 'processing' && count <= 5) {
          const result = await this.getResult(id, count + 1)
          if (result.status !== 'processing') {
            return result
          }
        } else return response
      } catch (err) {
        this.loadingRouteData = false
        this.confirmOptimiseDialog = false
        console.log(err)
      }
    },
    processFeature(feature) {
      let linesWithWaypoint = []
      const actions = feature.stops.filter(({ type }) => type === 'dropoff')
      for (const [i, action] of actions.entries()) {
        const actionId = action.location.split('-')[0]
        const line = this.planLines.find(({ id }) => id === Number(actionId))
        if (line)
          linesWithWaypoint.push({
            ...line,
            waypointIndex: i + 1,
            delTime: this.makeTimeForEntry(new Date(action.eta))
          })
      }
      const featureVehicle = this.planVehicles.find(
        ({ id }) => id.toString() == feature.vehicle
      )
      return linesWithWaypoint.map(line => {
        return {
          ...line,
          vehicleId: featureVehicle.id,
          vehicle: featureVehicle,
          driverId: featureVehicle.driver?.id,
          driver: featureVehicle.driver
        }
      })
    },
    createRequestOptions(agents, shipments, locations, type) {
      const body = {
        version: 1,
        locations,
        vehicles: agents,
        shipments,
        options: {
          objectives: [type]
        }
      }
      console.log(body)
      var bodyString = JSON.stringify(body)
      return {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: bodyString
      }
    },
    createLocations() {
      const locations = this.planLines.map(line => {
        return {
          name: `${line.id.toString()}-${line.destination.id.toString()}`,
          coordinates: [line.long, line.lat]
        }
      })
      locations.unshift({
        name: 'warehouse',
        coordinates: [this.item.source.long, this.item.source.lat]
      })
      const driverLocations = this.planVehicles.map(vehicle => {
        return vehicle?.driver?.long && vehicle?.driver?.lat
          ? {
              name: `driver-${vehicle.driver.id.toString()}`,
              coordinates: [vehicle.driver.long, vehicle.driver.lat]
            }
          : {
              name: `driver-${vehicle.driver.id.toString()}`,
              coordinates: [this.item.source.long, this.item.source.lat]
            }
      })
      return [...locations, ...driverLocations]
    },
    createAgents(defaultCapacity, jobDate, locations, traffic) {
      const end = `${Number(this.item.start.slice(0, 2)) +
        this.defaultHours}${this.item.start.substring(2)}`
      const defaultStart = this.convertTime(
        this.item.start,
        jobDate
      ).toISOString()
      const defaultEnd = this.convertTime(end, jobDate).toISOString()
      const defaultBreaks = {
        earliest_start: defaultStart,
        latest_end: defaultEnd,
        duration: 1800
      }
      // const defaultBreaks = {
      //   earliest_start: '2024-04-24T00:00:00.000Z',
      //   latest_end: '2024-04-24T01:00:00.000Z',
      //   duration: 1800
      // }

      const maxWindow = {
        startEnd: [defaultStart, defaultEnd],
        breaks: [defaultBreaks]
      }
      return this.planVehicles.map(vehicle => {
        const driverWindow = vehicle?.driver?.availability
          ? this.getDriverTimes(jobDate, vehicle?.driver?.availability)
          : maxWindow
        const vehicleCapacity =
          Number((vehicle.capacity || defaultCapacity).toFixed(3)) * 1000
        console.log(driverWindow)
        return {
          name: vehicle.id.toString(),
          routing_profile: traffic || 'mapbox/driving-traffic', //'mapbox/driving'
          start_location: locations[0].name,
          end_location: `driver-${vehicle.driver.id.toString()}`,
          capacities: {
            boxes: vehicleCapacity
          },
          earliest_start: driverWindow.startEnd[0],
          latest_end: driverWindow.startEnd[1]
          // breaks: [defaultBreaks] // Returns unsolvable route if breaks are used??
        }
      })
    },
    createShipments(jobDate, locations) {
      const end = `${Number(this.item.start.slice(0, 2)) +
        this.defaultHours}${this.item.start.substring(2)}`
      const maxWindow = [
        {
          earliest: this.convertTime(this.item.start, jobDate).toISOString(),
          latest: this.convertTime(end, jobDate).toISOString(),
          type: 'strict'
        }
      ]
      return this.planLines.map(line => {
        const timeWindows =
          line?.destination?.deliveryWindow && this.item.start
            ? this.getRelativeTimes(jobDate, line?.destination?.deliveryWindow)
            : maxWindow
        const amount =
          Number(
            ((line.itemType.volume || this.defaultItemM3) * line.qty).toFixed(3)
          ) * 1000
        const location = locations.find(
          loc =>
            loc.name ===
            `${line.id.toString()}-${line.destination.id.toString()}`
        )
        return {
          name: `${line.id.toString()}-${line.destination.id.toString()}`,
          from: locations[0].name,
          to: location.name,
          size: {
            boxes: amount
          },
          pickup_duration: 0,
          dropoff_duration: 15 * 60,
          pickup_times: maxWindow,
          dropoff_times: timeWindows
        }
      })
    },
    getRelativeTimes(date, windowsString) {
      return windowsString.split(',').map(delWindow => {
        const delWindowArr = delWindow.split('-')
        return {
          earliest: this.convertTime(
            delWindowArr[0].trim(),
            date
          ).toISOString(),
          latest: this.convertTime(delWindowArr[1].trim(), date).toISOString(),
          type: 'strict'
        }
      })
    },
    getDriverTimes(date, windowsString) {
      const driverTimes = windowsString.split(',').map(delWindow => {
        const delWindowArr = delWindow.split('-')
        return {
          earliest: this.convertTime(
            delWindowArr[0].trim(),
            date
          ).toISOString(),
          latest: this.convertTime(delWindowArr[1].trim(), date).toISOString(),
          type: 'strict'
        }
      })
      const driverStart = driverTimes[0].earliest
      const driverEnd = driverTimes[driverTimes.length - 1].latest
      return {
        startEnd: [driverStart, driverEnd],
        breaks: [
          {
            earliest_start:
              driverTimes.length > 1 ? driverTimes[0].latest : driverStart,
            latest_end:
              driverTimes.length > 1
                ? driverTimes[driverTimes.length - 1].earliest
                : driverEnd,
            duration: 1800
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
