<template>
  <v-window-item :value="step">
    <v-card-title class="font-weight-regular px-0 pt-0">{{
      title
    }}</v-card-title>
    <v-form
      :ref="refName"
      @submit.prevent="onSubmit($refs[refName], editedFields)"
    >
      <v-text-field
        v-for="field in Object.keys(editedFields)"
        :key="field"
        :label="editedFields[field].label"
        v-model="editedFields[field].value"
        :rules="editedFields[field].rules ? editedFields[field].rules : []"
        :type="getFieldType(field)"
        outlined
        rounded
      ></v-text-field>
      <v-btn text @click="toggleStep" :disabled="loading">{{
        toggleText
      }}</v-btn>
      <v-btn
        class="mt-2"
        block
        color="primary"
        :loading="loading"
        type="submit"
        >{{ submitText || title }}</v-btn
      >
    </v-form>
  </v-window-item>
</template>

<script>
export default {
  props: {
    onSubmit: Function,
    toggleStep: Function,
    title: String,
    toggleText: String,
    fields: Object,
    step: Number,
    refName: String,
    loading: Boolean,
    submitText: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      editedFields: { ...this.fields }
    }
  },
  methods: {
    getFieldType(field) {
      if (field === 'password' || field === 'confirmedPassword') {
        return 'password'
      }
      if (field === 'email') return 'email'
      return 'text'
    }
  }
}
</script>
