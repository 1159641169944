import { parseISO } from 'date-fns'
import { makeTime } from '../../utils/FormatDates'

class Pod {
  constructor(pod) {
    Object.keys(pod).forEach(key => {
      this[key] = pod[key]
    })
  }

  get deliveredAt() {
    return makeTime(parseISO(this.createdAt))
  }
}

export default Pod
