<template>
  <v-row>
    <v-col cols="7" sm="6" md="4" ls="3" class="py-3">
      <v-select
        label="Select action"
        :items="fieldsToChange"
        v-model="fieldToChange"
        item-value="value"
        return-object
        prepend-icon="mdi-format-list-bulleted"
        clearable
        :clear-icon-cb="clearFieldToChange"
        :color="$store.state.global.pages[$route.name].color"
        @change="
          () =>
            updateFieldToChange ? updateFieldToChange(fieldToChange) : null
        "
      >
        <template v-slot:item="{ item }">
          <v-list-item-action>
            <v-icon :color="item.color">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </template>
        <template v-slot:selection="{ item }">
          {{
            !!selectedCount
              ? `${item.text} for ${selectedCount} ${
                  selectedCount > 1 ? page : pageSingular
                }`
              : `${item.text}`
          }}
        </template>
      </v-select>
    </v-col>
    <v-col cols="5" class="py-0 d-flex align-center">
      <v-btn color="primary" text @click="confirmAction()">
        Confirm
      </v-btn>
    </v-col>

    <v-dialog
      v-model="dialog"
      max-width="400"
      @keydown.esc="dialog = false"
      v-if="fieldToChange"
    >
      <ActionDialog
        :submit="submit"
        :close="() => (dialog = false)"
        :loading="loading"
        :selectedCount="selectedCount"
        :types="fieldToChange.types"
        :field="fieldToChange"
        :exportOptions="filteredExportOptions"
      >
        <template v-slot="{ exportValue, pdfInfo, includeLines }">
          <v-btn
            text
            :disabled="!exportValue"
            :loading="loading"
            color="green"
            @click="() => handleExportClick(exportValue, pdfInfo, includeLines)"
            v-if="
              fieldToChange.value.label == 'export' ||
                fieldToChange.value.label == 'print'
            "
          >
            <v-icon left>{{
              fieldToChange.value.label == 'export'
                ? 'mdi-cloud-download'
                : 'mdi-file-pdf-box'
            }}</v-icon
            >{{
              fieldToChange.value.label == 'export' ? 'Export' : 'Print (pdf)'
            }}
          </v-btn>
          <download-csv
            class="btn btn-default"
            :data="exportData"
            :name="filename"
            v-on:export-finished="exported"
            v-if="fieldToChange.value.label == 'export'"
            style="display: none"
            ref="downloadCsv"
          >
          </download-csv>
        </template>
      </ActionDialog>
    </v-dialog>
  </v-row>
</template>

<script>
import ActionDialog from './ActionDialog'
import makeReportPdf from './makeReportPdf'
import { makePdfDoc } from '@/services/mixins/makePdfDoc'
export default {
  mixins: [makeReportPdf],
  components: {
    ActionDialog
  },
  props: {
    page: {
      type: String,
      required: true
    },
    isLines: {
      type: Boolean,
      required: false,
      default: false
    },
    selectedCount: {
      type: Number,
      required: true
    },
    fieldsToChange: {
      type: Array,
      required: true
    },
    updateFieldToChange: {
      type: Function,
      required: false
    },
    exportOptions: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      pageSingular: this.page.substring(0, this.page.length - 1),
      dialog: false,
      fieldToChange: null,
      status: 'ENTERED',
      statusItems: [
        'ENTERED',
        'COMMITED',
        'REVIEW',
        'APPROVED',
        'SENT',
        'CLOSED'
      ],
      notes: '',
      loading: false,
      isExported: false,
      exportData: [],
      filename: ''
    }
  },
  computed: {
    selected: {
      get() {
        return this.$store.state[this.page][this.storeProp]
      },
      set(val) {
        this.$store.commit(`${this.page}/setState`, {
          prop: this.storeProp,
          value: val
        })
      }
    },
    selectedAll() {
      const selectedAllProp = this.isLines ? 'selectedAllLines' : 'selectedAll'
      return this.$store.state[this.page][selectedAllProp]
    },
    storeProp() {
      return this.isLines ? 'selectedLines' : 'selected'
    },
    filteredExportOptions() {
      if (this.fieldToChange.value.label == 'print')
        return this.exportOptions.filter(({ format }) => !format)
      return this.exportOptions
    }
  },
  methods: {
    async submit(altAction, notes) {
      if (
        !this.selectedCount &&
        this.fieldToChange &&
        !this.fieldToChange.selectedNotRequired
      ) {
        this.snack({ color: 'yellow', text: `No ${this.page} are selected!` })
        this.dialog = false
        return
      }
      if (!this.fieldToChange) {
        this.snack({ color: 'yellow', text: 'No action selected' })
        this.dialog = false
        return
      }
      if (this.fieldToChange.value.label !== 'export') {
        const open = this.selected.filter(({ closed }) => !closed)
        if (open.length != this.selected.length) {
          this.snack({
            color: open.length ? 'green' : 'yellow',
            text: open.length
              ? 'NOTE: Closed records were not modified!'
              : 'No open records selected'
          })
          this.selected = open
          if (!open.length) {
            this.dialog = false
            return
          }
        }
      }
      this.loading = true
      altAction
        ? await altAction(notes)
        : await this.fieldToChange.action(this.selected)
      this.loading = false
      this.dialog = false
    },
    clearFieldToChange() {
      this.fieldToChange = {}
    },
    confirmAction() {
      if (
        !this.selectedCount &&
        this.fieldToChange &&
        !this.fieldToChange.selectedNotRequired
      ) {
        this.snack({ color: 'yellow', text: `No ${this.page} are selected!` })
        this.dialog = false
        return
      }
      if (!this.fieldToChange) {
        this.snack({ color: 'yellow', text: 'No action was selected!' })
        this.dialog = false
        return
      }
      this.dialog = true
    },
    exported() {
      this.isExported = true
      this.dialog = false
      setTimeout(() => {
        this.isExported = false
      }, 3 * 1000)
    },
    async fetchData(exportValue) {
      const data = []
      const result = await exportValue.fetchData()
      if (exportValue.format) {
        for (const item of result) {
          const obj = exportValue.format(item)
          data.push(obj)
        }
      } else if (exportValue.multiPdf) {
        return result
      } else {
        for (const item of result) {
          const obj = this.formatExportRowFromKeys(item, exportValue.keys)
          data.push(obj)
        }
      }
      return data
    },
    async handleExportClick(exportValue, pdfInfo, includeLines) {
      this.loading = true
      const data = await this.fetchData(exportValue)
      if (data.length) {
        if (this.fieldToChange.value.label == 'export') {
          const res = await this.setExportData({ name: exportValue.name, data })
          if (res) this.$refs.downloadCsv.$el.click()
        } else if (this.fieldToChange.value.label == 'print') {
          if (exportValue.multiPdf) {
            const linesData = includeLines
              ? await this.fetchData(exportValue.includeLinesExportValue)
              : []
            let i = 0
            for (const line of data) {
              setTimeout(
                // Without this limited to 10 downloads in Chrome
                async () => {
                  makePdfDoc({
                    pdfData: line.pdfData,
                    docHeading: line.docHeading,
                    docSubheading: line.docSubheading,
                    columns: line.columns,
                    columnStyles: line.columnStyles,
                    foot: line.foot,
                    pdfName: line.pdfName
                  })
                  if (includeLines)
                    await this.printLinesReport(line, linesData, exportValue)
                },
                i * 400
              )
              i++
            }
          } else {
            await this.printReport(exportValue, pdfInfo, data)
          }
        }
      }
      this.loading = false
      this.dialog = false
    },
    async printReport(exportValue, pdfInfo, data) {
      const res = await this.setPdfData({
        header: {
          name: exportValue.name,
          pdfTitle: pdfInfo.title,
          pdfType: pdfInfo.type,
          orientation: pdfInfo.orientation.charAt(0).toLowerCase(),
          pageSize: pdfInfo.pageSize.toLowerCase()
        },
        data,
        keys: exportValue.keys
      })
      if (res) this.makePdf(res)
    },
    async printLinesReport(line, linesData, exportValue) {
      const docId = line.pdfData.id
      const parentType = this.$store.state.global.pages[this.$route.name]
        .titleSingular
      const type = `${this.$store.state.global.pages.docket.titleSingular.toLowerCase()} lines`
      const title = [
        `${this.$store.state.global.pages.docket.titleSingular} lines for ${parentType}: ${docId}`,
        line.pdfData.subjectName
      ]
      const filteredLinesData = linesData.filter(
        item => item[parentType] == docId
      )
      const linesPdfInfo = {
        title,
        type,
        orientation: 'landscape',
        pageSize: 'A3'
      }
      const filename = `${parentType.toLowerCase()}_${docId}_${
        exportValue.includeLinesExportValue.name
      }`
      await this.printReport(
        {
          ...exportValue.includeLinesExportValue,
          name: filename
        },
        linesPdfInfo,
        filteredLinesData
      )
    },

    setExportData({ name, data }) {
      this.filename = `${name}.csv`
      this.exportData = data
      return this.exportData
    },
    setPdfData({ header, data, keys }) {
      const columnStyles = {}
      for (const key of keys) {
        if (key.type == 'decimal' || key.type == 'curr')
          columnStyles[key.text] = { halign: 'right' }
        if (key.type == 'time') columnStyles[key.text] = { minCellWidth: 1.6 }
      }
      let total = 0
      let costTotal = 0
      data.forEach(item => {
        const sell = item['Total'] ? item['Total'] : item['Sell Total']
        const cost = item['Cost Total']
        if (sell) total += Number(sell)
        if (cost) costTotal += Number(cost)
      })
      return {
        title: header.pdfTitle ? header.pdfTitle : 'Report title',
        filename: `${header.name}.pdf`,
        type: header.pdfType ? header.pdfType : header.name,
        total: total ? this.addCommas(total.toFixed(2)) : null,
        costTotal: costTotal ? this.addCommas(costTotal.toFixed(2)) : null,
        orientation: header.orientation,
        pageSize: header.pageSize,
        footerText: this.globalSettings.footerText,
        lines: data,
        columnStyles
      }
    },
    formatExportRowFromKeys(item, keys) {
      const obj = {}
      for (const key of keys) {
        let value = item[key.value]
        if (key.value instanceof Array) {
          let keyObj = item[key.value[0]]
          for (const [i, val] of key.value.entries()) {
            if (i === 0) continue
            keyObj = keyObj[val]
          }
          value = keyObj
        }
        if (this.getObjectPathRootExists(key.value, item)) {
          value = this.getObjectPath(key.value, item)
        }
        const result =
          typeof value === 'object' && value !== null ? value.name : value
        obj[key.text] = result
      }
      return obj
    }
  }
}
</script>
