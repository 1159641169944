<template>
  <v-card flat class="card-outlined">
    <v-card-text class="pb-0 d-flex justify-space-between align-center">
      <v-row>
        <v-col cols="12" sm="10" class="py-0">
          <MultiActions
            v-if="actionFields.length"
            page="dockets"
            isLines
            :selectedCount="selectedCount"
            :fieldsToChange="actionFields"
            :exportOptions="exportOptions"
          />
        </v-col>
        <v-col cols="12" sm="2" class="py-0 d-flex justify-end align-center">
          <v-tooltip left transition="slide-x-transition">
            <template v-slot:activator="{ on }">
              <div v-on="on" class="py-3">
                <v-btn
                  text
                  icon
                  color="grey"
                  @click="chartDialog = !chartDialog"
                  class="mr-2"
                >
                  <v-icon>mdi-chart-box</v-icon>
                </v-btn>
              </div>
            </template>
            <span>View charts</span>
          </v-tooltip>
          <v-tooltip left transition="slide-x-transition">
            <template v-slot:activator="{ on }">
              <div v-on="on" class="py-3">
                <v-menu
                  v-if="userAuthClass"
                  bottom
                  left
                  transition="scale-transition"
                  max-height="600px"
                  origin="top right"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn text icon color="grey" v-on="on" class="mr-2">
                      <v-icon>mdi-check-circle</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="openAddRctisDialog">
                      <v-list-item-icon>
                        <v-icon>mdi-receipt</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          >Create
                          {{
                            $store.state.global.pages.rcti.title
                          }}</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="openAddBillsDialog">
                      <v-list-item-icon>
                        <v-icon>mdi-receipt</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          >Create
                          {{
                            $store.state.global.pages.bill.title
                          }}</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>
            <span>
              Create {{ $store.state.global.pages.bill.title.toLowerCase() }} &
              {{ $store.state.global.pages.rcti.title.toLowerCase() }}
            </span>
          </v-tooltip>
          <ColumnSelector
            :defaultHeaders="defaultHeaders"
            :possibleHeaders="possibleHeaders"
            toolTipText="Column selector"
            :onChanged="val => (defaultHeaders = val)"
          />
        </v-col>
        <SelectAlert
          :selected="selected"
          :selectedAll="selectedAll"
          :totalRecordCount="count"
          :selectAll="() => (selectedAll = true)"
          :unselectAll="() => ((selectedAll = false), (selected = []))"
        />
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="docketLines"
      :loading="loadingLines"
      :options.sync="pagination"
      :server-items-length="count"
      mobile-breakpoint="0"
      :footer-props="{
        'items-per-page-options': [10, 20, 40, 50],
        showFirstLastPage: true
      }"
      v-model="selected"
      show-select
      dense
    >
      <template v-slot:no-data>
        No matching records found
      </template>
      <template v-slot:loading>
        Loading records...
      </template>
      <template v-slot:item="{ item, isSelected }">
        <Row
          :headers="headers"
          :item="item"
          :key="item.id"
          showEdit
          :value="isSelected"
          @input="toggleSelected"
          select
          defaultDialog="detailsDialog"
          editDialogFullscreen
          detailsDialogFullscreen
        >
          <template v-slot:details="{ dialog, close, openEditDialog }">
            <DocketDetails
              v-if="dialog"
              :dialog="dialog"
              :item="item.docket"
              :loading="loading"
              :close="close"
              :openCredit="openCredit"
              :openEditDialog="openEditDialog"
              :selectedId="item.id"
            />
          </template>
          <template v-slot:edit="{ dialog, close }">
            <DocketEdit
              v-if="dialog"
              :dialog="dialog"
              :item="item.docket"
              :loading="loading"
              :action="editDocket"
              :close="close"
            />
          </template>
          <template v-slot:delete="{ dialog, close }">
            <DeleteDialog
              :dialog="dialog"
              :page="`${titleSingular} Line`"
              :subject="item"
              :action="deleteDocketLine"
              :close="close"
            />
          </template>
        </Row>
      </template>
      <template
        v-slot:footer[`page-text`]="{ pageStart, pageStop, itemsLength }"
      >
        <div class="text-center mt-1">
          <span v-if="itemsLength">
            {{ `${pageStart}-${pageStop} of ${itemsLength}` }}
          </span>
          <span v-else> - </span>
          <v-progress-linear
            v-if="loadingLines && itemsLength"
            size="15"
            indeterminate
            color="primary"
          ></v-progress-linear>
          <div v-else style="height:5px"></div>
        </div>
      </template>
    </v-data-table>
    <Totals :totals="totals" :loading="loadingTotals" />
    <v-dialog
      v-model="chartDialog"
      @click:outside="() => (chartDialog = false)"
      @keydown.esc="() => (chartDialog = false)"
      fullscreen
    >
      <chart :close="() => (chartDialog = false)" />
    </v-dialog>
    <v-dialog
      v-model="addRctisDialog"
      @click:outside="() => (addRctisDialog = false)"
      @keydown.esc="() => (addRctisDialog = false)"
      max-width="900px"
    >
      <RctiAdd
        :dialog="addRctisDialog"
        :projects="projects"
        :close="() => (addRctisDialog = false)"
      />
    </v-dialog>
    <v-dialog
      v-model="addBillsDialog"
      @click:outside="() => (addBillsDialog = false)"
      @keydown.esc="() => (addBillsDialog = false)"
      max-width="900px"
    >
      <BillAdd
        :dialog="addBillsDialog"
        :projects="projects"
        :close="() => (addBillsDialog = false)"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import {
  getDocketLines,
  updateDocket,
  deleteDocketLine
} from '@/services/requests/dockets'
import Store from '@/store/computed/storeHelpers'
import getDocketLinesMixin from '@/services/mixins/getTransactionFiles/getDocketLines'
import getDocketTotalsMixin from '@/components/dockets/mixins/getTotals'
import Headers from '@/components/dockets/mixins/docketLineHeaders'
import HeaderFilters from '@/services/mixins/headerFilters'
import MultipleRecords from '@/services/mixins/multipleRecords'
import Docket from '@/services/models/docket'
import DocketLine from '@/services/models/docketLine'
import DocketLineExport from '@/components/dockets/mixins/docketLineExport'

import Row from '@/components/misc/row/Row'
import Totals from '@/components/dockets/components/Totals'
import DocketDetails from '@/components/dockets/DocketDetails'
import DocketEdit from '@/components/dockets/DocketEdit'
import DeleteDialog from '@/components/misc/DeleteDialog'
import ColumnSelector from '@/components/misc/shared/ColumnSelector'
import MultiActions from '@/components/misc/shared/MultiActions'
import SelectAlert from '@/components/misc/shared/SelectAlert'
import RctiAdd from '@/components/rctis/RctiAdd'
import BillAdd from '@/components/bills/BillAdd'
import Chart from '@/components/misc/shared/Chart'

import { debounce } from 'lodash'
export default {
  mixins: [
    getDocketLinesMixin,
    getDocketTotalsMixin,
    Headers,
    HeaderFilters,
    MultipleRecords,
    DocketLineExport
  ],
  components: {
    Row,
    Totals,
    DocketDetails,
    DocketEdit,
    DeleteDialog,
    ColumnSelector,
    MultiActions,
    SelectAlert,
    RctiAdd,
    BillAdd,
    Chart
  },
  props: {
    openCredit: {
      type: Function,
      required: true
    },
    projects: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      loading: false,
      addRctisDialog: false,
      addBillsDialog: false,
      chartDialog: false
    }
  },
  computed: {
    showClosed: Store.getSet({ store: 'global', prop: 'showClosed' }),
    showAutoCreated: Store.getSet({ store: 'global', prop: 'showAutoCreated' }),
    selectedContractorId: Store.getSet({
      store: 'global',
      prop: 'selectedContractorId'
    }),
    selectedDriverId: Store.getSet({
      store: 'global',
      prop: 'selectedDriverId'
    }),
    selectedVehicleId: Store.getSet({
      store: 'global',
      prop: 'selectedVehicleId'
    }),
    selectedDestinationId: Store.getSet({
      store: 'global',
      prop: 'selectedDestinationId'
    }),
    selectedSourceId: Store.getSet({
      store: 'global',
      prop: 'selectedSourceId'
    }),
    selectedBillingPeriod: Store.getSet({
      store: 'global',
      prop: 'selectedBillingPeriod'
    }),
    selectedProjectId: Store.getSet({
      store: 'global',
      prop: 'selectedProjectId'
    }),
    selectedUserId: Store.getSet({ store: 'global', prop: 'selectedUserId' }),
    dateRangeStart: Store.getSet({ store: 'global', prop: 'dateRangeStart' }),
    dateRangeEnd: Store.getSet({ store: 'global', prop: 'dateRangeEnd' }),
    filterCol: Store.getSet({ store: 'dockets', prop: 'filterCol' }),
    filterBy: Store.getSet({ store: 'dockets', prop: 'filterBy' }),
    selected: Store.getSet({ store: 'dockets', prop: 'selectedLines' }),
    selectedAll: Store.getSet({ store: 'dockets', prop: 'selectedAllLines' }),
    selectedCount() {
      return this.selectedAll ? this.count : this.selected.length
    },
    actionFields() {
      const authLimitedActions = [
        {
          text: 'GPS data',
          icon: 'mdi-crosshairs-gps',
          color: 'green',
          value: { value: null, text: 'GPS data', label: 'update' },
          types: this.getTypes('GPSConfirmed', 'GPSNotes', 'mdi-crosshairs-gps')
        },
        {
          text: 'Tipping docket data',
          icon: 'mdi-delete-sweep',
          color: 'green',
          value: { value: null, text: 'Tipping docket data', label: 'update' },
          types: this.getTypes(
            'tippingDocketConfirmed',
            'tippingDocketNotes',
            'mdi-delete-sweep'
          )
        },
        {
          text: 'Mass declaration data',
          icon: 'mdi-basket',
          color: 'green',
          value: {
            value: null,
            text: 'Mass declaration data',
            label: 'update'
          },
          types: this.getTypes(
            'massDeclarationConfirmed',
            'massDeclarationNotes',
            'mdi-basket'
          )
        },
        {
          text: 'Delete selected',
          icon: 'mdi-delete',
          color: 'red',
          value: { value: null, text: 'Delete selected', label: 'delete' },
          action: async () => {
            const result = await this.deleteMultipleRecords({
              ids: this.selected.map(({ id }) => id),
              table: 'dockets',
              isLine: true,
              query: this.selectedAll ? this.buildQuery() : null
            })
            if (result) {
              this.snack({
                text: `${result.ids.length} ${this.titleSingular} lines deleted, ${result.closedIds.length} ${this.titleSingular} lines closed`,
                color: 'green'
              })
            }
          }
        }
      ]
      let actions = [
        {
          text: 'Export selected',
          icon: 'mdi-file-export',
          color: 'grey',
          value: { value: null, text: 'Export selected', label: 'export' }
        },
        {
          text: 'Print selected',
          icon: 'mdi-file-pdf-box',
          color: 'grey',
          value: { value: null, text: 'Print selected', label: 'print' }
        }
      ]
      if (this.userAuthClass) actions = [...actions, ...authLimitedActions]
      return actions
    }
  },
  watch: {
    docketLines() {
      this.selected = []
      this.selectedAll = false
    },
    showClosed() {
      this.getLinesFromPage1()
    },
    selectedUserId() {
      this.getLinesFromPage1()
    },
    selectedBillingPeriod() {
      this.getLinesFromPage1()
    },
    showAutoCreated() {
      this.getLinesFromPage1()
    },
    selectedProjectId() {
      this.getLinesFromPage1()
    },
    selectedSourceId() {
      this.getLinesFromPage1()
    },
    selectedContractorId() {
      this.getLinesFromPage1()
    },
    selectedDriverId() {
      this.getLinesFromPage1()
    },
    selectedVehicleId() {
      this.getLinesFromPage1()
    },
    dateRangeStart() {
      this.getLinesFromPage1()
    },
    dateRangeEnd() {
      this.getLinesFromPage1()
    },
    selectedDestinationId() {
      this.getLinesFromPage1()
    },
    filterCol() {
      this.getLinesFromPage1()
    },
    filterBy: debounce(function() {
      this.getLinesFromPage1()
    }, 300)
  },
  methods: {
    getTypes(field, notesField, icon) {
      const typeStatuses = [
        { text: 'Confirm', value: 'CONFIRMED', color: 'green' },
        { text: 'Review', value: 'REVIEW', color: 'amber' },
        { text: 'Unconfirm', value: 'NOT SET', color: 'grey' }
      ]
      const types = []
      for (const status of typeStatuses) {
        const payload = {
          table: 'dockets/lines',
          field,
          notesField,
          value: status.value,
          query: this.selectedAll ? this.buildQuery() : null,
          model: DocketLine,
          prop: 'lines',
          store: 'dockets'
        }
        types.push({
          text: status.text,
          icon,
          color: status.color,
          value: { value: null, text: status.text, label: 'update' },
          action: async notes =>
            await this.updateMultipleRecords({
              ...payload,
              items: this.selected,
              notes
            })
        })
      }
      return types
    },
    getLinesFromPage1() {
      this.pagination.page = 1
      this.getDocketTotals()
      this.getDocketLines()
    },
    toggleSelected(val) {
      if (val.value) {
        this.selected.push(val.object)
      } else {
        var index = this.selected.indexOf(val.object)
        this.selected.splice(index, 1)
      }
    },
    toggleSelectAll({ items }) {
      if (this.selected.length == 0) {
        // const openItems = items.filter(({ closed }) => !closed)
        this.selected = items
      } else {
        this.selected = []
      }
    },
    async editDocket(docket) {
      try {
        this.loading = true
        const payload = {
          date: docket.date,
          dateReceived: docket.dateReceived,
          sourceId: docket.sourceId,
          vehicleId: docket.vehicleId,
          driverId: docket.driverId,
          externalRef: docket.externalRef,
          notes: docket.notes,
          start: docket.start,
          end: docket.end,
          billingPeriod: docket.billingPeriod,
          type: docket.type
        }
        const result = await updateDocket(docket.id, payload)
        if (result) {
          this.$store.commit('dockets/updateArrayStateById', {
            prop: 'dockets',
            editedValue: new Docket(result.data.result)
          })
          for (const line of result.data.lines) {
            this.$store.commit('dockets/updateArrayStateById', {
              prop: 'lines',
              editedValue: new DocketLine(line)
            })
          }
          this.snackUpdated()
        }
        this.loading = false
        return result
      } catch (err) {
        this.loading = false
        console.log(err)
      }
    },
    async deleteDocketLine(line) {
      try {
        this.loading = true
        const result = await deleteDocketLine(line.id)
        if (result) {
          this.$store.commit('dockets/deleteFromArrayStateByValue', {
            prop: 'lines',
            value: line
          })
          this.snackDeleted()
        }
        this.loading = false
        return result
      } catch (err) {
        this.loading = false
        console.log(err)
      }
    },
    async getAllSelected() {
      try {
        const payload = this.buildQuery()
        const result = await getDocketLines(payload)
        return result.data.result
      } catch (err) {
        console.log(err)
      }
    },
    openAddRctisDialog() {
      if (!this.selectedProjectId || !this.selectedBillingPeriod) {
        this.snack({
          color: 'yellow',
          text: 'You must select an open billing period and a project!'
        })
        return
      } else {
        this.addRctisDialog = true
      }
    },
    openAddBillsDialog() {
      if (!this.selectedProjectId || !this.selectedBillingPeriod) {
        this.snack({
          color: 'yellow',
          text: 'You must select an open billing period and a project!'
        })
        return
      } else {
        this.addBillsDialog = true
      }
    }
  }
}
</script>
