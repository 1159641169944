import Store from '@/store/computed/storeHelpers'
export default {
  computed: {
    defaultHeaders: Store.getSet({
      store: 'compliances',
      prop: 'complianceDefaultHeaders'
    })
  },
  data() {
    return {
      mobileHeaders: [
        {
          text: 'ID',
          value: 'id'
        },
        { text: 'Type', value: 'type', type: 'compliance' },
        {
          text: this.$store.state.global.pages.driver.titleSingular,
          value: 'driver.name'
        }
      ],
      allPossibleHeaders: [
        {
          text: 'ID',
          value: 'id'
        },
        { text: 'Expiry Date', value: 'expiryDate', type: 'date' },
        { text: 'Type', value: 'type', type: 'compliance' },
        {
          text: 'Days remaining',
          value: 'daysTillExpired',
          sortable: false,
          align: 'right'
        },
        { text: 'Belongs To', sortable: false, value: 'belongsTo' },
        { text: 'Masterfile', value: 'model' },
        {
          text: 'Notes',
          align: 'left',
          value: 'notes',
          type: 'text'
        },
        {
          text: 'Active',
          value: 'active',
          sortable: false,
          type: 'boolean'
        },
        { text: 'Entered By', value: 'createdBy.name' },
        { text: 'Updated By', value: 'updatedByName' },
        { text: 'Created At', value: '_createdAt', type: 'date' },
        { text: 'Updated At', value: '_updatedAt', type: 'date' }
      ]
    }
  }
}
