import Store from '@/store/computed/storeHelpers'
import { getSourcesLite } from '../../../requests/sources'
export default {
  mounted() {
    this.getSourcesLite()
  },
  data() {
    return {
      loadedSourcesLite: false
    }
  },
  computed: {
    sourcesLite: Store.getSet({ store: 'sources', prop: 'sourcesLite' }),
    loadingSourcesLite: Store.getSet({
      store: 'sources',
      prop: 'loadingSourcesLite'
    })
  },
  methods: {
    async getSourcesLite() {
      try {
        this.loadingSourcesLite = true
        const result = await getSourcesLite()
        if (result) {
          this.sourcesLite = !this.selectedProjectId
            ? result.data.result
            : result.data.result.filter(({ projectId }) => {
                projectId == this.selectedProjectId
              })
        }
        this.loadedSourcesLite = true
        this.loadingSourcesLite = false
      } catch (err) {
        console.log(err)
        this.loadingSourcesLite = false
      }
    }
  }
}
