<template>
  <v-form ref="form" @submit.prevent="submit()">
    <v-card flat class="card-outlined">
      <v-card-text class="pb-0">
        <v-card-title class="pa-0 font-weight-regular">
          {{ `Add ${titleSingular}` }}
        </v-card-title>
        <v-row>
          <v-col cols="12" sm="6">
            <v-select
              :items="models"
              label="* Masterfile"
              v-model="model"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              :items="resourceTypes"
              label="* Resource Type"
              v-model="resourceType"
              :disabled="!model"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              :items="subjects"
              :item-text="subjectLabel"
              item-value="id"
              :label="`* Select ${model ? model : 'model'}`"
              v-model="subjectId"
              :disabled="!model"
            ></v-autocomplete>
          </v-col>
          <input
            type="file"
            ref="fileUpload"
            style="display:none"
            @change="onFileChange"
          />
          <v-col cols="12" sm="6">
            <v-text-field
              :label="`* Select file`"
              readonly
              :value="!!file ? file.name : ''"
              @click="openFileDialog"
              color="primary"
              :loading="loading"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <DatePickerWrapper label="* Date" v-model="expiryDate" />
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Notes"
              v-model="notes"
              color="primary"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="grey darken-1" @click="cancel">Cancel</v-btn>
        <v-btn text color="primary" type="submit" :loading="loading">
          Add
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { addCompliance } from '../../services/requests/compliances'
import Compliance from '../../services/models/compliance'
import models from './mixins/models'
import DatePickerWrapper from '../misc/fields/DatePickerWrapper'
export default {
  mixins: [models],
  components: { DatePickerWrapper },
  props: {
    toggleAdd: {
      type: Function,
      required: true
    },
    contractors: {
      type: Array,
      required: true
    },
    vehicles: {
      type: Array,
      required: true
    },
    drivers: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      model: null,
      resourceType: null,
      subjectId: null,
      file: null,
      expiryDate: null,
      notes: null,
      resourceTypes: [],
      loading: false
    }
  },
  watch: {
    model() {
      this.getResourceTypes()
      this.resourceType = null
      this.subjectId = null
    }
  },
  computed: {
    subjects() {
      if (this.model) return this[`${this.model}s`]
      return []
    },
    subjectLabel() {
      if (this.model == 'vehicle')
        return 'vehicleName_contractorName_vehicleType'
      if (this.model == 'driver') return 'driverName_contractorName'
      return 'name'
    }
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) {
        this.snackFormError()
        return
      }
      this.loading = true
      try {
        const formData = new FormData()
        formData.append('type', this.resourceType)
        formData.append('expiryDate', this.expiryDate)
        formData.append('model', this.model)
        formData.append('file', this.file)
        formData.append('subjectId', this.subjectId)
        if (this.notes) formData.append('notes', this.notes)
        const result = await addCompliance(formData)
        if (result) {
          const compliance = new Compliance(result.data.result)
          this.$store.commit('compliances/addToArrayState', {
            prop: 'compliances',
            value: compliance
          })
          this.cancel()
          this.loading = false
          this.snackCreated()
        }
      } catch (err) {
        this.loading = false
        this.snack({ text: err.response.data.message, color: 'yellow' })
        console.log(err)
      }
    },
    openFileDialog() {
      this.$refs.fileUpload.click()
    },
    onFileChange() {
      this.file = {}
      this.file = this.$refs.fileUpload.files[0]
    },
    cancel() {
      this.toggleAdd()
      this.clear()
    },
    clear() {
      this.model = null
      this.resourceType = null
      this.subjectId = null
      this.file = null
      this.expiryDate = null
      this.notes = null
      this.$refs.fileUpload.value = ''
    }
  }
}
</script>
