import {
  getPlans,
  updatePlan,
  deletePlan,
  deletePlanLine
} from '../../../services/requests/plans'
import Plan from '../../../services/models/plan'
import PlanLine from '../../../services/models/planLine'

export default {
  methods: {
    toggleSelected(val) {
      if (val.value) {
        this.selected.push(val.object)
      } else {
        var index = this.selected.indexOf(val.object)
        this.selected.splice(index, 1)
      }
    },
    async editPlan(plan) {
      try {
        this.loading = true
        const payload = {
          reference: plan.reference,
          date: plan.date,
          isDay: plan.isDay,
          sourceId: plan.sourceId,
          notes: plan.notes,
          planVehicles: plan.planVehicles
        }
        const result = await updatePlan(plan.id, payload)
        if (result) {
          this.$store.commit('plans/updateArrayStateById', {
            prop: 'plans',
            editedValue: new Plan(result.data.result)
          })
          if (result.data.lines.length) {
            for (const line of result.data.lines) {
              this.$store.commit('plans/updateArrayStateById', {
                prop: 'lines',
                editedValue: new PlanLine(line)
              })
            }
          }

          if (this.events) this.getEvents()
          this.snackUpdated()
        }
        this.loading = false
        return result
      } catch (err) {
        this.loading = false
        console.log(err)
      }

      // return updatePlan(plan.id, payload)
      //   .then(result => {
      //     this.$store.commit('plans/updateArrayStateById', {
      //       prop: 'plans',
      //       editedValue: new Plan(result.data.result)
      //     })
      //     if (result.data.lines.length) {
      //       for (const line of result.data.lines) {
      //         this.$store.commit('plans/updateArrayStateById', {
      //           prop: 'lines',
      //           editedValue: new PlanLine(line)
      //         })
      //       }
      //     }

      //     if (this.events) this.getEvents()
      //     this.loading = false
      //     this.snackUpdated()
      //   })
      //   .catch(err => {
      //     this.loading = false
      //     console.log(err)
      //   })
    },
    async deletePlan(plan) {
      // THIS IS NOT USED ANYWHERE
      try {
        this.loading = true
        const result = deletePlan(plan.id)
        if (result) {
          this.$store.commit('plans/deleteFromArrayStateByValue', {
            prop: 'plans',
            value: plan
          })
          this.snackDeleted()
        }
        this.loading = false
        return result
      } catch (err) {
        this.loading = false
        console.log(err)
      }

      // return deletePlan(plan.id)
      //   .then(() => {
      //     this.$store.commit('plans/deleteFromArrayStateByValue', {
      //       prop: 'plans',
      //       value: plan
      //     })
      //     this.loading = false
      //     this.snackDeleted()
      //   })
      //   .catch(err => {
      //     this.loading = false
      //     console.log(err)
      //   })
    },
    async deletePlanLine(line) {
      console.log('delete plan line')
      try {
        this.loading = true
        const result = await deletePlanLine(line.id)
        if (result) {
          this.$store.commit('plans/deleteFromArrayStateByValue', {
            prop: 'lines',
            value: line
          })
          this.snackDeleted()
        }
        this.loading = false
        return result
      } catch (err) {
        this.loading = false
        console.log(err)
      }

      // return deletePlanLine(line.id)
      //   .then(() => {
      //     this.$store.commit('plans/deleteFromArrayStateByValue', {
      //       prop: 'lines',
      //       value: line
      //     })
      //     this.loading = false
      //     this.snackDeleted()
      //   })
      //   .catch(err => {
      //     this.loading = false
      //     console.log(err)
      //   })
    },
    async getAllSelected() {
      try {
        const payload = this.buildQuery()
        const result = await getPlans(payload)
        return result.data.result
      } catch (err) {
        console.log(err)
      }
    }
  }
}
