import Mutations from '@/store/data/mutations'
export default {
  namespaced: true,
  state: {
    contractors: [],
    contractorsLite: [],
    contractorId: null,
    driverId: null,
    adding: false,
    loadingContractors: false,
    loadingContractorsLite: false
  },
  mutations: Mutations,
  actions: {},
  getters: {}
}
