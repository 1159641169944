<template>
  <!-- <v-img
    class="bg-image"
    src="https://images.pexels.com/photos/358622/pexels-photo-358622.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
    cover
    height="100vh"
    gradient="to top right, rgba(49, 51, 63, 0.64), rgba(25,32,72,.9)"
  > -->
  <v-container fill-height>
    <v-row class="fill-height">
      <v-col cols="12"> </v-col>
      <v-col cols="12" class="d-flex justify-space-around align-center">
        <v-container>
          <v-row>
            <v-col cols="12" class="d-flex justify-center align-center">
              <v-img
                src="@/assets/payloader-large1.png"
                max-width="70"
                contain
              ></v-img>
              <div class="text-h4 grey--text text--lighten-1 text-center">
                ayloader
              </div>
            </v-col>
            <v-col cols="12" class="d-flex justify-center mt-3">
              <v-hover v-slot:default="{ hover }">
                <v-card
                  flat
                  class="card-outlined"
                  width="350"
                  :elevation="hover ? 3 : 0"
                  :class="{ 'on-hover': hover }"
                >
                  <v-card-text>
                    <v-window class="text-center" v-model="step">
                      <LandingForm
                        :onSubmit="login"
                        :toggleStep="() => step++"
                        title="Login"
                        toggleText="Sign Up!"
                        :step="1"
                        refName="loginForm"
                        :loading="loading"
                        :fields="loginFields"
                      />
                      <LandingForm
                        :onSubmit="register"
                        :toggleStep="() => step--"
                        title="Register"
                        toggleText="Have an account?"
                        :step="2"
                        refName="registerForm"
                        :loading="loading"
                        :fields="registerFields"
                      />
                      <LandingForm
                        :onSubmit="login"
                        :toggleStep="() => (step = 1)"
                        title="Two-factor authentication"
                        toggleText="Back"
                        :step="3"
                        refName="codeForm"
                        :loading="loading"
                        :fields="codeFields"
                        submitText="Authenticate"
                      />
                    </v-window>
                  </v-card-text>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="12"></v-col>
    </v-row>
  </v-container>
  <!-- </v-img> -->
</template>

<script>
import Auth from '@/services/authentication'
import AuthValidation from '@/services/validation/authValidation'
import LandingForm from '@/components/misc/LandingForm'
export default {
  mixins: [AuthValidation],
  components: { LandingForm },
  data() {
    return {
      step: 1,
      email: '',
      name: '',
      password: '',
      confirmedPassword: '',
      loading: false,
      showSnackbar: false,
      snackbarText: null,
      snackbarColor: null,
      email2FA: null,
      password2FA: null
    }
  },
  computed: {
    loginFields() {
      return {
        email: { label: 'Email', value: '', rules: this.emailRules },
        password: {
          label: 'Password',
          value: '',
          rules: this.passwordRules
        }
      }
    },
    registerFields() {
      return {
        email: { label: 'Email', value: '', rules: this.emailRules },
        name: { label: 'Name', value: '' },
        password: {
          label: 'Password',
          value: '',
          rules: this.passwordRules
        },
        confirmedPassword: {
          label: 'Confirm Password',
          value: '',
          rules: this.passwordRules
        }
      }
    },
    codeFields() {
      return {
        code: {
          label: '2FA Code',
          value: ''
        }
      }
    }
  },
  methods: {
    login(form, fields) {
      if (!form.validate()) {
        this.snackFormError()
        return
      }
      this.loading = true
      const payload =
        this.step == 3
          ? {
              email: this.email2FA,
              password: this.password2FA,
              code: fields.code.value
            }
          : {
              email: fields.email.value,
              password: fields.password.value
            }
      Auth.login(payload)
        .then(async result => {
          if (result.data.require2FA) {
            this.step = 3
            this.email2FA = fields.email.value
            this.password2FA = fields.password.value
            this.loading = false
            return
          }
          this.setLocalStorageTokens(
            result.data.token,
            result.data.refreshToken
          )
          this.$router.push({
            name: 'dashboard'
          })
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.snack({
            text: `${err.response.data.message} (${err.response.status}) `,
            color: 'amber'
          })
        })
    },
    register(form, fields) {
      if (!form.validate()) {
        this.snackFormError()
        return
      }
      if (fields.password.value !== fields.confirmedPassword.value) {
        this.snack({ text: 'Passwords do not match', color: 'error' })
        return
      }
      this.loading = true
      Auth.register({
        email: fields.email.value,
        name: fields.name.value,
        password: fields.password.value,
        userAuthClassId: 1
      })
        .then(async result => {
          await this.setLocalStorageTokens(
            result.data.token,
            result.data.refreshToken
          )
          this.$router.push({
            name: 'dashboard'
          })
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
          this.snack({
            text: `${err.response.data.message} (${err.response.status}) `,
            color: 'error'
          })
        })
    },
    setLocalStorageTokens(token, refreshToken) {
      localStorage.setItem('accessToken', token)
      localStorage.setItem('refreshToken', refreshToken)
    }
  }
}
</script>
