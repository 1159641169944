<template>
  <v-form ref="form" @submit.prevent="submit()">
    <v-row class="pt-5">
      <v-col cols="12" class="pb-0">
        <v-card-subtitle class="pa-0 font-weight-bold">
          Add Policy
        </v-card-subtitle>
      </v-col>
      <v-col cols="3" class="d-flex align-center">
        <v-text-field
          :disabled="!$store.state.user.userAuthClass.policyWrite"
          label="Name"
          v-model="name"
          :rules="nameRules"
          hide-details
        ></v-text-field>
      </v-col>
      <input
        :disabled="!$store.state.user.userAuthClass.policyWrite"
        type="file"
        ref="fileUpload"
        style="display:none"
        @change="onFileChange"
      />
      <v-col cols="3">
        <v-text-field
          :disabled="!$store.state.user.userAuthClass.policyWrite"
          label="Select File"
          color="primary"
          prepend-icon="mdi-paperclip"
          readonly
          :value="typeof file == 'object' ? file.name : ''"
          v-model="file.name"
          @click="openFileDialog"
          :loading="loading"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="6" class="d-flex align-center justify-end">
        <v-btn
          :disabled="!$store.state.user.userAuthClass.policyWrite"
          text
          color="primary"
          type="submit"
          :loading="loading"
        >
          Add
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col cols="12" v-if="policies.length">
        <v-card flat class="card-outlined">
          <v-list>
            <PolicyListItem
              v-for="item in policies"
              :key="item.id"
              :item="item"
              :loading="loading"
              :onEdit="editPolicy"
              :deletePolicy="deletePolicy"
            />
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import {
  addPolicy,
  updatePolicy,
  deletePolicy
} from '../../services/requests/policies'
import { uploadFile } from '../../services/requests/s3Service'
import Auth from '../../services/authentication'
import PolicyValidation from '../../services/validation/policy'
import getPolicies from '../../services/mixins/getMasterFiles/getPolicies'
import MasterFileCrudFunctions from '../../services/mixins/masterFileCrudFunctions'
import PolicyListItem from './components/PolicyListItem'

export default {
  mixins: [getPolicies, MasterFileCrudFunctions, PolicyValidation],
  components: { PolicyListItem },
  data() {
    return {
      name: null,
      prop: 'policies',
      loading: false,
      file: ''
    }
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) {
        this.snackFormError()
        return
      }
      try {
        const key = await this.uploadImage()
        if (!key) return
        await this.createPolicy({ name: this.name, key })
        this.name = null
        this.file = ''
      } catch (err) {
        console.log(err)
      }
    },
    async createPolicy(policy) {
      await this.createMasterFile({
        payload: policy,
        action: addPolicy,
        store: 'policies'
      })
    },
    async editPolicy(policy) {
      const payload = {
        name: policy.name,
        key: policy.key
      }
      await this.editMasterFile({
        id: policy.id,
        payload: payload,
        action: updatePolicy,
        store: 'policies'
      })
    },
    async deletePolicy(policy) {
      await this.deleteMasterFile({
        payload: policy,
        action: deletePolicy,
        store: 'policies'
      })
    },
    openFileDialog() {
      this.$refs.fileUpload.click()
    },
    onFileChange() {
      this.file = this.$refs.fileUpload.files[0]
    },
    async uploadImage() {
      if (!this.file) return
      const result = await Auth.getS3PutUrl({
        key: this.file.name,
        bucket: 'payloader-policies'
      })
      const { url, key } = result.data.result
      const file = await uploadFile(url, this.file)
      if (file) return key
    }
  }
}
</script>
>
