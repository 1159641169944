import ItemTypeValidation from '../../validation/itemType'
import getDestinationsLite from '../getMasterFiles/lite/getDestinationsLite'

export default {
  mixins: [ItemTypeValidation, getDestinationsLite],
  computed: {
    fields() {
      return [
        {
          label: '* Name',
          prop: 'name',
          value: null,
          rules: this.nameRules
        },
        {
          label: `${this.$store.state.global.pages.itemType.titleSingular} Category`,
          prop: 'type',
          clearable: true,
          value: null,
          sm: '6',
          items: ['SPL', 'TOL', 'INT']
        },
        {
          label: 'Volume (m3)',
          prop: 'volume',
          type: 'number',
          value: 0,
          sm: '6'
        },
        {
          label: 'Request',
          prop: 'request',
          value: false,
          sm: '6',
          type: 'boolean'
        },
        {
          label: `Allowed ${this.$store.state.global.pages.destination.titleSingular}s`,
          prop: 'allowedDestinations',
          items: this.destinationsLite,
          value: [],
          type: 'expansionCheckbox',
          panel: -1,
          icon: this.$store.state.global.pages.destination.icon
        }
      ]
    }
  }
}
