<template>
  <div>
    <h3>MASS</h3>
    <v-row>
      <v-col cols="12" sm="4">
        <v-text-field
          color="indigo"
          :label="
            `* ${$store.state.global.pages.destination.titleSingular} weight`
          "
          prepend-icon="mdi-shopping_basket"
          v-model.number="destinationWeight"
          ref="destinationWeight"
          :rules="destinationWeightRules"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          color="indigo"
          :label="`${$store.state.global.pages.source.titleSingular} weight`"
          prepend-icon="mdi-shopping_basket"
          v-model.number="sourceWeight"
          :rules="sourceWeightRules"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          color="indigo"
          :label="`${$store.state.global.pages.vehicle.titleSingular} weight`"
          prepend-icon="mdi-shopping_basket"
          v-model.number="vehicleWeight"
          :rules="vehicleWeightRules"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <span class="title" v-if="destinationWeight"
          >Total: {{ destinationWeight }} tonne</span
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    values: {
      type: Object,
      required: true
    },
    destinationWeightRules: {
      type: Array,
      required: true
    },
    sourceWeightRules: {
      type: Array,
      required: true
    },
    vehicleWeightRules: {
      type: Array,
      required: true
    },
    onChange: {
      type: Function,
      required: true
    },
    refKey: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      destinationWeight: 0,
      sourceWeight: 0,
      vehicleWeight: 0
    }
  },
  mounted() {
    for (const key of Object.keys(this.values)) {
      this[key] = this.values[key]
    }
  },
  watch: {
    refKey(val) {
      if (val) this.focusRef(val)
    },
    values() {
      for (const key of Object.keys(this.values)) {
        this[key] = this.values[key]
      }
    },
    destinationWeight(val) {
      this.onChange('destinationWeight', val)
    },
    sourceWeight(val) {
      this.onChange('sourceWeight', val)
    },
    vehicleWeight(val) {
      this.onChange('vehicleWeight', val)
    }
  },
  methods: {
    focusRef(ref) {
      this.$refs[ref].focus()
    }
  }
}
</script>
