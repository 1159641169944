export default {
  methods: {
    async setMainPages(useApprovedBilling) {
      const usersMainPages = this.$store.state.global.possibleMainPages.filter(
        page => {
          const condition =
            this.$store.state.user.userAuthClass[`${page.route}Read`] ||
            this.$store.getters['user/isAdmin']
          if (page.route === 'approvedBillLine' || page.route === 'bill') {
            const condition2 = useApprovedBilling
              ? page.route !== 'bill'
              : page.route !== 'approvedBillLine'
            return condition && condition2
          }
          return condition
        }
      )
      this.$store.commit('global/setState', {
        prop: 'mainPages',
        value: usersMainPages
      })
    },
    setMasterFilePages() {
      // console.log(this.$store.state.user.userAuthClass)
      const usersMasterFilePages = this.$store.state.global.possibleMasterFilePages.filter(
        page => {
          return this.$store.state.user.userAuthClass[`${page.route}Read`]
        }
      )
      this.$store.commit('global/setState', {
        prop: 'masterFilePages',
        value: usersMasterFilePages
      })
    },
    setSettingsPages() {
      const userSettingsPages = this.$store.state.global.possibleSettingsPages.filter(
        page => {
          return !!page
        }
      )
      this.$store.commit('global/setState', {
        prop: 'settingsPages',
        value: userSettingsPages
      })
    }
  }
}
