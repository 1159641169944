import Store from '@/store/computed/storeHelpers'
import { getUsers } from '../../requests/users'
export default {
  mounted() {
    if (this.pagination) this.initPagination()
    this.getUsers()
  },
  data() {
    return {
      loadedUsers: false,
      userCount: 0
    }
  },
  watch: {
    pagination: {
      deep: true,
      handler() {
        this.getUsers()
      }
    }
  },
  computed: {
    users: Store.getSet({ store: 'users', prop: 'users' }),
    loadingUsers: Store.getSet({
      store: 'users',
      prop: 'loadingUsers'
    })
  },
  methods: {
    async getUsers() {
      try {
        this.loadingUsers = true
        const payload =
          this.masterFile == 'user'
            ? { pagination: this.pagination, filterBy: this.filterBy }
            : {}
        const result = await getUsers(payload)
        if (result) {
          this.users = result.data.result
          this.userCount = result.data.count
        }
        this.loadedUsers = true
        this.loadingUsers = false
      } catch (err) {
        console.log(err)
        this.loadingUsers = false
      }
    }
  }
}
