<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-1">
        <span class="text-truncate">
          View {{ $store.state.global.pages.allocation.titleSingular }} line -
          <span class="pl-1 subtitle-1">
            ID:
            <span class="font-weight-bold">{{ item.id }}</span>
          </span>
        </span>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text class="pb-3 pt-3">
      <v-row class="subtitle-1">
        <Detail
          :icon="$store.state.global.pages.destination.icon"
          :text="item.destination.name"
          cols="12"
          sm="6"
          :tooltip="destinationTooltip"
        />
        <Detail
          :icon="$store.state.global.pages.itemType.icon"
          :text="item.itemType.name"
          cols="12"
          sm="6"
        />
      </v-row>
    </v-card-text>
    <v-card-text class="pb-0 pt-3" v-if="item.allocation">
      <v-row>
        <Detail
          :icon="$store.state.global.pages.driver.icon"
          :text="item.allocation.driver.name"
          cols="12"
          sm="6"
        />
        <Detail
          :icon="$store.state.global.pages.vehicle.icon"
          :text="
            `${item.allocation.vehicle.name}
            ${item.allocation.vehicle.vehicleType.name}
            (${item.allocation.vehicle.vehicleType.tonnage}t)`
          "
          cols="12"
          sm="6"
        />
      </v-row>
    </v-card-text>
    <v-divider class="mt-4"></v-divider>

    <PodTabs
      v-if="lines.length"
      :loading="loading"
      :timesheets="timesheets"
      :images="images"
      :forms="forms"
      :lines="lines"
      :podId="podId"
      :allPods="allPods"
    />
    <v-card-text v-else class="d-flex justify-center pa-5">
      No {{ $store.state.global.pages.pod.title }} found
    </v-card-text>
    <v-card-actions>
      <ShowAudits :auditData="auditData" />
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import { getLineWithPodsByLineId } from '../../../services/requests/allocations'
import FormatDates from '../../../services/mixins/formatDates'
import PodTabs from '../../pods/PodTabs'
import ShowAudits from '../../misc/shared/ShowAudits'
import Detail from '../../misc/Detail'

export default {
  mixins: [FormatDates],
  components: {
    PodTabs,
    ShowAudits,
    Detail
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    podId: {
      type: Number,
      required: false
    },
    dialog: {
      type: Boolean,
      required: true
    },
    close: {
      type: Function,
      required: true
    }
  },
  async mounted() {
    await this.getLines()
  },
  computed: {
    allPods() {
      return [...this.timesheets, ...this.images, ...this.forms]
    },
    auditData() {
      return { id: this.item.id, model: 'allocationLine' }
    },
    destinationTooltip() {
      const text = this.item.deliveryAddress
      const waypointText = this.item.waypointIndex
        ? `Waypoint: ${this.item.waypointIndex}`
        : null
      return waypointText ? `${text} - ${waypointText}` : text
    }
  },
  data() {
    return {
      lines: [],
      timesheets: [],
      images: [],
      forms: [],
      loading: false
    }
  },
  methods: {
    async getLines() {
      try {
        this.lines = []
        this.timesheets = []
        this.images = []
        this.forms = []
        this.loading = true
        const result = await getLineWithPodsByLineId(this.item.id)
        this.lines = result.data.result.pods
        for (const pod of this.lines) {
          if (pod.type === 'photo' || pod.type === 'signature')
            this.images.push(pod)
          else if (pod.type === 'timesheet') this.timesheets.push(pod)
          else if (pod.type === 'form') this.forms.push(pod)
        }
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>
