import Auth from '@/services/authentication'

export default {
  namespaced: true,
  state: {
    loading: false,
    accessToken: null,
    authenticated: false,
    user: {},
    userAuthClass: {}
  },
  mutations: {
    setState(state, payload) {
      state[payload.prop] = payload.value
    }
  },
  actions: {
    initApp(context) {
      const token = Auth.getTokenFromStorage()
      if (token) {
        Auth.useTokenForRequests(token)
        return Auth.checkAuth()
          .then(result => {
            const refreshToken = Auth.getRefreshTokenFromStorage()
            context.dispatch('setAuthenticated', {
              token: token,
              user: result.data.user,
              userDetails: result.data.userDetails,
              refreshToken: refreshToken
            })
            return true
          })
          .catch(err => {
            console.log(err)
            context.dispatch('setLogout')
            return false
          })
      } else {
        context.dispatch('setLogout')
        return false
      }
    },
    async setAuthenticated(context, payload) {
      await context.dispatch('setAccessTokens', {
        token: payload.token,
        refreshToken: payload.refreshToken
      })
      context.commit('setState', { prop: 'authenticated', value: true })
      payload.user.name = payload.userDetails.name
      context.commit('setState', { prop: 'user', value: payload.user })
      context.commit('setState', {
        prop: 'userAuthClass',
        value: payload.userDetails.userAuthClass
      })
      context.commit('setState', {
        prop: 'userSettings',
        value: payload.userDetails.userSetting
      })
    },
    setLogout(context) {
      context.dispatch('unsetAccessTokens')
      context.commit('setState', { prop: 'authenticated', value: false })
      context.commit('setState', { prop: 'user', value: {} })
      context.commit('setState', { prop: 'userAuthClass', value: {} })
      context.commit('setState', { prop: 'userSettings', value: {} })
    },
    setAccessTokens(context, tokens) {
      Auth.useTokenForRequests(tokens.token)
      localStorage.setItem('accessToken', tokens.token)
      localStorage.setItem('refreshToken', tokens.refreshToken)
      context.commit('setState', { prop: 'accessToken', value: tokens.token })
    },
    unsetAccessTokens(context) {
      Auth.dontUserTokenForRequests()
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      context.commit('setState', { prop: 'accessToken', value: null })
    },

    setLoading(context, bool) {
      context.commit('setState', { prop: 'setLoading', value: bool })
    }
  },
  getters: {
    getAuthenticated: state => state.authenticated,
    getUser: state => state.user,
    getLoading: state => state.loading,
    isAdmin: state => state.userAuthClass.id == 1
  }
}
