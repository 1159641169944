// import Store from '@/store/computed/storeHelpers'
export default {
  data() {
    return {
      allDocketLineHeaders: [
        {
          text: 'Billing period',
          value: 'docket.billingPeriod'
        },
        {
          text: `${this.$store.state.global.pages.docket.titleSingular} ID`,
          value: 'docket.id'
        },

        {
          text: 'Line ID',
          value: 'id'
        },
        {
          text: 'Type',
          value: 'docket.type'
        },
        { text: 'Date', value: 'docket._date' },
        {
          text: 'W/B ref #',
          align: 'left',
          value: 'wbDocketNumber',
          type: 'text'
        },
        {
          text: `${this.$store.state.global.pages.allocation.titleSingular} ref #`,
          value: 'docket.allocation.reference',
          type: 'text'
        },
        { text: 'Date received', value: 'docket._dateReceived' },
        {
          text: this.$store.state.global.pages.project.titleSingular,
          value: 'docket.source.project.name'
        },
        {
          text: this.$store.state.global.pages.source.titleSingular,
          value: 'docket.source.name'
        },
        {
          text: this.$store.state.global.pages.destination.titleSingular,
          value: 'destination.name',
          type: 'text'
        },
        {
          text: this.$store.state.global.pages.itemType.titleSingular,
          value: 'itemType.name',
          type: 'text'
        },
        {
          text: `${
            this.$store.state.global.pages.allocation.titleSingular
          } ${this.$store.state.global.pages.destination.titleSingular.toLowerCase()}`,
          value: 'allocationLine.destination.name',
          type: 'text'
        },
        {
          text: this.$store.state.global.pages.contractor.titleSingular,
          value: 'docket.vehicle.contractor.name'
        },
        {
          text: this.$store.state.global.pages.vehicle.titleSingular,
          value: 'docket.vehicle.name'
        },
        {
          text: this.$store.state.global.pages.vehicleType.titleSingular,
          value: 'docket.vehicle.vehicleType.name'
        },
        {
          text: 'Max. tonnage',
          value: 'docket.vehicle.vehicleType.tonnage',
          align: 'right'
        },
        {
          text: this.$store.state.global.pages.driver.titleSingular,
          value: 'docket.driver.name'
        },
        {
          text: 'Notes',
          align: 'left',
          value: 'notes',
          type: 'text'
        },
        {
          text: 'Run #',
          align: 'right',
          value: 'allocationLine.destination.runCode',
          sortable: true
        },
        {
          text: 'Quantity',
          value: '_qty',
          align: 'right',
          type: 'decimal',
          noOfDecimals: 2
        },
        { text: 'Unit', value: 'sellUnit', align: 'left' },
        {
          text: 'Mass',
          align: 'right',
          value: 'destinationWeight',
          type: 'decimal',
          noOfDecimals: 2
        },
        { text: 'Start', value: '_start', type: 'time' },
        { text: 'End', value: '_end', type: 'time' },
        {
          text: 'Hours',
          align: 'right',
          value: '_hours',
          type: 'decimal',
          noOfDecimals: 2
        },
        {
          text: 'Breaks',
          align: 'right',
          value: 'breaks',
          type: 'decimal',
          noOfDecimals: 2
        },
        {
          text: 'Travel',
          align: 'right',
          value: 'travel',
          type: 'decimal',
          noOfDecimals: 2
        },
        {
          text: 'Cost qty',
          align: 'right',
          value: '_costQty',
          type: 'decimal',
          noOfDecimals: 2
        },
        { text: 'Cost unit', value: 'costUnit' },
        {
          text: 'Cost',
          align: 'right',
          value: '_costPrice',
          type: 'curr',
          noOfDecimals: 2
        },
        {
          text: 'Cost Total',
          align: 'right',
          value: '_costTotal',
          type: 'curr',
          noOfDecimals: 2
        },
        {
          text: 'Sell',
          align: 'right',
          value: '_sellPrice',
          type: 'curr',
          noOfDecimals: 2
        },
        {
          text: 'Total',
          align: 'right',
          value: '_sellTotal',
          type: 'curr',
          noOfDecimals: 2
        },
        {
          text: 'Export',
          align: 'right',
          value: '_exportTotal',
          type: 'curr',
          noOfDecimals: 2
        },
        {
          text: 'Approved',
          align: 'right',
          value: 'approvedBillingAmount',
          type: 'curr',
          noOfDecimals: 2
        },
        {
          text: 'Waived',
          align: 'right',
          value: 'waivedAmount',
          type: 'curr',
          noOfDecimals: 2
        },
        {
          text: 'Outstanding',
          align: 'right',
          value: 'outstandingBillingAmount',
          type: 'curr',
          noOfDecimals: 2
        },
        {
          text: this.$store.state.global.pages.rcti.titleSingular,
          align: 'right',
          value: 'rctiId'
        },
        {
          text: `${this.$store.state.global.pages.bill.titleSingular}`,
          align: 'right',
          value: 'billId'
        },
        {
          text: `Export ${this.$store.state.global.pages.bill.titleSingular}`,
          align: 'right',
          value: 'exportBillId'
        },
        {
          text: this.$store.state.global.pages.rate.titleSingular,
          align: 'right',
          value: 'rateId'
        },
        {
          text: 'Closed',
          align: 'right',
          value: 'closed',
          sortable: false,
          type: 'boolean'
        },
        {
          text: 'App created',
          align: 'right',
          value: 'docket.isAppCreated',
          sortable: false,
          type: 'boolean'
        },
        { text: 'Entered By', value: 'createdByName' },
        { text: 'Entered ID', value: 'createdById' },
        { text: 'Updated By', value: 'updatedBy.name' },
        { text: 'Created At', value: '_createdAt', type: 'date' },
        { text: 'Updated At', value: '_updatedAt' },
        {
          text: 'GPS',
          value: 'GPSConfirmed',
          type: 'confirmedIcon',
          icon: 'mdi-crosshairs-gps',
          align: 'center'
        },
        {
          text: 'Tipping docket',
          value: 'tippingDocketConfirmed',
          type: 'confirmedIcon',
          icon: 'mdi-delete-sweep',
          align: 'center'
        },
        {
          text: 'Mass declaration',
          value: 'massDeclarationConfirmed',
          type: 'confirmedIcon',
          icon: 'mdi-basket',
          align: 'center'
        }
      ]
    }
  }
}
