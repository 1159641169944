import {
  getAllocations,
  getAllocationLines,
  getAllocationsByIds,
  getLinesByAllocationIds,
  getAllocationsWithLines,
  getAllocationsByIdsWithLines
} from '../../../services/requests/allocations'
import Allocation from '@/services/models/allocation'
import AllocationLine from '@/services/models/allocationLine'
// import Headers from './allocationHeaders'
import ExportKeys from '../../misc/shared/exportKeys'
import makePdf from './makeAllocationPdf'

export default {
  mixins: [ExportKeys, makePdf],
  computed: {
    exportOptions() {
      const currentHeaders = [...this.headers]
      currentHeaders.pop()
      return [
        {
          name: `${this.$store.state.global.pages.allocation.titleSingular.toLowerCase()} details`,
          fetchData: () => this.getPdfData(),
          multiPdf: true
        },
        {
          name: this.$store.state.global.pages.allocation.title.toLowerCase(),
          fetchData: () => this.getHeaders(),
          keys: this.allocationHeaderKeys
        },
        {
          name: `${this.$store.state.global.pages.allocation.titleSingular} Lines`.toLowerCase(),
          fetchData: () => this.getLines(),
          keys: this.allocationLineKeys
        },
        {
          name: 'selected (allocation) columns',
          keys: currentHeaders,
          fetchData: () => this.getHeaders()
        }
      ]
    }
  },
  methods: {
    async getPdfData() {
      let pdfData = []
      if (this.selectedAll) {
        const payload = this.buildQuery()
        const result = await getAllocationsWithLines(payload)
        const items = result.data.result.map(item => new Allocation(item))
        pdfData = items
      } else {
        const ids = this.selected.map(({ id }) => id)
        const result = await getAllocationsByIdsWithLines({ ids })
        const items = result.data.result.map(item => new Allocation(item))
        pdfData = items
      }
      const result = this.makePdf(pdfData)
      return result
    },
    async getHeaders() {
      if (this.selectedAll) {
        const payload = this.buildQuery()
        const result = await getAllocations(payload)
        const items = result.data.result.map(item => new Allocation(item))
        return items
      } else {
        const ids = this.selected.map(({ id }) => id)
        const result = await getAllocationsByIds({ ids })
        const items = result.data.result.map(item => new Allocation(item))
        return items
      }
    },
    async getLines() {
      if (this.selectedAll) {
        const payload = {
          userId: this.$store.state.global.selectedUserId,
          showClosed: this.$store.state.global.showClosed,
          isStaged: this.$store.state.allocations.isStaged,
          deliveryStatus: this.$store.state.global.selectedDeliveryStatus,
          projectId: this.$store.state.global.selectedProjectId,
          sourceId: this.$store.state.global.selectedSourceId,
          contractorId: this.$store.state.global.selectedContractorId,
          driverId: this.$store.state.global.selectedDriverId,
          vehicleId: this.$store.state.global.selectedVehicleId,
          destinationId: this.$store.state.global.selectedDestinationId,
          dateRangeStart: this.$store.state.global.dateRangeStart,
          dateRangeEnd: this.$store.state.global.dateRangeEnd,
          filterCol: this.$store.state.allocations.filterCol,
          filterBy: this.$store.state.allocations.filterBy
        }
        const result = await getAllocationLines(payload)
        const items = result.data.result.map(item => new AllocationLine(item))
        return items
      } else {
        const ids = this.selected.map(({ id }) => id)
        const result = await getLinesByAllocationIds({ ids })
        const items = result.data.result.map(item => new AllocationLine(item))
        return items
      }
    }
  }
}
