<template>
  <v-expand-transition>
    <v-row class="mt-0" v-show="costUnit">
      <v-col class="pt-0" cols="12" md="4" v-if="colSize == 2"></v-col>
      <v-col class="pt-0" cols="6" :md="colSize">
        <v-autocomplete
          label="Cost Units"
          prepend-icon="mdi-chevron-right"
          v-model="costUnit"
          :items="units"
          :disabled="!costUnitEnabled"
        ></v-autocomplete>
      </v-col>
      <v-col class="pt-0" cols="6" :md="colSize">
        <v-text-field
          :label="costUnit ? `Cost Qty (${costUnit})` : 'Cost Qty'"
          prepend-icon="mdi-chevron-right"
          v-model.number="costQty"
          ref="costQty"
          :rules="qtyRules"
          :disabled="disableCostQty"
        ></v-text-field>
      </v-col>
      <v-col class="pt-0" cols="6" :md="colSize">
        <v-text-field
          v-if="showCost"
          label="Cost Price"
          prepend-icon="mdi-chevron-right"
          v-model.number="costPrice"
          ref="costPrice"
          :disabled="priceDisabled"
        ></v-text-field>
      </v-col>
      <v-col class="pt-0" cols="6" :md="colSize">
        <v-text-field
          v-if="showCost"
          label="Total"
          prepend-icon="mdi-chevron-right"
          :value="costTotal.toFixed(2)"
          disabled
        ></v-text-field>
      </v-col>
    </v-row>
  </v-expand-transition>
</template>

<script>
export default {
  props: {
    values: {
      type: Object,
      required: true
    },
    onChange: {
      type: Function,
      required: true
    },
    units: {
      type: Array,
      required: true
    },
    costUnitEnabled: {
      type: Boolean,
      required: true
    },
    disableCostQty: {
      type: Boolean,
      required: true
    },
    priceDisabled: {
      type: Boolean,
      required: true
    },
    showCost: {
      type: Boolean,
      required: true
    },
    costTotal: {
      type: Number,
      required: true
    },
    qtyRules: {
      type: Array,
      required: true
    },
    colSize: {
      type: Number,
      required: false,
      default: 3
    }
  },
  data() {
    return {
      costUnit: '',
      costQty: 0,
      costPrice: 0
    }
  },
  watch: {
    values(val) {
      for (const key of Object.keys(val)) {
        this[key] = val[key]
      }
    },
    costUnit(val) {
      this.onChange('costUnit', val)
    },
    costQty(val) {
      this.onChange('costQty', val)
    },
    costPrice(val) {
      this.onChange('costPrice', val)
    }
  },
  methods: {
    focusRef(ref) {
      this.$refs[ref].focus()
    }
  }
}
</script>
