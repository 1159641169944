import Axios from 'axios'
import Store from '../store'
import Auth from './authentication'

import Router from '../router'

const ApiInstance = Axios.create({
  baseURL: process.env.VUE_APP_BASE_URL + 'api/',
  withCredentials: true
})

ApiInstance.interceptors.response.use(null, async error => {
  if (error.config && error.response && error.response.status === 401) {
    const response = error.response

    if (response) {
      if (
        response.status === 401 &&
        error.config &&
        !error.config.__isRetryRequest
      ) {
        const refreshToken = Auth.getRefreshTokenFromStorage()
        if (refreshToken) {
          const headers = {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + refreshToken
          }
          const result = await ApiInstance.post(
            'authentication/refreshToken',
            {},
            { headers: headers }
          )
          if (!result) await Store.dispatch('user/setLogout')

          await Store.dispatch('user/setAuthenticated', {
            token: result.data.accessToken,
            user: result.data.user,
            userDetails: result.data.userDetails,
            refreshToken: refreshToken
          })

          if (Router.currentRoute.name == 'login') {
            Router.push({ name: 'dashboard' })
          }
          error.config.headers.Authorization =
            'Bearer ' + result.data.accessToken
          error.config.__isRetryRequest = true
          return ApiInstance(error.config)
        } else {
          return Promise.reject(error)
        }
      }
    }
  } else {
    return Promise.reject(error)
  }
})

export default ApiInstance
