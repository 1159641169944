import Rules from '@/services/validation/rules/rules'
export default {
  data() {
    return {
      nameRules: [
        v => Rules.isRequired(v),
        v => Rules.minLength(v, 1),
        v => Rules.maxLength(v, 90)
      ],
      usernameRules: [
        v => Rules.isRequired(v),
        v => Rules.minLength(v, 1),
        v => Rules.maxLength(v, 90)
      ],
      contactEmailRules: [v => Rules.isEmail(v)],
      contactPhoneRules: [v => Rules.isPhone(v)],
      contractorRules: [v => Rules.isRequired(v)],
      userRules: [v => Rules.isRequired(v)]
    }
  }
}
