<template>
  <v-expansion-panels v-model="panel" focusable>
    <template
      v-if="filteredSettings.otherSettings && globalSettings.footerText"
    >
      <PanelWrapper
        v-if="
          $store.state.user.userAuthClass[`${$route.name}Read`] &&
            isAdministrator
        "
        title="Global Settings"
        icon="mdi-earth"
        :active="panel == 0"
      >
        <GlobalPanel
          :footerText="globalSettings.footerText"
          :otherSettings="filteredSettings.otherSettings"
        />
      </PanelWrapper>

      <PanelWrapper
        title="User Settings"
        icon="mdi-account"
        :active="panel == 1"
      >
        <UserPanel />
      </PanelWrapper>

      <PanelWrapper
        v-if="
          $store.state.user.userAuthClass[`${$route.name}Read`] &&
            isAdministrator
        "
        :title="$store.state.global.pages.billingPeriod.title"
        :icon="$store.state.global.pages.billingPeriod.icon"
        :active="panel == 2"
      >
        <BillingPeriodPanel />
      </PanelWrapper>

      <PanelWrapper
        v-if="$store.state.user.userAuthClass.policyRead"
        :title="$store.state.global.pages.policy.title"
        :icon="$store.state.global.pages.policy.icon"
        :active="panel == 3"
      >
        <PolicyPanel />
      </PanelWrapper>

      <PanelWrapper
        v-if="
          $store.state.user.userAuthClass[`${$route.name}Read`] &&
            isAdministrator
        "
        title="Accounting Settings"
        :icon="$store.state.global.pages.policy.icon"
        :active="panel == 4"
      >
        <AccountingPanel
          :accountingSettings="filteredSettings.accountingSettings"
        />
      </PanelWrapper>
    </template>
  </v-expansion-panels>
</template>

<script>
import PanelWrapper from './PanelWrapper'
import GlobalPanel from './GlobalPanel'
import UserPanel from './UserPanel'
import BillingPeriodPanel from './BillingPeriodPanel'
import PolicyPanel from './PolicyPanel'
import AccountingPanel from './AccountingPanel'

export default {
  components: {
    PanelWrapper,
    GlobalPanel,
    UserPanel,
    BillingPeriodPanel,
    PolicyPanel,
    AccountingPanel
  },
  data() {
    return {
      panel: null
    }
  },
  computed: {
    filteredSettings() {
      const {
        footerText,
        accountingSystem,
        salesAccount,
        purchasesAccount,
        xeroTokenSet,
        quickbooksTokenSet,
        myobTokenSet,
        myobUriId,
        ...otherSettings
      } = this.globalSettings
      return {
        footerText,
        accountingSettings: {
          id: otherSettings.id,
          accountingSystem,
          salesAccount,
          purchasesAccount,
          xeroTokenSet,
          quickbooksTokenSet,
          myobTokenSet,
          myobUriId
        },
        otherSettings
      }
    },
    isAdministrator() {
      return this.$store.state.user.userAuthClass.name == 'Administrator'
    }
  }
}
</script>
