import Api from '@/services/api.js'
export default {
  register(credentials) {
    return Api.post('authentication/register', credentials)
  },
  login(credentials) {
    return Api.post('authentication/login', credentials)
  },
  dontUserTokenForRequests() {
    Api.defaults.headers['Authorization'] = null
  },
  checkAuth() {
    return Api.post('authentication/checkAuth')
  },
  getTokenFromStorage() {
    return localStorage.getItem('accessToken')
  },
  getRefreshTokenFromStorage() {
    return localStorage.getItem('refreshToken')
  },
  useTokenForRequests(token) {
    Api.defaults.headers['Authorization'] = `Bearer ` + token
  },
  getS3PutUrl(payload) {
    return Api.post('authentication/getS3PutUrl', payload)
  },
  getS3Url(payload) {
    return Api.post('authentication/getS3Url', payload)
  },
  generateQrCode() {
    return Api.get('authentication/qr')
  },
  verify2FA(payload) {
    return Api.post('authentication/verify2FA', payload)
  },
  disable2FA(payload) {
    return Api.post('authentication/disable2FA', payload)
  },
  getVerified2FA() {
    return Api.get('authentication/getVerified2FA')
  }
}
