import {
  getDockets,
  getDocketLines,
  getDocketsByIds,
  getDocketsWithLines,
  getDocketsByIdsWithLines,
  getLinesByDocketIds
} from '../../../services/requests/dockets'
// import Headers from './docketHeaders'
import Docket from '@/services/models/docket'
import DocketLine from '@/services/models/docketLine'
import ExportKeys from '../../misc/shared/exportKeys'
import makePdf from './makeDocketPdf'

export default {
  mixins: [ExportKeys, makePdf],
  computed: {
    exportOptions() {
      const viewHeaders = [...this.headers]
      viewHeaders.pop()
      return [
        {
          name: `${this.$store.state.global.pages.docket.titleSingular.toLowerCase()} details`,
          fetchData: () => this.getPdfData(),
          multiPdf: true
        },
        {
          name: this.$store.state.global.pages.docket.title.toLowerCase(),
          fetchData: () => this.getHeaders(),
          keys: this.docketHeaderKeys
        },
        {
          name: `${this.$store.state.global.pages.docket.titleSingular.toLowerCase()} lines`,
          fetchData: () => this.getLines(),
          keys: this.docketLineKeys
        },
        {
          name: 'selected (docket) columns',
          keys: viewHeaders,
          fetchData: () => this.getHeaders()
        }
      ]
    }
  },
  methods: {
    async getPdfData() {
      let pdfData = []
      if (this.selectedAll) {
        const payload = this.buildQuery()
        const result = await getDocketsWithLines(payload)
        const items = result.data.result.map(item => new Docket(item))
        pdfData = items
      } else {
        const ids = this.selected.map(({ id }) => id)
        const result = await getDocketsByIdsWithLines({ ids })
        const items = result.data.result.map(item => new Docket(item))
        pdfData = items
      }
      const result = this.makePdf(pdfData)
      return result
    },
    async getHeaders() {
      if (this.selectedAll) {
        const payload = this.buildQuery()
        const result = await getDockets(payload)
        const items = result.data.result.map(item => new Docket(item))
        return items
      } else {
        const ids = this.selected.map(({ id }) => id)
        const result = await getDocketsByIds({ ids })
        const items = result.data.result.map(item => new Docket(item))
        return items
      }
    },
    async getLines() {
      if (this.selectedAll) {
        const payload = {
          userId: this.$store.state.global.selectedUserId,
          showClosed: this.$store.state.global.showClosed,
          showAutoCreated: this.$store.state.global.showAutoCreated,
          deliveryStatus: this.$store.state.global.selectedDeliveryStatus,
          billingPeriod: this.$store.state.global.selectedBillingPeriod,
          projectId: this.$store.state.global.selectedProjectId,
          sourceId: this.$store.state.global.selectedSourceId,
          contractorId: this.$store.state.global.selectedContractorId,
          driverId: this.$store.state.global.selectedDriverId,
          vehicleId: this.$store.state.global.selectedVehicleId,
          destinationId: this.$store.state.global.selectedDestinationId,
          dateRangeStart: this.$store.state.global.dateRangeStart,
          dateRangeEnd: this.$store.state.global.dateRangeEnd,
          filterCol: this.$store.state.dockets.filterCol,
          filterBy: this.$store.state.dockets.filterBy
        }
        const result = await getDocketLines(payload)
        const items = result.data.result.map(item => new DocketLine(item))
        return items
      } else {
        const ids = this.selected.map(({ id }) => id)
        const result = await getLinesByDocketIds({ ids })
        const items = result.data.result.map(item => new DocketLine(item))
        return items
      }
    }
  }
}
