<template>
  <v-card>
    <v-card-text class="pb-0">
      <v-card-title
        class="px-0 pb-1 font-weight-regular d-flex justify-space-between"
      >
        <span>
          View {{ getComplianceTypeName(item.type) }} -
          <span class="pl-1 subtitle-1">
            ID: <span class="font-weight-bold">{{ item.id }}</span>
          </span>
        </span>
        <v-tooltip bottom v-if="userAuthClass">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click="showEditDialog">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit this {{ titleSingular.toLowerCase() }}</span>
        </v-tooltip>
      </v-card-title>
      <v-row>
        <Detail
          :icon="$store.state.global.pages[item.model].icon"
          :text="item.belongsTo"
        />
        <Detail icon="mdi-calendar" :text="makeShortDate(item.expiryDate)" />
        <Detail icon="mdi-note" :text="item.notes" v-if="item.notes" />
        <v-col v-if="url" class="text-center">
          <v-img :src="url" v-if="item.attachmentType != 'pdf'"></v-img>
          <v-btn v-else block color="primary" @click="viewPdf">
            View Document
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <v-row class="pt-3">
        <v-col cols="12" class="py-0" v-if="item.updatedAt !== item.createdAt">
          <TimeAgo
            class="grey--text"
            :subject="item"
            :time="item.updatedAt"
            needsParse
            updated
          >
            <v-icon small color="grey" slot="icon" class="mr-2">
              mdi-clock-outline
            </v-icon>
          </TimeAgo>
        </v-col>
        <v-col cols="12" class="py-0">
          <TimeAgo
            class="grey--text"
            :subject="item"
            :time="item.createdAt"
            needsParse
            created
          >
            <v-icon small color="grey" slot="icon" class="mr-2">
              mdi-clock-outline
            </v-icon>
          </TimeAgo>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <ShowAudits :auditData="auditData" />
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="close">Close</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Auth from '../../services/authentication'
import FormatDates from '@/services/mixins/formatDates'
import models from './mixins/models'
import Detail from '../misc/Detail'
import TimeAgo from '../misc/TimeAgo'
import ShowAudits from '../misc/shared/ShowAudits'

export default {
  mixins: [FormatDates, models],
  components: {
    Detail,
    TimeAgo,
    ShowAudits
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    close: {
      type: Function,
      required: true
    },
    openEditDialog: {
      type: Function,
      required: false
    },
    loading: {
      type: Boolean,
      required: true
    },
    open: {
      type: Function,
      required: false
    }
  },
  data() {
    return {
      url: null
    }
  },
  mounted() {
    this.getUrl()
  },
  computed: {
    attachmentType() {
      if (this.url) {
        const arr = this.url.split('?')
        const urlArr = arr[0].split('.')
        return urlArr[urlArr.length - 1]
      } else {
        return null
      }
    },
    auditData() {
      return { id: this.item.id, model: 'compliance' }
    }
  },
  methods: {
    viewPdf() {
      window.open(this.url)
    },
    async getUrl() {
      if (this.item.key) {
        try {
          const result = await Auth.getS3Url({
            key: this.item.key,
            bucket: 'payloader-compliance'
          })
          this.url = result.data.result
        } catch (err) {
          console.log(err)
        }
      }
    },
    showEditDialog() {
      this.close()
      this.openEditDialog()
    }
  }
}
</script>

<style>
.show-route:hover {
  cursor: pointer;
}
</style>
