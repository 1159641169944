<template>
  <v-card flat class="card-outlined">
    <v-card-text class="pb-0 d-flex justify-space-between align-center">
      <v-row>
        <v-col cols="12" sm="10" class="py-0">
          <MultiActions
            page="rctis"
            :selectedCount="selectedCount"
            :fieldsToChange="actionFields"
            :exportOptions="exportOptions"
            :updateFieldToChange="val => (fieldToChange = val)"
          />
        </v-col>
        <v-col cols="12" sm="2" class="py-0 d-flex justify-end align-center">
          <v-tooltip left transition="slide-x-transition">
            <template v-slot:activator="{ on }">
              <div v-on="on" class="py-3">
                <v-btn
                  text
                  icon
                  color="grey"
                  @click="chartDialog = !chartDialog"
                  class="mr-2"
                >
                  <v-icon>mdi-chart-box</v-icon>
                </v-btn>
              </div>
            </template>
            <span>View charts</span>
          </v-tooltip>
          <ColumnSelector
            :defaultHeaders="defaultHeaders"
            :possibleHeaders="possibleHeaders"
            toolTipText="Column selector"
            :onChanged="val => (defaultHeaders = val)"
          />
        </v-col>
        <SelectAlert
          :selected="selected"
          :selectedAll="selectedAll"
          :totalRecordCount="count"
          :selectAll="() => (selectedAll = true)"
          :unselectAll="() => ((selectedAll = false), (selected = []))"
        />
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="rctis"
      :loading="loadingRctis"
      :options.sync="pagination"
      :server-items-length="count"
      mobile-breakpoint="0"
      :footer-props="{
        'items-per-page-options': [10, 20, 40, 50],
        showFirstLastPage: true
      }"
      v-model="selected"
      show-select
    >
      <template v-slot:no-data>
        No matching records found
      </template>
      <template v-slot:loading>
        Loading records...
      </template>
      <template v-slot:item="{ item, isSelected }">
        <Row
          :headers="headers"
          :item="item"
          :key="item.id"
          :value="isSelected"
          @input="toggleSelected"
          select
          showEdit
          editDialogFullscreen
          detailsDialogFullscreen
          defaultDialog="detailsDialog"
        >
          <template v-slot:details="{ dialog, close, openEditDialog }">
            <RctiDetails
              v-if="dialog"
              :dialog="dialog"
              :item="item"
              :loading="loading"
              :close="close"
              :openEditDialog="openEditDialog"
            />
          </template>
          <template v-slot:edit="{ dialog, close }">
            <RctiEdit
              v-if="dialog"
              :dialog="dialog"
              :item="item"
              :loading="loading"
              :action="editRcti"
              :close="close"
            />
          </template>
          <template v-slot:delete="{ dialog, close }">
            <DeleteDialog
              :dialog="dialog"
              :page="titleSingular"
              :subject="item"
              :action="deleteRcti"
              :close="close"
            />
          </template>
        </Row>
      </template>
      <template
        v-slot:footer[`page-text`]="{ pageStart, pageStop, itemsLength }"
      >
        <div class="text-center mt-1">
          <span v-if="itemsLength">
            {{ `${pageStart}-${pageStop} of ${itemsLength}` }}
          </span>
          <span v-else> - </span>
          <v-progress-linear
            v-if="loadingRctis && itemsLength"
            size="15"
            indeterminate
            color="primary"
          ></v-progress-linear>
          <div v-else style="height:5px"></div>
        </div>
      </template>
    </v-data-table>
    <v-dialog
      v-model="chartDialog"
      @click:outside="() => (chartDialog = false)"
      @keydown.esc="() => (chartDialog = false)"
      fullscreen
    >
      <chart :close="() => (chartDialog = false)" />
    </v-dialog>
  </v-card>
</template>

<script>
import { deleteRcti, updateRcti } from '../../services/requests/rctis'
import {
  createXeroRcti,
  createQuickbooksRcti,
  createMyobRcti
} from '../../services/requests/accounting'
import Store from '@/store/computed/storeHelpers'
import getRctisMixin from '../../services/mixins/getTransactionFiles/getRctis'
import Headers from './mixins/rctiHeaders'
import HeaderFilters from '../../services/mixins/headerFilters'
import MultipleRecords from '../../services/mixins/multipleRecords'
import Rcti from '../../services/models/rcti'
import RctiExport from './mixins/rctiExport'
import Chart from '../misc/shared/Chart'

import Row from '../misc/row/Row'
import RctiEdit from './RctiEdit'
import RctiDetails from './RctiDetails'
import DeleteDialog from '../misc/DeleteDialog'
import ColumnSelector from '../misc/shared/ColumnSelector'
import MultiActions from '../misc/shared/MultiActions'
import SelectAlert from '../misc/shared/SelectAlert'

import { debounce } from 'lodash'
export default {
  mixins: [getRctisMixin, Headers, HeaderFilters, MultipleRecords, RctiExport],
  components: {
    Chart,
    Row,
    RctiEdit,
    RctiDetails,
    DeleteDialog,
    ColumnSelector,
    MultiActions,
    SelectAlert
  },
  data() {
    return {
      loading: false,
      chartDialog: false
    }
  },
  computed: {
    actionFields() {
      const authLimitedActions = [
        {
          text: `${this.$store.state.global.pages.rcti.titleSingular} Status`,
          icon: 'mdi-information',
          color: 'green',
          value: {
            value: null,
            text: `${this.$store.state.global.pages.rcti.titleSingular} Status`,
            label: 'update'
          },
          types: this.getTypes('status', 'internalNotes', 'mdi-information')
        },
        {
          text: 'Stat Dec data',
          icon: 'mdi-note-multiple',
          color: 'green',
          value: { value: null, text: 'Stat Dec data', label: 'update' },
          types: this.getTypes(
            'statDecConfirmed',
            'statDecNotes',
            'mdi-note-multiple'
          )
        },
        {
          text: `Create ${this.$store.state.settings.settings
            .accountingSystem || ''} invoices`,
          icon: 'mdi-file-export',
          color: 'grey',
          value: {
            value: null,
            text: `Create ${this.$store.state.settings.settings
              .accountingSystem || ''} invoices`,
            label: 'import'
          },
          action: async () => this.createInvoices()
        },
        {
          text: 'Delete selected',
          icon: 'mdi-delete',
          color: 'red',
          value: { value: null, text: 'Delete selected', label: 'delete' },
          action: async () => {
            await this.deleteMultipleRecords({
              ids: this.selected.map(({ id }) => id),
              table: 'rctis',
              query: this.selectedAll ? this.buildQuery() : null
            })
          }
        }
      ]
      let actions = [
        {
          text: 'Export selected',
          icon: 'mdi-file-export',
          color: 'grey',
          value: { value: null, text: 'Export Selected', label: 'export' }
        },
        {
          text: 'Print selected',
          icon: 'mdi-file-pdf-box',
          color: 'grey',
          value: { value: null, text: 'Print selected', label: 'print' }
        }
      ]
      if (this.userAuthClass) actions = [...actions, ...authLimitedActions]
      return actions
    },

    showClosed: Store.getSet({ store: 'global', prop: 'showClosed' }),
    selectedBillingPeriod: Store.getSet({
      store: 'global',
      prop: 'selectedBillingPeriod'
    }),
    selectedProjectId: Store.getSet({
      store: 'global',
      prop: 'selectedProjectId'
    }),
    selectedContractorId: Store.getSet({
      store: 'global',
      prop: 'selectedContractorId'
    }),
    selectedUserId: Store.getSet({ store: 'global', prop: 'selectedUserId' }),
    dateRangeStart: Store.getSet({ store: 'global', prop: 'dateRangeStart' }),
    dateRangeEnd: Store.getSet({ store: 'global', prop: 'dateRangeEnd' }),
    filterCol: Store.getSet({ store: 'rctis', prop: 'filterCol' }),
    filterBy: Store.getSet({ store: 'rctis', prop: 'filterBy' }),
    selected: Store.getSet({ store: 'rctis', prop: 'selected' }),
    selectedAll: Store.getSet({ store: 'rctis', prop: 'selectedAll' }),
    selectedCount() {
      return this.selectedAll ? this.count : this.selected.length
    },
    accounting() {
      return this.globalSettings.accountingSystem
    }
  },
  watch: {
    rctis() {
      this.selected = []
      this.selectedAll = false
    },
    selectedBillingPeriod() {
      this.getRctisFromPage1()
    },
    selectedContractorId() {
      this.getRctisFromPage1()
    },
    selectedUserId() {
      this.getRctisFromPage1()
    },
    selectedProjectId() {
      this.getRctisFromPage1()
    },
    dateRangeStart() {
      this.getRctisFromPage1()
    },
    dateRangeEnd() {
      this.getRctisFromPage1()
    },
    filterCol() {
      this.getRctisFromPage1()
    },
    showClosed() {
      this.getRctisFromPage1()
    },
    filterBy: debounce(function() {
      this.getRctisFromPage1()
    }, 300)
  },
  methods: {
    getTypes(field, notesField, icon) {
      const typeStatuses = [
        { text: 'Entered', value: 'ENTERED', color: 'blue' },
        { text: 'Approved', value: 'APPROVED', color: 'green' },
        { text: 'Review', value: 'REVIEW', color: 'amber' },
        { text: 'Sent', value: 'SENT', color: 'green' },
        { text: 'Unconfirm', value: 'NOT SET', color: 'grey' }
      ]
      const types = []
      for (const status of typeStatuses) {
        const payload = {
          table: 'rctis',
          field,
          notesField,
          value: status.value,
          query: this.selectedAll ? this.buildQuery() : null,
          model: Rcti,
          prop: 'rctis',
          store: 'rctis'
        }
        types.push({
          text: status.text,
          icon,
          color: status.color,
          value: { value: null, text: status.text, label: 'update' },
          action: async notes =>
            await this.updateMultipleRecords({
              ...payload,
              items: this.selected,
              notes
            })
        })
      }
      return types
    },
    async getRctisFromPage1() {
      this.pagination.page = 1
      await this.getRctis()
    },
    toggleSelected(val) {
      if (val.value) {
        this.selected.push(val.object)
      } else {
        var index = this.selected.indexOf(val.object)
        this.selected.splice(index, 1)
      }
    },
    async editRcti(item) {
      try {
        this.loading = true
        const payload = {
          // billingPeriod: item.billingPeriod,
          externalRef: item.externalRef,
          date: item.date,
          status: item.status,
          statDecConfirmed: item.statDecConfirmed,
          statDecNotes: item.statDecNotes,
          internalNotes: item.internalNotes,
          invoiceNotes: item.invoiceNotes,
          rctiLines: item.rctiLines
        }
        const result = await updateRcti(item.id, payload)
        if (result) {
          this.$store.commit('rctis/updateArrayStateById', {
            prop: 'rctis',
            editedValue: new Rcti(result.data.result)
          })
          this.snackUpdated()
        }
        this.loading = false
        return result
      } catch (err) {
        this.loading = false
        console.log(err)
      }
    },
    async createInvoices() {
      try {
        if (!this.accounting)
          return this.snack({
            text: 'No accounting software selected in global settings',
            color: 'amber'
          })
        const route = () => {
          if (this.accounting === 'Xero') return createXeroRcti
          if (this.accounting === 'Quickbooks') return createQuickbooksRcti
          if (this.accounting === 'MYOB') return createMyobRcti
        }
        const payload = {
          ids: this.selected.map(({ id }) => id),
          query: this.selectedAll ? this.buildQuery() : null
        }
        const result = await route()(payload)
        if (!result?.data?.result?.length) {
          return this.snack({
            text: 'Error creating invoices',
            color: 'amber'
          })
        }
        for (const item of result.data.result) {
          this.$store.commit('rctis/updateArrayStateById', {
            prop: 'rctis',
            editedValue: new Rcti(item)
          })
        }
        this.snack({
          text: result?.data?.message,
          color: 'green'
        })
      } catch (err) {
        console.log(err)
        this.snack({ text: err.message, color: 'red' })
      }
    },
    async deleteRcti(rcti) {
      try {
        this.loading = true
        const result = await deleteRcti(rcti.id)
        if (result) {
          this.$store.commit('rctis/deleteFromArrayStateByValue', {
            prop: 'rctis',
            value: rcti
          })
          this.snackDeleted()
        }
        this.loading = false
        return result
      } catch (err) {
        this.loading = false
        console.log(err)
      }

      // return deleteRcti(rcti.id)
      //   .then(() => {
      //     this.$store.commit('rctis/deleteFromArrayStateByValue', {
      //       prop: 'rctis',
      //       value: rcti
      //     })
      //     this.loading = false
      //     this.snackDeleted()
      //   })
      //   .catch(err => {
      //     this.loading = false
      //     console.log(err)
      //   })
    }
  }
}
</script>
