import { getPods, getPodsByIds } from '../../../services/requests/allocations'
import ExportKeys from '../../misc/shared/exportKeys'
// import Headers from './podHeaders'

export default {
  mixins: [ExportKeys],
  computed: {
    exportOptions() {
      const currentHeaders = [...this.headers]
      currentHeaders.pop()
      return [
        {
          name: this.$store.state.global.pages.pod.title,
          fetchData: () => this.getHeaders(),
          keys: this.podKeys
        },
        {
          name: 'selected (pod) columns',
          keys: currentHeaders,
          fetchData: () => this.getHeaders()
        }
      ]
    }
  },
  methods: {
    async getHeaders() {
      if (this.selectedAll) {
        const payload = this.buildQuery()
        const result = await getPods(payload)
        return result.data.result
      } else {
        const ids = this.selected.map(({ id }) => id)
        const uniq = [...new Set(ids)]
        const result = await getPodsByIds({ ids: uniq })
        return result.data.result
      }
    }
  }
}
