import Store from '@/store/computed/storeHelpers'
import { getItemTypes } from '../../requests/itemTypes'
export default {
  mounted() {
    if (this.pagination) this.initPagination()
    this.getItemTypes()
  },
  data() {
    return {
      itemTypeCount: 0,
      loadedItemTypes: false
    }
  },
  watch: {
    pagination: {
      deep: true,
      handler() {
        this.getItemTypes()
      }
    },
    sortDesc(val) {
      this.pagination.sortDesc = [val]
    }
  },
  computed: {
    sortDesc: Store.getSet({ store: 'masterfiles', prop: 'sortDesc' }),
    itemTypes: Store.getSet({ store: 'itemTypes', prop: 'itemTypes' }),
    loadingItemTypes: Store.getSet({
      store: 'itemTypes',
      prop: 'loadingItemTypes'
    })
  },
  methods: {
    async getItemTypes() {
      try {
        this.loadingItemTypes = true
        const payload =
          this.masterFile == 'itemType'
            ? { pagination: this.pagination, filterBy: this.filterBy }
            : {}
        const result = await getItemTypes(payload)
        if (result) {
          this.itemTypes = result.data.result
          this.itemTypeCount = result.data.count
        }
        this.loadingItemTypes = false
        this.loadedItemTypes = true
      } catch (err) {
        console.log(err)
        this.loadingItemTypes = false
      }
    }
  }
}
