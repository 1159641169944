import Store from '@/store/computed/storeHelpers'
import { getSettings } from '../requests/settings'
import SetPages from '../mixins/setPages'

export default {
  mixins: [SetPages],
  // mounted() {
  // this.getSettings()
  // },
  computed: {
    settings: Store.getSet({ store: 'settings', prop: 'settings' }),
    loadingSettings: Store.getSet({
      store: 'settings',
      prop: 'loadingSettings'
    })
  },
  methods: {
    async getSettings() {
      try {
        this.loadingSettings = true
        const result = await getSettings()
        if (result) this.settings = result.data.result
        this.loadingSettings = false
        this.loadedSettings = true
        return this.settings
      } catch (err) {
        console.log(err)
        this.loadingSettings = false
      }
    }
  }
}
