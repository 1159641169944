<template>
  <v-card flat class="card-outlined mx-3">
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="() => toggleAdd(false)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title> Add {{ titleSingular }} </v-toolbar-title>
    </v-toolbar>
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-sheet outlined rounded>
            <v-card flat class="card-outlined">
              <v-card-text class="pb-0">
                <CardHeader
                  :title="`${titleSingular} Entry`"
                  :icon="$store.state.global.pages.plan.icon"
                  :actions="controlActions"
                />
                <v-form ref="addForm" @submit.prevent="add()">
                  <v-row class="mb-1">
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        label="Reference"
                        prepend-icon="mdi-group"
                        v-model="reference"
                        :rules="referenceRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <DatePickerWrapper
                        label="* Date"
                        v-model="date"
                        :rules="dateRules"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <TimePickerWrapper
                        required
                        v-model="start"
                        refString="start"
                        label="Start Time"
                      />
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-autocomplete
                        :label="
                          `* ${$store.state.global.pages.source.titleSingular}`
                        "
                        :prepend-icon="$store.state.global.pages.source.icon"
                        v-model="sourceId"
                        :items="sources"
                        item-value="id"
                        item-text="sourceName_projectName"
                        :rules="sourceRules"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-autocomplete
                        label="Travel Mode"
                        :prepend-icon="$store.state.global.pages.vehicle.icon"
                        v-model="travel_mode"
                        :items="modes"
                        item-value="id"
                        item-text="name"
                        :hint="
                          selectedMode
                            ? `Vehicle height: ${selectedMode.max_height}`
                            : ''
                        "
                        persistent-hint
                        :rules="sourceRules"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-text-field
                        label="Notes"
                        prepend-icon="mdi-note"
                        v-model="notes"
                        :rules="notesRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-switch
                        :label="isDay ? 'Day' : 'Night'"
                        color="primary"
                        v-model="isDay"
                        inset
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col md="6" cols="12" v-if="allowedPlanVehicles.length">
                      <h4>
                        Available
                        {{ $store.state.global.pages.plan.titleSingular }}
                        {{ $store.state.global.pages.vehicle.title }}
                      </h4>
                      <v-list dense>
                        <v-list-item
                          v-for="(item, i) in allowedPlanVehicles"
                          :key="i"
                        >
                          <v-list-item-action>
                            <v-checkbox
                              color="secondary"
                              v-model="planVehicles"
                              :label="
                                `${
                                  item.vehicleName_contractorName_vehicleType
                                } / ${
                                  item.driver ? item.driver.name : 'No driver'
                                }`
                              "
                              :value="item"
                            ></v-checkbox>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col md="6" cols="12" v-if="allowedPlanVehicles.length">
                      <h4>
                        Selected
                        {{ $store.state.global.pages.plan.titleSingular }}
                        {{ $store.state.global.pages.vehicle.title }}
                      </h4>
                      <v-chip
                        v-for="item in planVehicles"
                        :key="`${item.id}-${item.driver ? item.driver.id : 0}`"
                        color="primary"
                        outlined
                        class="ma-1"
                      >
                        <v-icon left>
                          {{ $store.state.global.pages.vehicle.icon }}
                        </v-icon>
                        <div>
                          {{ item.vehicleName_contractorName_vehicleType }}
                        </div>
                        <div v-if="item.driver" class="pl-1">
                          / {{ item.driver.name }}
                        </div>
                      </v-chip>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      class="pt-0 d-flex justify-end"
                      v-if="lines.length"
                      ><v-tooltip top :disabled="!duplicatedVehicles.length">
                        <template v-slot:activator="{ on }">
                          <div v-on="on">
                            <v-btn
                              :disabled="!!duplicatedVehicles.length"
                              color="primary"
                              type="submit"
                              :loading="loading"
                              outlined
                              class="mb-3"
                            >
                              Save
                            </v-btn>
                          </div>
                        </template>
                        <span>
                          Remove duplicated
                          {{
                            $store.state.global.pages.vehicle.title.toLowerCase()
                          }}
                        </span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-sheet>
        </v-col>

        <v-col cols="12">
          <PlanLineAdd
            :lines="lines"
            :onAdd="addLine"
            :onEdit="editLine"
            :onDelete="deleteLine"
            :loading="loading"
            :lineHeaders="lineHeaders"
            :sources="sources"
            :vehicles="vehicles"
            :drivers="drivers"
            :destinations="destinations"
            :itemTypes="itemTypes"
            :sourceId="sourceId"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="importDialog" width="900px">
      <ImportPlanLines
        :title="`Import ${$store.state.global.pages.plan.title} Lines`"
        :close="() => (importDialog = false)"
        :importLines="importLines"
        :loading="loadingLines"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import Vue from 'vue'
import Papa from 'papaparse'
import { addPlan } from '../../services/requests/plans'
import PlanValidation from '../../services/validation/plan'
import FilterByContractor from '../../services/mixins/filterByContractor'
import FormatDates from '../../services/mixins/formatDates'
import Plan from '../../services/models/plan'
import PlanLine from '../../services/models/planLine'
import LineHeaders from './lines/mixins/lineHeaders'
import CardHeader from '../misc/shared/CardHeader'
import DatePickerWrapper from '../misc/fields/DatePickerWrapper'
import PlanLineAdd from '../plans/lines/PlanLineAdd'
import ImportPlanLines from './ImportPlanLines'
import TimePickerWrapper from '../misc/fields/TimePickerWrapper'

export default {
  mixins: [PlanValidation, FilterByContractor, FormatDates, LineHeaders],
  components: {
    CardHeader,
    DatePickerWrapper,
    PlanLineAdd,
    ImportPlanLines,
    TimePickerWrapper
  },
  props: {
    toggleAdd: {
      type: Function,
      required: true
    },
    sources: {
      type: Array,
      required: true
    },
    vehicles: {
      type: Array,
      required: true
    },
    drivers: {
      type: Array,
      required: true
    },
    destinations: {
      type: Array,
      required: true
    },
    itemTypes: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      reference: null,
      date: null,
      start: null,
      sourceId: null,
      vehicleId: null,
      driverId: null,
      notes: null,
      endDate: null,
      isDay: true,
      lines: [],
      loading: false,
      loadingLines: false,
      addRunDialog: false,
      importDialog: false,
      planVehicles: [],
      travel_mode: 'light_truck',
      modes: [
        { id: 'drive', name: 'Drive', max_height: '1.6m' },
        { id: 'light_truck', name: 'Light Truck', max_height: '3.2m' },
        { id: 'medium_truck', name: 'Medium Truck', max_height: '4.1m' },
        { id: 'truck', name: 'Truck', max_height: '4.1m' },
        { id: 'heavy_truck', name: 'Heavy Truck', max_height: '4.1m' }
      ]
    }
  },
  mounted() {
    if (this.$store.state.global.defaultSourceId) {
      this.sourceId = this.$store.state.global.defaultSourceId
    }
  },
  watch: {
    sourceId() {
      this.planVehicles = this.allowedPlanVehicles
    }
  },
  computed: {
    lineHeaders() {
      return this.allPossibleHeaders.filter(
        item => item.text != 'POD' && item.text != 'Delivery Status'
      )
    },
    selectedDriver() {
      if (!this.drivers || !this.driverId) return null
      return this.drivers.filter(({ id }) => this.driverId == id)[0]
    },
    allowedPlanVehicles() {
      if (!this.vehicles || !this.sourceId) return []
      const source = this.sources.find(({ id }) => this.sourceId == id)
      const allowedVehicles = this.vehicles.filter(({ id }) =>
        source.allowedVehicles.includes(id)
      )
      // console.log(allowedVehicles)
      return allowedVehicles
    },
    duplicatedVehicles() {
      const vehicleNames = this.planVehicles.map(({ name }) => name)
      const dup = vehicleNames.filter(
        (item, index) => vehicleNames.indexOf(item) !== index
      )
      return dup
    },
    selectedMode() {
      return this.modes.find(({ id }) => id == this.travel_mode)
    },
    controlActions() {
      return [
        {
          action: () => (this.importDialog = !this.importDialog),
          text: 'Import lines',
          icon: 'mdi-paperclip',
          toolTipText: 'Import lines',
          color: 'primary'
        }
      ]
    }
  },
  methods: {
    async add() {
      if (!this.$refs.addForm.validate()) {
        this.snackFormError()
        return
      }
      try {
        this.loading = true
        const lines = []
        for (const line of this.lines) {
          lines.push({
            destinationId: line.destinationId,
            itemTypeId: line.itemTypeId,
            vehicleId: line.vehicleId,
            driverId: line.driverId,
            qty: line.qty,
            notes: line.notes
          })
        }
        const planVehicles = this.planVehicles.map(item => {
          return {
            vehicleId: item.id,
            driverId: item.driver ? item.driver.id : null
          }
        })
        const planVehiclesStr = JSON.stringify(planVehicles)
        const payload = {
          reference: this.reference,
          date: this.date,
          isDay: this.isDay,
          sourceId: this.sourceId,
          notes: this.notes,
          planVehicles: planVehiclesStr,
          start: this.start,
          travelMode: this.travel_mode,
          lines
        }

        const result = await addPlan(payload)
        if (result) {
          this.$store.commit('plans/addToArrayState', {
            prop: 'plans',
            value: new Plan(result.data.result)
          })
          if (result.data.lines)
            for (const line of result.data.lines) {
              this.$store.commit('plans/addToArrayState', {
                prop: 'lines',
                value: new PlanLine(line)
              })
            }
          this.cancel()
          this.snackCreated()
        }
        this.loading = false
      } catch (err) {
        this.loading = false
        console.log(err)
      }
    },
    importLines(file) {
      this.loadingLines = true
      Papa.parse(file, {
        skipEmptyLines: true,
        complete: this.processCsv,
        error: () => (this.loadingLines = false)
      })
    },
    processCsv(results) {
      console.log(results)
      const linesNotFound = []
      for (const line of results.data) {
        const destination = this.destinations.find(
          ({ name }) => name === line[0]
        )
        const itemType = this.itemTypes.find(({ name }) => name === line[1])
        const data = {
          id: this.lines.length + 1,
          destinationId: destination?.id,
          itemTypeId: itemType?.id,
          qty: Number(line[2]),
          destination,
          itemType
        }
        if (destination && itemType) this.lines.push(data)
        else linesNotFound.push(data)
      }
      let text = ''
      if (linesNotFound.length) {
        text += `${linesNotFound.length} lines were not found. `
        console.log(linesNotFound)
      }
      if (this.lines.length) text += `${this.lines.length} lines were added`
      this.snack({
        text,
        color: linesNotFound.length ? 'yellow' : 'green'
      })
      this.loadingLines = false
      this.importDialog = false
    },
    addLine(line) {
      const destination = this.destinations.find(
        ({ id }) => id === line.destinationId
      )
      const itemType = this.itemTypes.find(({ id }) => id === line.itemTypeId)
      const vehicle = line.vehicleId
        ? this.vehicles.find(({ id }) => id === line.vehicleId)
        : null
      const driver = line.driverId
        ? this.drivers.find(({ id }) => id === line.driverId)
        : null
      this.lines.push({
        ...line,
        destination,
        itemType,
        vehicle,
        driver
      })
    },
    editLine({ original, item }) {
      item.destination = this.destinations.find(
        ({ id }) => id === item.destinationId
      )
      item.itemType = this.itemTypes.find(({ id }) => id === item.itemTypeId)
      item.vehicle = item.vehicleId
        ? this.vehicles.find(({ id }) => id === item.vehicleId)
        : null
      item.driver = item.driverId
        ? this.drivers.find(({ id }) => id === item.driverId)
        : null
      const i = this.lines.indexOf(original)
      if (~i) Vue.set(this.lines, i, item)
    },
    deleteLine(line) {
      const i = this.lines.indexOf(line)
      if (~i) this.lines.splice(i, 1)
    },
    cancel() {
      this.toggleAdd(true)
      this.clear()
    },
    close() {
      this.addRunDialog = false
    },
    clear() {
      this.vehicleId = null
      this.driverId = null
      this.notes = null
      this.endDate = null
      this.calDate = null
      this.lines = []
    },
    clearVehicle() {
      this.vehicleId = null
      this.vehicleCompliances = []
      this.vehicleComplianceCount = null
      this.contractorCompliances = []
      this.contractorComplianceCount = null
    }
  }
}
</script>
